import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useNavigate, useParams} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation} from "react-query";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {AdminCourseForm} from "Views/Course/AdminCourseForm";
import {createCourse} from "Api/Mutation/CourseMutation";

export const AdminCourseCreate : React.FC = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [entity, setEntity] = useState<any>({
        name: "",
        position: null
    })

    const update = useMutation({
        mutationKey: [],
        mutationFn: (body: any) => createCourse(body.name, body.position),
        onSuccess: () => {
            Notificator.success('El curso se ha creado correctamente')
            navigate(ROUTE_PATHS.ADMIN_COURSES_LIST)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al creado el curso')
            navigate(ROUTE_PATHS.ADMIN_COURSES_LIST)
        }
    })

    const onSave = (data: any) => {
        update.mutate({
            id: id,
            name: data.name,
            position: data.position ? parseInt(data.position) : null
        })
    }

    useEffect(() => {
        dispatch(appLoading({value: update.isLoading, text: 'Cargando'}))
    }, [update.isLoading])

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Crear curso</h1>
                        </Col>

                    </Row>

                    <Row>
                        <AdminCourseForm
                            onSave={onSave}
                            data={entity}
                        />
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
