import React from "react";
import {Spinner} from "react-bootstrap";

interface Props {
    size?: any
}

export const LoadingSpinner: React.FC = (props: Props) => {
    return (
        <Spinner
            as="span"
            animation="border"
            size={props.size ?? "sm"}
            role="status"
            aria-hidden="true"
            className={"mr-2"}
        />
    )
}
