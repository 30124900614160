import {http} from "Services/Http/HttpClient"
import {AuthorizedPerson} from "Components/User/AuthorizedPersonForm";

export interface TutorBasicInfo {
    name: string,
    surnames: string,
    document: string,
    documentType: number,
    email: string,
    nif: string,
    phoneNumbers: string[]
}

export interface TutorRequest {
    tutor: TutorBasicInfo,
    address: string,
    city: string,
    zipCode: number,
    province: string,
    registeredInSSR: boolean,
    bankEntity: string,
    iban: string,
    accountHolder: {
        name: string,
        nif: string,
        document: string,
        documentType: number
    },
    authorizedPersons?: AuthorizedPerson[],
    extraTutorInfo?: TutorBasicInfo
}

export const updateTutor = async (
    body : TutorRequest,
    idTutor: string = ''
) : Promise<unknown> => {
    if(idTutor.length){
        return await http.put('tutors/' + idTutor, body)
    }else{
        return await http.post('tutors', body)
    }
}
