import {ROUTE_PATHS} from "Config/Router/Routes";

export const USER_MENU = [
    {
        "title": "Inicio",
        "path": ROUTE_PATHS.DASHBOARD,
        "children": []
    },
    {
        "title": "Hijos/as",
        "path": ROUTE_PATHS.KIDS_LIST,
        "children": []
    },
    {
        "title": "Actividades",
        "path": ROUTE_PATHS.ACTIVITIES_LIST,
        "children": []
    },
    {
        "title": "Inscripciones",
        "path": ROUTE_PATHS.REQUEST_ITEMS_LIST,
        "children": []
    },
    {
        "title": "Mi Perfil",
        "path": ROUTE_PATHS.TUTOR_DATA_UPDATER,
        "children": []
    }
]
