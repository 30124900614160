import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";

interface Props {
    onSave: (formData: { [key: string]: any }
    ) => void,
    data?: {
        name: string,
        position: number|null,
    },
}

export const AdminCourseForm: React.FC<Props> = (props) => {

    const [formData, setFormData] = useState<{ [key: string]: any }>({
        name: props.data?.name ?? '',
        position: props.data?.position ?? null,
    })
    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({
        name: false
    })
    const [submited, setSubmited] = useState<boolean>(false)

    const validate = (): boolean => {
        let errors: number = 0
        let currentFormErrors = {}

        if (!formData.name) {
            currentFormErrors = {
                ...currentFormErrors,
                name: true
            }
            errors++
        }

        if (errors === 0) {
            return true
        }

        setFormErrors(currentFormErrors)
        return false
    }

    const save = () => {
        if (!submited) {
            setSubmited(true)
        }

        if (!validate()) {
            return
        }

        props.onSave(formData)
    }

    useEffect(() => {
        const newFormData = {
            name: props.data?.name,
            position: props.data?.position
        }
        setFormData(newFormData)
    }, [props.data]);

    useEffect(() => {
        if (submited) {
            validate()
        }
    }, [formData]);

    return (
        <>
            <Row className={"row mt-4"}>
                <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId={"queryFilter"}>
                        <Form.Label>
                            Nombre del curso
                            &nbsp;<FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                name: e.target.value
                            })}
                            required
                            className={formErrors.name ? 'is-invalid' : ''}
                            value={formData.name ?? ''}
                        />
                        {formErrors.name && <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId={"position"}>
                        <Form.Label>
                            Posicion
                        </Form.Label>
                        <Form.Control
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                position: e.target.value
                            })}
                            required
                            className={formErrors.position ? 'is-invalid' : ''}
                            value={formData.position ?? ''}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row style={{"paddingTop": "50px"}}>
                <Col xs={12} sm={4}>
                    <Button
                        className={"purple-btn btn-sm mt-4"}
                        onClick={() => save()}
                    >
                        Guardar
                    </Button>
                </Col>
            </Row>
        </>
    )
}
