import React, {useLayoutEffect, useState} from 'react';

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


export function useIsMobile() {
    const [width] = useWindowSize()

    if (width <= 425) {
        return true
    }

    return false
}

export function useIsDesktop() {
    const [width] = useWindowSize()

    if (width >= 1024) {
        return true
    }

    return false
}

export function useIsTablet() {
    const [width] = useWindowSize()

    if (width >= 768 && width <= 1024) {
        return true
    }

    return false
}
