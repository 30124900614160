import {SelectOption} from "./SelectOption"
import {ApiListResponse} from "Api/Query/ApiListResponse"

export class SelectOptionCollection {

    private _options: SelectOption[];

    constructor(options: SelectOption[] = []) {
        this._options = options
    }

    /**
     * @param data
     * @param labelFieldName
     * @param valueFieldName
     */
    static fromArray = (
        data: ApiListResponse,
        labelFieldName: string,
        valueFieldName: string
    ): SelectOptionCollection => {
        const collection = new this()

        data._result.items.map((item: object) => {
            const label = item[labelFieldName]
            const value = item[valueFieldName]
            const option = SelectOption.fromPrimitives(label, value)
            collection.add(option)
            // collection.addPrimitive(option.toPrimitives())
        })

        return collection
    }

    add = (option: SelectOption) => {
        this._options.push(option)
    }

    addPrimitive(option: {label: string, value: string}) {
        this._options.push(new SelectOption(option.label, option.value))
    }

    toPrimitiveOptions(): object[] {
        const primitive : object[] = []

        this._options.map(i => primitive.push({
            label: i.label,
            value: i.value
        }))

        return primitive
    }

    get options(): SelectOption[] {
        return this._options;
    }
}
