import React from "react"
import {Table} from "react-bootstrap"
import {TotalRecordsText} from "Components/Table/TotalRecordsText"
import {LoadingSpinner} from "Components/Loading/LoadingSpinner"

interface Props {
    headers: JSX.Element,
    children: JSX.Element[],
    loading: boolean,
    totalItems?: number,
    totalRecords?: number,
    attrs?: object,
    totalColumns?: number,

    bordered?:boolean
    border?:number
}

export const SimpleTable: React.FC<Props> = (props) => {

    const customProps = () => {
        let proppps: any = {}

        if(props.bordered){
            proppps = {...proppps, bordered: props.bordered}
        }
        if(props.border){
            proppps = {...proppps, border: props.border}
        }

        return proppps
    }

    return (
        <>
            <Table {...props.attrs} {...customProps()}>
                <thead>
                <tr>
                    {props.headers}
                </tr>
                </thead>
                <tbody className={""}>
                {
                    (!props.children || !props.children.length) &&
                    <tr key={"0"}>
                        <td
                            className={"text-center my-4 py-4"}
                            colSpan={props.totalColumns ? props.totalColumns : 0}
                        >
                            {props.loading && <LoadingSpinner/>}
                            {!props.loading && "No results found"}
                        </td>
                    </tr>
                }

                {props.children}

                </tbody>
            </Table>

            {props.totalItems && props.totalRecords &&
                <TotalRecordsText totalItems={props.totalItems} totalRecords={props.totalRecords}/>
            }
        </>
    )
}
