import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Col, Row} from "react-bootstrap";

interface Props {
    value: any,
    onChange: (newValue: any, socialWorker: string) => void,
    socialWorker?: string,
    classes?: string,
    isSocialWorkerInvalid?: boolean
}

export const DISCOUNT_TYPE_ORDINARY_ID = 'ordinary'
export const DISCOUNT_TYPE_ORDINARY_LABEL = 'Ordinaria (sin descuento)'

export const DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID = 'social_services_review'
export const DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_LABEL = 'Solicita Valoración de Servicios Sociales (-100%)'

export const DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_ID = 'two_or_more_unemployed_in_family'
export const DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_LABEL = 'Descuento por 2 o más desempleados en la familia (-50%)'

export const DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_ID = 'one_unemployed_in_family'
export const DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_LABEL = 'Descuento por 1 desempleado en la familia (-20%)'

export const DISCOUNT_TYPE_LARGE_FAMILY_ID = 'large_family'
export const DISCOUNT_TYPE_LARGE_FAMILY_LABEL = 'Descuento por familia numerosa (-20%)'

export const DISCOUNT_TYPE_LABELS_BY_ID = {
    [DISCOUNT_TYPE_ORDINARY_ID]: DISCOUNT_TYPE_ORDINARY_LABEL,
    [DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID]: DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_LABEL,
    [DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_ID]: DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_LABEL,
    [DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_ID]: DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_LABEL,
    [DISCOUNT_TYPE_LARGE_FAMILY_ID]: DISCOUNT_TYPE_LARGE_FAMILY_LABEL,
}

export const DISCOUNT_TYPE_PERCENTS_BY_ID = {
    [DISCOUNT_TYPE_ORDINARY_ID]: 0,
    [DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID]: 100,
    [DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_ID]: 50,
    [DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_ID]: 20,
    [DISCOUNT_TYPE_LARGE_FAMILY_ID]: 20,
}

export const DiscountTypeSelect: React.FC<Props> = (props) => {

    const [option, setOption] = useState<any>(null)
    const [socialWorker, setSocialWorker] = useState<string>('')
    const [isSocialWorkerInvalid, setSocialWorkerInvalid] = useState<boolean>(props.isSocialWorkerInvalid ?? true)

    const options = [
        {
            label: DISCOUNT_TYPE_ORDINARY_LABEL,
            value: DISCOUNT_TYPE_ORDINARY_ID
        },
        {
            label: DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_LABEL,
            value: DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID
        },
        {
            label: DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_LABEL,
            value: DISCOUNT_TYPE_TWO_OR_MORE_UNENPLOYED_IN_FAMILY_ID
        },
        {
            label: DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_LABEL,
            value: DISCOUNT_TYPE_ONE_UNENPLOYED_IN_FAMILY_ID
        },
        {
            label: DISCOUNT_TYPE_LARGE_FAMILY_LABEL,
            value: DISCOUNT_TYPE_LARGE_FAMILY_ID
        }
    ]

    const onChange = (option: any) => {
        let currentSocialWorker = socialWorker
        setOption(option ?? null)

        if (!option || option.value !== DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID) {
            currentSocialWorker = ''
            setSocialWorker(currentSocialWorker)
        }

        props.onChange(option, currentSocialWorker)
    }

    const onChangeSocialWorker = (currentSocialWorker: string) => {
        setSocialWorker(currentSocialWorker)
        props.onChange(option, currentSocialWorker)
    }

    useEffect(() => {
        if(props.isSocialWorkerInvalid === undefined){
            return
        }

        setSocialWorkerInvalid(props.isSocialWorkerInvalid)
    }, [props.isSocialWorkerInvalid]);

    useEffect(() => {
        if (!props.value || !options.length) {
            return
        }

        const values = options.filter(option => option.value === props.value?.value)
        setOption(values[0] ?? null)
    }, [props.value])

    useEffect(() => {
        if (!options.length) {
            return
        }

        setSocialWorker(props.socialWorker ?? '')
    }, [props.socialWorker]);

    return (
        <>
            <Select
                options={options}
                placeholder={""}
                noOptionsMessage={() => "No hay resultados"}
                isClearable={true}
                loadingMessage={() => "Cargando ..."}
                onChange={onChange}
                className={props.classes ?? ''}
                value={option}
            />

            {
                option && option.value === DISCOUNT_TYPE_SOCIAL_SERVICES_REVIEW_ID && <>
                    <p className={"mt-3"}>En este caso debe solicitar cita previa con la trabajadora social.</p>
                    <p><strong>¿Ya es solicitante de Servicios Sociales?</strong></p>
                    <label htmlFor={"social_worker"} className={"mb-1"}>Indique el nombre del Trabajador/a Social</label>
                    <input
                        id={"social_worker"}
                        type={"text"}
                        className={"form-control " + (isSocialWorkerInvalid ? "is-invalid" : "")}
                        value={socialWorker}
                        onChange={(e: any) => onChangeSocialWorker(e.target.value)}
                    />
                    {isSocialWorkerInvalid &&
                        <p className={"text-danger pt-2"}>El trabajador social es obligatorio para este tipo de
                            descuento.</p>}
                </>
            }
        </>
    )
}
