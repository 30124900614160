import React, {useEffect, useRef, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {Button, Card, Col, Form, Row} from "react-bootstrap"
import {RequireAuth} from "Components/Security/RequireAuth"
import {DefaultContainer} from "Views/Shared/DefaultContainer"
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk"
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch} from "hooks";
import {useMutation, useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Activity} from "Model/Activity/Activity";
import {ChildSelect} from "Components/Child/ChildSelect";
import {SelectOption, SelectOptionType} from "Model/Select/SelectOption";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {MultiValue} from "react-select";
import {ActivityService} from "Model/Activity/ActivityService";
import Toggle from 'react-toggle'
import {addCartItem, AddCartItemBody} from "Api/Mutation/CartMutation";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {increaseCartCounter} from "Store/cartSlice";
import {buildActivityYearlyDayOptionName, SelectActivityYearlyDay} from "Components/Activities/SelectActivityYearlyDay";
import {IdName} from "Model/Shared/IdName";
import {Observations} from "Components/Activities/Observations";
import {getUserDetail} from "Api/Query/UserQuery";
import {UserService} from "Services/User/UserService";

interface Props {
    activity: Activity
}

interface YearDaysWithService {
    date: string,
    description: string,
    end: string,
    id: string,
    name: string,
    price: number,
    services: {
        id: string,
        name: string,
        price: number,
        description: string,
        billable: boolean,
        center: {
            id: string,
            name: string
        }
    }[]
}

export const ActivityYearlyDetail: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [services, setServices] = useState<ActivityService[]>([])

    const [selectedServices, setSelectedServices] = useState<Array<{
        serviceId: string,
        activityYearDayId: string
    }>>([])
    const [selectedChild, setSelectedChild] = useState<SelectOptionType | null>(null)
    const [selectedActivityYearDays, setSelectedActivityYearDays] = useState<MultiValue<SelectOptionType> | null>([])

    const [activity, setActivity] = useState<Activity>(props.activity)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [centerIds, setCenterIds] = useState<string[]>([])
    const [observations, setObservations] = useState<string>('')
    const [yearDaysWithServices, setYearDaysWithServices] = useState<any>([])
    const selectedChildCenterId = useRef(null)

    const userService = new UserService()
    const userId = userService.getId()

    // TODO: use react query cache
    const userDetailQuery: any = useQuery(['userDetail', userId], () => getUserDetail(userId))

    useEffect(() => {
        if (!props.activity) {
            return
        }

        const centers: string[] = []
        props.activity.centers.map((center: IdName) => centers.push(center.id))
        setCenterIds(centers)

    }, [props.activity]);

    const addToCartMutation = useMutation({
        mutationFn: (body: AddCartItemBody) => addCartItem(body),
        onSuccess: () => {
            dispatch(increaseCartCounter())
            Notificator.success(`“${activity.name}” se ha añadido a tu carrito.`)
            navigate(ROUTE_PATHS.SHOPPING_CART)
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const isFormValid = (): boolean => {
        if (!selectedChild) {
            return false
        }

        return true
    }

    useEffect(() => {
        dispatch(
            appLoading({
                value: addToCartMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [addToCartMutation.isLoading])

    useEffect(() => {
        if (!activity || !activity.activityYearDays) return

        let tutorChildren = userDetailQuery?.data?.data._result.tutor.children ?? null
        selectedChildCenterId.current = null

        // Get selected children center
        tutorChildren?.map((child: any) => {
            if (child.id === selectedChild?.value) {
                selectedChildCenterId.current = child.center.id
            }
        })

        // Get services for current child center
        let currentYearDays: any = []
        let selectedActivityYearServices: any[] = []
        let yearsDaysHashmap: {[key:string]:any} = {}

        /**
         * Dias seleccionados por el usuario en el dropdown
         * @var selectedActivityYearDays
         **/
        selectedActivityYearDays?.map(yearDay => {
            const yearDayId = yearDay.value
            const activityYearDaysObject = activity.activityYearDays?.filter(item => item.id === yearDayId)

            if (activityYearDaysObject) {
                let hasSameCenterThanSelectedChildren = false
                let cservices: any[] = []

                activityYearDaysObject.map(yearsDay => {
                    if (yearsDay.services) {

                        const newYearDayServices: Array<any> = []
                        yearsDaysHashmap[yearsDay.id] = yearsDay

                        for (let i in yearsDay.services) {
                            let yearDayService = yearsDay.services[i]
                            yearDayService['activity'] = activityYearDaysObject[0]

                            if (yearDayService.center.id === selectedChildCenterId.current) {
                                hasSameCenterThanSelectedChildren = true
                                newYearDayServices.push(yearDayService)
                            }
                        }

                        cservices = [...cservices, ...newYearDayServices]
                    }
                })

                if (cservices.length) {
                    selectedActivityYearServices = [...selectedActivityYearServices, ...cservices]
                }

                // Si el YearDay tiene el mismo centro que el niño seleccionado, lo guardamos para mostrarlo como opcion seleccionable.
                if(hasSameCenterThanSelectedChildren){
                    const cYearday = yearsDaysHashmap[yearDay.value]
                    currentYearDays = [...currentYearDays, cYearday]
                }
            }
        })

        setYearDaysWithServices(currentYearDays)
        setServices(selectedActivityYearServices)
    }, [selectedActivityYearDays, selectedChild])

    useEffect(() => calculatePrice(), [selectedServices, selectedActivityYearDays])

    const calculatePrice = () => {
        let priceSum = 0

        // sum services
        for (let i in selectedServices) {
            const item = selectedServices[i]
            const serviceId = item.serviceId
            const activityYearDayId = item.activityYearDayId

            const serviceObjects = services.filter(service => service.id === serviceId && service.activity.id === activityYearDayId)

            if (serviceObjects.length) {
                priceSum += serviceObjects[0].price
            }
        }

        selectedActivityYearDays?.map(activityYearDay => {
            const activityYearDaysObject = activity.activityYearDays?.filter(item => item.id === activityYearDay.value)
            if (activityYearDaysObject) {
                priceSum += activityYearDaysObject[0].price
            }
        })

        setTotalPrice(priceSum)
    }

    const addSelectedServices = (serviceId: string, activityYearDayId: string): void => {
        const newSelectedServices = [...selectedServices, {serviceId: serviceId, activityYearDayId: activityYearDayId}]
        setSelectedServices(newSelectedServices)
    }

    const removeSelectedService = (serviceId: string, activityYearDayId: string): void => {
        const services: any = []
        selectedServices.map(item => {
            if (item.activityYearDayId !== activityYearDayId || item.serviceId !== serviceId) {
                services.push(item)
            }
        })
        setSelectedServices(services)
    }

    const existSelectedService = (serviceId: string, activityId: string): boolean => {
        const found = selectedServices.filter(item => item.serviceId === serviceId && item.activityYearDayId === activityId)
        return found.length > 0
    }

    function createActivityContent() {
        return {__html: activity.description};
    }

    const createRequest = (): void => {
        if (!selectedChild) return

        const days: string[] = []
        selectedActivityYearDays?.map(item => days.push(item.value))

        const cServices: any[] = []
        selectedServices.map(item => cServices.push(item))

        let body: AddCartItemBody = {
            days: days,
            idChild: selectedChild.value,
            services: cServices,
            observations: observations
        }

        addToCartMutation.mutate(body)
    }

    return (
        <RequireAuth>

            {!activity.name && <DefaultContainer>&nbsp;</DefaultContainer>}

            {activity.name &&
                <div id={"activityDetailUserView"}>
                    <Col
                        className={"activityBgImage"}
                        style={
                            activity.backgroundImage ? {
                                backgroundImage: `url(${activity.backgroundImage})`
                            } : {
                                background: '#bdc3c7'
                            }
                        }
                    >
                        &nbsp;
                    </Col>

                    <DefaultContainer
                        classes={"activityImageContainer px-0"}
                    >
                        <Row>
                            <Col
                                className={"px-sm-0 d-flex align-items-end  justify-content-center  justify-content-sm-left"}></Col>
                            <Col className={"d-flex justify-content-end"}>
                                <img
                                    className={"activityImage"}
                                    src={activity.image}
                                    alt={activity.name}
                                    title={activity.name}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h1>{activity.name}</h1>
                        </Row>
                    </DefaultContainer>

                    <DefaultContainer>
                        <Col id={"activity_detail"} className={"px-sm-0"}>

                            <div dangerouslySetInnerHTML={createActivityContent()}></div>

                            <Row className={"formContainer"}>
                                <Col xs={12} sm={6}>
                                    <Form.Group
                                        controlId="child"
                                        className="my-3"
                                    >
                                        <Form.Label>
                                            Nombre del menor <FormLabelRequiredAsterisk/>
                                        </Form.Label>

                                        <ChildSelect
                                            onChange={(option) => {
                                                setSelectedChild(option)

                                                if(!option) {
                                                    setServices([])
                                                    setSelectedServices([])
                                                    setSelectedActivityYearDays([])
                                                }
                                            }}
                                            centerIds={centerIds}
                                            value={""}
                                        />
                                        {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                                    </Form.Group>
                                </Col>
                            </Row>

                            {
                                activity &&
                                <Col xs={12}>
                                    <Form.Group
                                        controlId="yearDays"
                                        className="my-3"
                                    >
                                        <Form.Label>
                                            Días <FormLabelRequiredAsterisk/>
                                        </Form.Label>

                                        <SelectActivityYearlyDay
                                            activityYearDays={activity.activityYearDays ?? []}
                                            onChange={(option: SelectOption|MultiValue<SelectOptionType>|any) => {
                                                let options : MultiValue<any>|null = null

                                                if(!activity.canSelectMultipleYearDays) {
                                                    options = [option]
                                                }else {
                                                    options = option
                                                }

                                                setSelectedActivityYearDays(options)
                                                setServices([])
                                                setSelectedServices([])
                                            }}
                                            value={selectedActivityYearDays}
                                            canSelectMultiple={activity.canSelectMultipleYearDays}
                                        />

                                        {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                                    </Form.Group>
                                </Col>
                            }

                            {
                                services.length > 0 &&
                                <Row>
                                    <Col>
                                        <Form.Group
                                            controlId="services"
                                            className="my-3"
                                        >
                                            <Form.Label>
                                                Servicios <FormLabelRequiredAsterisk/>
                                            </Form.Label>

                                            <Row>

                                                {
                                                    yearDaysWithServices?.map((yearDaysWithService:YearDaysWithService, key: number) => {
                                                        return <Col key={key} sm={12} md={12} lg={12} xl={12} xxl={12} className={"mb-3"}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Card.Title className={"mb-3"}>
                                                                        {yearDaysWithService.name} - Precio {yearDaysWithService.price} €
                                                                    </Card.Title>

                                                                    <Row>
                                                                    {
                                                                        yearDaysWithService.services.map((service:any, subkey:number) => {
                                                                            // Solo muestra los servicios para el centro asociado al niño seleccionado.
                                                                            if(service.center.id !== selectedChildCenterId.current){
                                                                                return <></>
                                                                            }

                                                                            return <Col md={3} key={subkey}>
                                                                            <label
                                                                                htmlFor={service.id + service.activity.id}
                                                                                className={"d-block mb-2 fw-bold pointer"}
                                                                            >
                                                                                <Toggle
                                                                                    id={service.id + service.activity.id}
                                                                                    checked={existSelectedService(service.id, service.activity.id)}
                                                                                    onChange={(e) => e.target.checked === true
                                                                                        ? addSelectedServices(service.id, service.activity.id)
                                                                                        : removeSelectedService(service.id, service.activity.id)
                                                                                    }
                                                                                />

                                                                                <span style={{
                                                                                    "paddingLeft": "13px",
                                                                                    "verticalAlign": "top"
                                                                                }}>
                                                                            {service.name} <span
                                                                                    style={{"color": "#A80048"}}>+{service.price} €</span>

                                                                                    {
                                                                                        service.activity.name &&
                                                                                        <>
                                                                                            <br/>
                                                                                            <small>
                                                                                                ({service.activity.name}{service.activity.description && <> - {service.activity.description}</>})
                                                                                            </small>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        !service.activity.name &&
                                                                                        <>
                                                                                            <br/>
                                                                                            <small>({buildActivityYearlyDayOptionName(service.activity, false)})</small>
                                                                                        </>
                                                                                    }
                                                                            </span>

                                                                                {service.description &&
                                                                                    <label
                                                                                        title={(service.name + ' ' + service.price + '€')}
                                                                                        htmlFor={service.id + service.activity.id}
                                                                                        className={"d-block pointer"}
                                                                                        style={{
                                                                                            "fontWeight": "normal",
                                                                                            "fontSize": "14px"
                                                                                        }}>{service.description}</label>
                                                                                }

                                                                            </label>
                                                                        </Col>
                                                                    })
                                                                    }
                                                                    </Row>

                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    })
                                                }

                                            </Row>

                                            {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col>
                                    <Observations
                                        onChange={(value: string) => setObservations(value)}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className={"col-12 text-center text-sm-start "}>
                                    <Form.Group
                                        controlId="child"
                                        className="my-3"
                                    >
                                        <span>Total</span>
                                        <span className={"price d-block"}>
                                            <MoneyAmount amount={totalPrice}/>
                                        </span>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>

                            <div className="d-grid d-sm-block gap-2">
                                <Button
                                    onClick={createRequest}
                                    className={"purple-btn btn-xs-block"}
                                    disabled={!isFormValid()}
                                >
                                    Solicitar inscripción
                                </Button>
                            </div>

                        </Col>
                    </DefaultContainer>
                </div>
            }

        </RequireAuth>
    )
}
