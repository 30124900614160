import {ErrorTranslator} from "Services/Notificator/ErrorTranslator";

export class ErrorConverter
{
    static convertToHashmap = (errors : object) : object => {
        let hashmap : { [key: string]: string } = {}

        for(let i in errors){
            const message = errors[i]

            let formattedMessage = message.replaceAll('[', '')
            formattedMessage = formattedMessage.replaceAll(':', '')
            formattedMessage = formattedMessage.replaceAll(']', '|')

            const parts = formattedMessage.split('|')
            const fieldName = parts[0]
            const errorMessage = ErrorTranslator.trans(parts[1].trim())
            hashmap[fieldName] = errorMessage
        }

        return  hashmap
    }
}
