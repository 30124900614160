import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface CartState {
    total: number,
    checkedOnLogin: boolean
}

// Define the initial state using that type
const initialState: CartState = {
    total: 0,
    checkedOnLogin: false
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        increaseCartCounter: (state) => {
            state.total += 1
        },
        decreaseCartCounter: (state) => {
            state.total -= 1
        },
        setTotalCartCounter: (state, action: PayloadAction<number>) => {
            state.total = action.payload
        },
        markCartAsCheckedOnLogin: (state, action: PayloadAction<boolean>) => {
            state.checkedOnLogin = action.payload
        }
    }
})

export const {
    increaseCartCounter,
    decreaseCartCounter,
    setTotalCartCounter,
    markCartAsCheckedOnLogin
} = cartSlice.actions

export default cartSlice.reducer
