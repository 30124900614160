import toastr from 'toastr'
import {ErrorTranslator} from "Services/Notificator/ErrorTranslator";

export class Notificator
{
    static readonly options : ToastrOptions = {
        closeDuration: 5
    }

    static success = (message: string, title: string = '') : void => {
        toastr.success(message, title, this.options)
    }

    static error = (message: string, title: string = '') : void => {
        message = ErrorTranslator.trans(message)
        toastr.error(message, title, this.options)
    }

    static warning = (message: string, title: string = '') : void => {
        toastr.warning(message, title, this.options)
    }
}
