import Select, {MultiValue} from "react-select";
import React, {useEffect, useState} from "react";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {SelectOption, SelectOptionType} from "Model/Select/SelectOption";
import {ActivitySession} from "Model/Activity/ActivitySession";
import {Notificator} from "Services/Notificator/Notificator";

interface Props {
    onChange: (newValue: MultiValue<SelectOptionType>|null) => void,
    value?: any,
    classes?: string,
    session?: ActivitySession|null,
    isDisabled?: boolean
}

export const SessionDaySelect : React.FC<Props> = (props) => {

    const [options, setOptions] = useState<any>([])
    const [selectedOptions, setSelectedOptions] = useState<any>([])

    const createFromSession = () => {
        if(!props.session) return

        const collection = new SelectOptionCollection()
        for (const [key, value] of Object.entries(props.session?.days)) {
            const option = SelectOption.fromPrimitives(value.name, value.id)
            collection.addPrimitive(option)
        }

        setOptions(collection.toPrimitiveOptions())
    }

    const onChange = (options: any) => {
        if(props.session && props.session?.numberOfDays > 0 && (options.length > props.session?.numberOfDays)){
            Notificator.error(`Solo se pueden seleccionar ${props.session?.numberOfDays} días como máximo para esta modalidad.`, 'Error')
            return
        }

        props.onChange(options)
    }

    useEffect(() => {
        if(props.session){
            createFromSession()
        }
    }, [props.session])

    return (
        <Select
            // isLoading={isLoading}
            isMulti
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={onChange}
            className={props.classes ?? ''}
            isDisabled={props.isDisabled}
            value={props.value}
        />
    )
}
