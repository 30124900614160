import React, {useEffect, useState} from "react";

interface Props{
    amount: number
}

export function formatMoneyValue(amount : any){
    return new Intl.NumberFormat('es-es', { style: 'currency', currency: 'EUR' }).format(amount)
}

export const MoneyAmount : React.FC<Props> = (props) => {

    const [amount, setAmount] = useState<string>("0")

    useEffect(() => {
        formatMoney()
    },[])

    useEffect(() => {
        formatMoney()
    },[props.amount])

    const formatMoney = () => {
        const formattedAmount = formatMoneyValue(props.amount)
        setAmount(formattedAmount)
    }

    return (
        <>{amount}</>
    )
}
