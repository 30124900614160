import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation, useQuery} from "react-query";
import {getActivitySessions} from "Api/Query/ActivityQuery";
import {Notificator} from "Services/Notificator/Notificator";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import {deleteActivitySessions} from "Api/Mutation/ActivitySession";
import Pagination from "rc-pagination";
import {renderHtml} from "Services/View/ViewUtils";

export const AdminModalityList: React.FC = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [allSelected, setAllChecked] = useState<boolean>(false)
    const [activitySessions, setActivitySessions] = useState<any[]>([])
    const [selectedActivitySessions, setSelectedActivitySessions] = useState<{ [key: string]: boolean }>({})
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const [pageSize, setPageSize] = useState<number>(12)
    const [page, setPage] = useState<number>(1)

    const [searchFilters, setSearchFilters] = useState<{[key:string]:any}>({
        size: pageSize,
        page: page
    })
    const [searchFilterValue, setSearchFilterValue] = useState<string>('')
    const [totalRecords, setTotalRecords] = useState<number>(0)

    const checkAll = () => {
        if (allSelected) {
            setSelectedActivitySessions(initCheckboxes(activitySessions))
        } else {
            const checkboxes = selectedActivitySessions
            Object.keys(checkboxes).map(key => checkboxes[key] = true)
            setSelectedActivitySessions(checkboxes)
        }

        setAllChecked(!allSelected)
    }

    const isAnyRowChecked = (): boolean => {
        for (let key in Object.values(selectedActivitySessions)) {
            const isChecked = Object.values(selectedActivitySessions)[key]

            if (isChecked) {
                return true
            }
        }

        return false
    }

    const getCheckedIds = (): string[] => {
        const ids: string[] = []

        const keys = Object.keys(selectedActivitySessions)
        const values = Object.values(selectedActivitySessions)

        for (let i in values) {
            const checked = values[i]
            if (checked) {
                ids.push(keys[i])
            }
        }

        return ids
    }

    const onSelectedCheckboxRowChange = (item: any) => {
        setSelectedActivitySessions({
            ...selectedActivitySessions,
            [item.id]: !selectedActivitySessions[item.id]
        })
    }

    const deleteMultiple = () => {
        if (!window.confirm('¿Deseas eliminar los registros seleccionados?')) {
            return
        }

        setIsDeleting(true)
        deleteMutation.mutate(getCheckedIds())
    }

    const headers: string[] = [
        'NOMBRE MODALIDAD',
        'HORARIO',
        'PRECIO',
        'VINCULADO A',
        'ACCIONES'
    ]

    const initCheckboxes = (sessions: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        sessions.map(session => checkboxes[session.id] = false)
        return checkboxes
    }

    const listQuery = useQuery({
        enabled: false,
        queryKey: ['lisyActivitySessions'],
        queryFn: () => getActivitySessions(searchFilters),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const sessions = responseData.items
            setActivitySessions(sessions)
            setTotalRecords(responseData.total)
            setSelectedActivitySessions(initCheckboxes(sessions))
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const deleteMutation = useMutation({
        mutationKey: ['deleteActivitySessions'],
        mutationFn: (ids: any) => deleteActivitySessions(ids),
        onSuccess: () => {
            setIsDeleting(false)
            setSelectedActivitySessions({})
            listQuery.refetch()
            Notificator.success('Los registros seleccionados se han eliminado correctamente')
        },
        onError: () => {
            setIsDeleting(false)
            Notificator.error('Ha ocurrido un error al intentar eliminar los registros seleccionados.')
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        if(searchFilterValue){
            setSearchFilters({'q': `*${searchFilterValue}*`})
        }else{
            setSearchFilters({})
        }

        dispatch(appLoading({value: true, text: 'Cargando'}))
    }

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString(),
            size: pageSize
        })
    }

    useEffect(() => {
        listQuery.refetch()
    }, [])

    useEffect(() => {
        listQuery.refetch()
    }, [searchFilters])

    useEffect(() => {
        dispatch(appLoading({value: listQuery.isLoading, text: 'Cargando'}))
    }, [listQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: listQuery.isRefetching, text: 'Cargando'}))
    }, [listQuery.isRefetching])

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Listado de modalidades</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>
                                {
                                    isAnyRowChecked() &&
                                    <>
                                        <Button
                                            variant={"secondary"}
                                            onClick={deleteMultiple}
                                            disabled={isDeleting}
                                            className={"mx-2"}
                                        >
                                            {!isDeleting && <span>Eliminar Seleccionadas</span>}
                                            {isDeleting && <span>Eliminando ...</span>}
                                        </Button>

                                        {/*<Button*/}
                                        {/*    className={"purple-btn mx-4"}*/}
                                        {/*    disabled={true}*/}
                                        {/*>*/}
                                        {/*    Exportar*/}
                                        {/*</Button>*/}
                                    </>
                                }

                                <Button
                                    className={"purple-btn"}
                                    onClick={() => navigate(ROUTE_PATHS.ADMIN_MODALITY_CREATE)}
                                >
                                    Añadir modalidad
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row my-4"}>
                        <Col xs={12} sm={6} md={4}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                    &nbsp;<FormLabelRequiredAsterisk/>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e:any) => setSearchFilterValue(e.target.value)}
                                    onKeyDown={onEnterPressed}
                                />
                            </Form.Group>
                        </Col>
                    </div>
                    <br/>

                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            <th
                                style={{"width": "2%"}}
                            >
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={allSelected}
                                    onChange={checkAll}
                                />
                            </th>
                            {headers.map((header, key) => <th key={key}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {activitySessions.map((item, key) => <tr key={key}>
                            <>
                                <td className={"align-middle"}>
                                    <input
                                        key={key}
                                        type="checkbox"
                                        checked={selectedActivitySessions[item.id]}
                                        onChange={(e: any) => onSelectedCheckboxRowChange(item)}
                                    />
                                </td>
                                <td>
                                    <Link
                                        to={ROUTE_PATHS.ADMIN_MODALITY_EDIT.replace(":id", item.id)}>{item.name}</Link>
                                    <br/>
                                    <div dangerouslySetInnerHTML={renderHtml(item.description)}></div>
                                    <br/>
                                </td>
                                <td>
                                {item.startDate && item.endDate &&
                                        <>
                                            {item.startDate} - {item.endDate}
                                        </>
                                    }
                                </td>
                                <td>
                                    <MoneyAmount amount={item.price}/>
                                </td>
                                <td>
                                    <Link to={ROUTE_PATHS.ADMIN_ACTIVITIES_EDIT.replace(":id", item.activity.id)}>
                                        {item.activity.name}
                                    </Link>
                                </td>
                                <td className={"text-center align-middle"}>
                                    <Button
                                        className={"purple-btn btn-sm"}
                                        onClick={() => navigate(ROUTE_PATHS.ADMIN_MODALITY_EDIT.replace(":id", item.id))}
                                    >
                                        Modificar
                                    </Button>
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <>
                                    <Pagination
                                        total={totalRecords}
                                        pageSize={pageSize}
                                        current={page}
                                        onChange={onPaginationPageChange}
                                    />
                                </>
                            }
                        </Col>
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
