import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import DateService from "Services/Date/DateService";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {AddYearDayModalForm, AddYearSubmitDto} from "Views/Modalities/AddYearDayModalForm";
import {Notificator} from "Services/Notificator/Notificator";
import {CenterServiceResponseItem} from "Api/Query/CenterQuery";
import {CenterServiceLabel} from "Components/Center/CenterServiceLabel";

interface Props {
    yearDays?: any,
    services?: CenterServiceResponseItem[],
    onChange: (yearDays: AddYearSubmitDto[]) => void
}

export const AdminYearDayForm: React.FC<Props> = (props) => {

    const [showAddYearDayModalForm, setShowAddYearDayModalForm] = useState<boolean>(false)
    const [yearDays, setYearDays] = useState<AddYearSubmitDto[]>([])

    const addYearDay = (data: AddYearSubmitDto) => {
        const newYearDays = [...yearDays, data]
        props.onChange(newYearDays)
        setYearDays(newYearDays)
        setShowAddYearDayModalForm(false)
        Notificator.success('El día se ha añadido correctamente')
    }

    const deleteYearDay = (index: number) => {
        const newYearDays = yearDays.filter((item: any, key: number) => key !== index)
        props.onChange(newYearDays)
        setYearDays(newYearDays)
        Notificator.success('El día se ha eliminado correctamente')
    }

    useEffect(() => {
        setYearDays(props.yearDays)
    }, [props.yearDays])

    return (
        <>
            <h4>Días</h4>
            <span>Días del año</span>

            {
                yearDays?.map((yearDay: any, key: number) => (
                    <div key={key} className={"d-block mb-3 mt-3"}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <strong>Fecha:</strong> {DateService.convertYYYYMMDDFormatToDDMMYYYY(yearDay.date)}
                                        {yearDay.name && <span
                                            className={"d-block"}><strong>Nombre:</strong> {yearDay.name}</span>}
                                        {yearDay.description && <span
                                            className={"d-block"}><strong>Descripción:</strong> {yearDay.description}</span>}
                                        {yearDay.price > 0 && <span
                                            className={"d-block"}><strong>Precio:</strong> <MoneyAmount
                                            amount={yearDay.price}/></span>}
                                        {yearDay.billable && <span
                                            className={"d-block"}><strong>Facturable:</strong> {yearDay.billable ? 'Sí' : 'No'}</span>}
                                        {yearDay.start && <span
                                            className={"d-block"}><strong>Inicio:</strong> {yearDay.start}</span>}
                                        {yearDay.end &&
                                            <span className={"d-block"}><strong>Fin:</strong> {yearDay.end}</span>}
                                    </Col>
                                    <Col>
                                        <strong>Servicios:</strong>
                                        <ul>
                                            {
                                                yearDay.services?.map((service: CenterServiceResponseItem) => {
                                                    // @ts-ignore
                                                    return (
                                                        <li key={service.id}>
                                                            <CenterServiceLabel centerService={service}/>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>

                                <Button
                                    onClick={() => deleteYearDay(key)}
                                    variant={"danger"}
                                    className={"d-block mt-2"}
                                    size={"sm"}
                                >Eliminar</Button>
                            </Card.Body>
                        </Card>
                    </div>
                ))
            }

            <Button
                className={"btn-dark btn-sm d-block mt-2"}
                onClick={() => setShowAddYearDayModalForm(true)}
            >
                Añadir día
            </Button>

            <AddYearDayModalForm
                show={showAddYearDayModalForm}
                onClose={() => setShowAddYearDayModalForm(false)}
                onSubmit={(data: AddYearSubmitDto) => addYearDay(data)}
                services={props.services ?? []}
            />
        </>
    )
}
