import Select from "react-select";
import {useQuery} from "react-query";
import React, {useEffect, useState} from "react";
import {getAllActivities} from "Api/Query/ActivityQuery";
import {SelectOptionType} from "Model/Select/SelectOption";

interface Props {
    onChange: (newValue: SelectOptionType|null) => void,
    onActivitiesLoad?: (activities: any[]) => void,
    value?: any,
    classes?: string,
    filters?: {[key:string]:string|number}
}

export const ActivitySelect : React.FC<Props> = (props) => {

    const [options, setOptions] = useState<any>([])

    const activitiesQuery = useQuery({
        enabled: false,
        queryKey: ['getAllActivities'],
        queryFn: () => getAllActivities(props.filters ?? {}),
        onSuccess: (response: any) => {
            const activities : object[] = []
            response.data._result.items.map((item:any) => {
                activities.push({
                    label: item.name,
                    value: item.id
                })
            })
            setOptions(activities)

            if(props.onActivitiesLoad){
                props.onActivitiesLoad(response.data._result.items)
            }
        },
        onError: (error: any) => {

        }
    })

    useEffect(() => {
        activitiesQuery.refetch()
    }, [])

    return (
        <Select
            // isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
        />
    )
}
