import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getCenters} from "Api/Query/CenterQuery";
import {useQuery} from "react-query";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {SelectOptionType} from "Model/Select/SelectOption";

interface Props {
    value: SelectOptionType|null,
    onChange: (newValue: SelectOptionType | null) => void,
    classes?: string
}

export const CenterSelect: React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(null)
    const [options, setOptions] = useState<any>([])

    const {isLoading, data} = useQuery(['centersList'], () => getCenters({
        sort: 'name'
    }))

    useEffect(() => {
        if (!data) return

        // @ts-ignore
        const response: ApiListResponse = data?.data
        const collection = SelectOptionCollection.fromArray(response, 'name', 'id')
        setOptions(collection.toPrimitiveOptions())
    }, [data])

    useEffect(() => {
        if (!props.value || !options.length) {
            return
        }

        const values = options.filter(option => option.value === props.value?.value)
        setValue(values[0] ?? null)
    }, [props.value, options])

    return (
        <Select
            isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={false}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
            value={value}
        />
    )

}
