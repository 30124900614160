import {http} from "Services/Http/HttpClient";
import FilterBuilder from "Services/Filters/FilterBuilder";

export const getChildren = async (filters: any = {}, sort: string = ''): Promise<unknown> => {
    filters = {
        ...filters,
        sort: sort
    }
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get('children' + (queryParams ? '?' + queryParams : ''))
}

export const getChildrenDetail = async (id: string): Promise<unknown> => {
    return await http.get('children/' + id)
}
