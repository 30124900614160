import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface AuthorizedPerson {
    id?:string,
    name: string,
    document: string
    documentType: number
}

interface Props {
    onChange: (authorizedPersons: AuthorizedPerson[]) => void,
    defaultValues?: AuthorizedPerson[]
}

export const AuthorizedPersonForm: React.FC<Props> = (props) => {

    const [name, setName] = useState<string>('')
    const [nif, setNif] = useState<string>('')
    const [authorizedPersons, setAuthorizedPersons] = useState<AuthorizedPerson[]>(props.defaultValues ?? [])

    useEffect(() => {
        props.onChange(authorizedPersons)
    }, [authorizedPersons])

    const addAuthorizedPerson = (): void => {
        if (!name || !nif) return

        const newAuthorizedPerson : AuthorizedPerson = {
            name: name,
            document: nif,
            documentType: 1
        }
        setAuthorizedPersons([...authorizedPersons, newAuthorizedPerson])

        setName('')
        setNif('')
    }

    const removeAuthPerson = (key: number) => {
        const tempArray = authorizedPersons
        const newArray = tempArray.filter((person, pKey) => pKey !== key)
        setAuthorizedPersons(newArray)
    }

    return (
        <Form className={"partialUserDataForm"}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="authorizedName">
                        <Form.Label>
                            Nombre y apellidos del autorizado
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                            value={name}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="authorizedNif">
                        <Form.Label>
                            DNI / NIE o Pasaporte autorizado
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNif(e.target.value)}
                            value={nif}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col className={"d-flex align-items-center mt-2"}>
                    <Button
                        className={"btn-block purple-btn"}
                        onClick={addAuthorizedPerson}
                    >
                        Añadir
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className={"mt-2 px-3"}>
                        {
                            authorizedPersons.map((person, key) => {
                                return (
                                    <li key={key} className={"mb-2"}>
                                        <span><strong>{person.name}</strong>: {person.document}</span>
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            className={"text-danger pointer"}
                                            onClick={() => removeAuthPerson(key)}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Col>
            </Row>
        </Form>
    )

}
