import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Col} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {createChild, CreateChildBodyRequest, uploadChildFile} from "Api/Mutation/TutorChild";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {UserService} from "Services/User/UserService";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {getUserDetail, UserDetailResponseResult} from "Api/Query/UserQuery";
import {ChildMediaTypes} from "Model/Child/ChildMediaTypes";
import {ChildForm, ChildFormData} from "Views/Kids/ChildForm";
import {refreshTokenAndSave} from "Services/Security/AuthService";

export const AddChild: React.FC = () => {

    const [submitted, setSubmitted] = useState<boolean>(false)
    const navigate = useNavigate()

    const [tutorId, setTutorId] = useState<string>('')
    const [kidsFormData, setKidsFormData] = useState<ChildFormData>({
        center: {
            id: '',
            name: ''
        },
        name: '',
        surnames:'',
        courseLevel: {
            id: '',
            name: ''
        },
        birthDate: '',
        canLeaveWithoutTutor: false,
        hasDisease: false,
        hasSpecialNeeds: false,
        hasSpecialCare: false,
        hasAnyAllergyOrSpecialDiet: false,
        hasSphincterControl: true,
        hasAnySyndrome: false,
        canEatPork: true,
        hasSocialServices: false,
        canBeFilmed: false,
        nif: '',
        childDiseases: '',
        specialCareDescription: '',
        sphincterControlDescription: '',
        specialNeedsDescription: '',
        allergyOrSpecialDietDescription: '',
        anySyndromeDescription: '',
        diseaseFiles: [],
        allergensFiles: [],
        disorderFiles: [],
        reqSpecialAttentionFiles: [],
        specialEducationFiles: [],
        sphinctersFiles: [],
    })

    let userService = new UserService()
    const userId = userService.getId()

    const userDetailQuery = useQuery({
        enabled: false,
        queryKey: ['userDetail', userId],
        queryFn: () => getUserDetail(userId),
        onSuccess: async (data: any) => {
            const response: UserDetailResponseResult = data.data._result
            setTutorId(response.tutor.id)

            // refresh token
            if(!userService.getTutor()) {
                await refreshTokenAndSave()
            }
        }
    })

    useEffect(() => {
        // check if token has tutorId value
        let tokenUserId = userService.getTutor()

        if (tokenUserId) {
            setTutorId(tokenUserId)
            return
        }

        // get tutor info
        userDetailQuery.refetch()
    }, [])

    /**
     * @param loading
     * @param text
     */
    const setAppLoading = (loading: boolean, text: string = ''): void => {
        dispatch(
            appLoading({
                value: loading,
                text: text
            })
        )
    }

    const applyServerValidationErrors = (serverErrors: object): void => {
        if (!Object.keys(serverErrors).length) {
            return
        }

        let errors: { [key: string]: string } = {}

        // input fields validation
        for (const [fieldName, error] of Object.entries(serverErrors)) {
            let key = fieldName
            errors[key] = error
        }
    }

    const mutation = useMutation({
        mutationFn: (body: CreateChildBodyRequest) => createChild(tutorId, body),
        onMutate: () => setAppLoading(true),
        onSuccess: async (response) => {
            const childId: string = response.data._result.id
            await uploadFiles(childId)
        },
        onError: (error: any) => {
            setAppLoading(false)

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')

            applyServerValidationErrors(
                ErrorMessageBuilder.getServerErrors(error)
            )
        }
    })

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadChildFile(body.childId, body.file, body.type),
        onMutate: (body: any) => {
            setAppLoading(true, `Subiendo archivo: ${body.file.file.name} ...`)
        },
        onError: (error: any) => {
            setAppLoading(false)

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const dispatchSuccess = (): void => {
        setAppLoading(false)
        Notificator.success('Su hijo/a se ha registrado correctamente.')
        navigate(ROUTE_PATHS.DASHBOARD)
    }

    const dispatch = useAppDispatch()

    const handleSubmit = (formData : ChildFormData): void => {
        if (!submitted) {
            setSubmitted(true)
        }

        setKidsFormData(formData)
    }
    
    const save = () => {

        let mutationBody: CreateChildBodyRequest = {
            center: kidsFormData.center.id,
            name: kidsFormData.name,
            surnames: kidsFormData.surnames,
            courseLevel: kidsFormData.courseLevel.id,
            birthDate: kidsFormData.birthDate,
            canLeaveWithoutTutor: kidsFormData.canLeaveWithoutTutor,
            hasDisease: kidsFormData.hasDisease,
            hasSpecialNeeds: kidsFormData.hasSpecialNeeds,
            hasSpecialCare: kidsFormData.hasSpecialCare,
            hasAnyAllergyOrSpecialDiet: kidsFormData.hasAnyAllergyOrSpecialDiet,
            hasSphincterControl: kidsFormData.hasSphincterControl,
            hasAnySyndrome: kidsFormData.hasAnySyndrome,
            canEatPork: kidsFormData.canEatPork,
            hasSocialServices: kidsFormData.hasSocialServices,
            canBeFilmed: kidsFormData.canBeFilmed
        }

        if (kidsFormData.nif) {
            mutationBody = { ...mutationBody, nif: kidsFormData.nif }
        }

        if (kidsFormData.documentType) {
            mutationBody = { ...mutationBody, documentType: parseInt(kidsFormData.documentType.id) }
        }

        // ¿Padece alguna enfermedad?
        if (kidsFormData.childDiseases) {
            mutationBody = { ...mutationBody, childDiseases: kidsFormData.childDiseases }
        }

        // ¿Requiere de alguna atención especial durante las actividades?
        if (kidsFormData.specialCareDescription) {
            mutationBody = { ...mutationBody, hasSpecialCareDescription: kidsFormData.specialCareDescription}
        }

        // ¿Controla esfinteres?
        if (kidsFormData.sphincterControlDescription) {
            mutationBody = { ...mutationBody, hasSphincterControlDescription: kidsFormData.sphincterControlDescription }
        }

        // ¿Tiene necesidades educativas especiales?
        if (kidsFormData.specialNeedsDescription) {
            mutationBody = { ...mutationBody, hasSpecialNeedsDescription: kidsFormData.specialNeedsDescription }
        }

        // ¿Tiene alergias, intolerancias alimentarias o dietas especiales?
        if (kidsFormData.allergyOrSpecialDietDescription) {
            mutationBody = { ...mutationBody, hasAnyAllergyOrSpecialDietDescription: kidsFormData.allergyOrSpecialDietDescription }
        }

        // ¿Padece algún síndrome o trastorno?
        if (kidsFormData.anySyndromeDescription) {
            mutationBody = { ...mutationBody, hasAnySyndromeDescription: kidsFormData.anySyndromeDescription }
        }

        mutation.mutate(mutationBody)
    }

    const uploadFiles = async (childId: string) => {
        const statesWithFiles: any = {
            [ChildMediaTypes.DISEASE]: kidsFormData.diseaseFiles,
            [ChildMediaTypes.HAS_ALLERGIES_FOOD_INTOLERANCES_OR_SPECIAL_DIETS]: kidsFormData.allergensFiles,
            [ChildMediaTypes.HAS_DISORDER]: kidsFormData.disorderFiles,
            [ChildMediaTypes.NEEDS_SPECIAL_ATTENTION_AT_ACTIVITY]: kidsFormData.reqSpecialAttentionFiles,
            [ChildMediaTypes.SPECIAL_EDUCATIONAL_NEEDS]: kidsFormData.specialEducationFiles,
            [ChildMediaTypes.CONTROL_SPHINCTERS]: kidsFormData.sphinctersFiles
        }

        const files: any = []

        Object.keys(statesWithFiles).map(async (fileTypeId: string) => {
            const state = statesWithFiles[fileTypeId]
            let totalFileKeys = Object.keys(state).length

            for (let i = 0; i < totalFileKeys; i++) {
                const fileToUpload = state[i]

                files.push({
                    childId: childId,
                    file: fileToUpload,
                    type: fileTypeId
                })
            }
        })

        for(let i in files){
            await uploadMutation.mutateAsync(files[i])
        }

        dispatchSuccess()
    }

    useEffect(() => {
        if(!kidsFormData.center?.id.length){
            return
        }

        save()
    }, [kidsFormData]);

    return (
        <>
            <RequireAuth>
                <DefaultContainer>
                    <Col id={"createKid"}>
                        <h1 className={"pb-4"}>Registro de hijo/a</h1>
                        <p>Este registro no implica la inscripción automática en ninguno de los servicios de Sanse
                            Concilia. Para realizar una inscripción, una vez realizada el alta, debe hacerlo a través
                            de <Link to={ROUTE_PATHS.REQUEST_ITEMS_LIST}>este enlace</Link>.</p>

                        <ChildForm
                            tutorId={tutorId}
                            submitted={submitted}
                            onSubmit={handleSubmit}
                            loading={false}
                        />

                    </Col>
                </DefaultContainer>
            </RequireAuth>
        </>
    )
}
