import {http} from "Services/Http/HttpClient"
import {DropzoneFile} from "Components/FileUpload/FileUpload";
import axios from "axios";
import {getAuth} from "Services/Security/AuthService";
import {ChildMediaTypes} from "Model/Child/ChildMediaTypes";

// age: 11,
export interface CreateChildBodyRequest {
    center: string,
    name: string,
    surnames: string,
    nif?: string,
    documentType?: number,
    courseLevel: string,
    birthDate: string,
    canLeaveWithoutTutor: boolean,
    childDiseases?: string,
    hasDisease: boolean,
    hasSpecialNeeds: boolean,
    hasSpecialNeedsDescription?: string,
    hasSpecialCare: boolean,
    hasSpecialCareDescription?: string,
    hasAnyAllergyOrSpecialDiet: boolean,
    hasAnyAllergyOrSpecialDietDescription?: string,
    hasSphincterControl: boolean,
    hasSphincterControlDescription?: string,
    hasAnySyndrome: boolean,
    hasAnySyndromeDescription?: string,
    canEatPork: boolean,
    observations?: string,
    hasSocialServices: boolean,
    canBeFilmed: boolean
}

export const createChild = async (
    idTutor: string,
    body: CreateChildBodyRequest
) => {
    return await http.post(
        `/tutors/${idTutor}/children`,
        body
    )
}

export const updateChild = async (
    idTutor: string,
    idChild: string,
    body: CreateChildBodyRequest
) => {
    return await http.patch(
        `/tutors/${idTutor}/children/${idChild}`,
        body
    )
}

export const uploadChildFile = async (
    childId: string,
    file: DropzoneFile,
    type: number = ChildMediaTypes.DISEASE
) => {
    let data = new FormData()
    data.append('file', file.file)
    data.append('type', type.toString())

    const fhttp = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "multipart/form-data;",
            "Authorization": getAuth()
        }
    })

    return fhttp.post(`children/${childId}/file`, data)
}

export const uploadTutorFile = async (
    tutorId: string,
    file: DropzoneFile
) => {
    let data = new FormData()
    data.append('file', file.file)

    const fhttp = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "multipart/form-data;",
            "Authorization": getAuth()
        }
    })

    return fhttp.post(`tutors/${tutorId}/file`, data)
}

export const removeFile = async(idFile: string) => {
    return await http.delete(`/children/${idFile}/file`)
}
