import Select from "react-select";
import {useQuery} from "react-query";
import React, {useEffect, useState} from "react";
import {getUserDetail} from "Api/Query/UserQuery";
import {UserService} from "Services/User/UserService";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {SelectOption} from "Model/Select/SelectOption";
import {TutorChildren} from "Model/Tutor/TutorChildren";

interface Props {
    onChange: (newValue: any) => void,
    value: any,
    classes?: string,
    centerIds?: string[]
}

export const ChildSelect : React.FC<Props> = (props) => {

    const [options, setOptions] = useState<any>([])

    const userService = new UserService()
    const userId = userService.getId()

    const {
        isLoading,
        data,
    } = useQuery(['userDetail', userId], () => getUserDetail(userId))

    useEffect(() => {
        if(!data) return

        // @ts-ignore
        const response : ApiListResponse = data?.data
        const childrens : TutorChildren[] = response._result.tutor.children ?? []
        const collection = new SelectOptionCollection()

        if(Object.keys(childrens).length){
            for (const [key, children] of Object.entries(childrens)) {
                const childrenCenterId = children.center.id

                if(props.centerIds && !props.centerIds.includes(childrenCenterId)){
                    continue
                }

                const option = SelectOption.fromPrimitives(children.name, children.id)
                collection.addPrimitive(option)
            }
        }

        setOptions(collection.toPrimitiveOptions())
    }, [data])

    return (
        <Select
            isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
        />
    )
}
