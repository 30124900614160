import {http} from "Services/Http/HttpClient";

export const updateCourse = async(id:string, name: string, position: number|null) : Promise<unknown> => {
    return await http.patch('/course-levels/' + id, {
        name: name,
        position: position
    })
}

export const createCourse = async(name: string, position: number|null) : Promise<unknown> => {
    return await http.post('/course-levels', {
        name: name,
        position:position
    })
}

export const deleteCourse = async(id:string) : Promise<unknown> => {
    return await http.delete('/course-levels/' + id)
}
