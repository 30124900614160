import React from "react";

interface Props{
    value: string
}

export const Time : React.FC<Props> = (props) => {

    return (
        <>
            {props.value.length > 5 && props.value.substring(0, props.value.length - 3)}
            {props.value.length <= 5 && props.value}
        </>
    )
}
