import Select from "react-select";
import React, {useEffect, useState} from "react";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {SelectOption, SelectOptionType} from "Model/Select/SelectOption";
import {renderHtml} from "Services/View/ViewUtils";
import {ActivitySession} from "Model/Activity/ActivitySession";

interface Props {
    onChange: (newValue: SelectOptionType | null) => void,
    value?: any,
    classes?: string,
    sessions: ActivitySession[],
    isDisabled?: boolean
}

export const SessionSelect: React.FC<Props> = (props) => {

    const [options, setOptions] = useState<any>([])
    const [description, setDescription] = useState<string>('')
    const [value, setValue] = useState<SelectOption|null>(props.value ?? null)

    const createFromActivitySessions = () => {
        if (!props.sessions) return

        const collection = new SelectOptionCollection()
        for (const [key, session] of Object.entries(props.sessions)) {
            const sessionStart = session.start ?? ''
            const sessionEnd = session.end ?? ''
            let label = session.name

            if (sessionStart && sessionEnd) {
                label += `: De ${sessionStart.substring(0, sessionStart.length - 3)} a ${sessionEnd.substring(0, sessionEnd.length - 3)}.`
            }

            // if(session.description){
            //     label += ' ' + session.description
            // }

            const option = SelectOption.fromPrimitives(label, session.id)
            collection.addPrimitive(option)
        }

        setOptions(collection.toPrimitiveOptions())
    }

    const onChange = (option:any) => {
        const session: any = props.sessions.filter(item => item.id === option?.value)
        setDescription(session[0]?.description ?? '')
        props.onChange(option)
    }

    useEffect(() => {
        setValue(null)
        createFromActivitySessions()
    }, [props.sessions])

    return (
        <>
            <Select
                // isLoading={isLoading}
                isDisabled={props.isDisabled ?? false}
                options={options}
                placeholder={""}
                noOptionsMessage={() => "No hay resultados"}
                isClearable={true}
                loadingMessage={() => "Cargando ..."}
                onChange={(option:any) => {
                    setValue(option)
                    onChange(option)
                }}
                className={props.classes ?? ''}
                value={value}
            />

            {description.length > 0 && <>
                <br/>
                <div dangerouslySetInnerHTML={renderHtml(description)}></div>
            </>}
        </>
    )
}
