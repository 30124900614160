import {http} from "Services/Http/HttpClient";
import {UserDetailTutor} from "Model/Tutor/Tutor";
import FilterBuilder from "Services/Filters/FilterBuilder";
import {UserService} from "../../Services/User/UserService";
import {createEmptyHttpResponsePromise} from "Model/Shared/EmptyHttpResponsePromise";

export interface UserDetailResponseResult {
    email: string,
    id: string,
    tutor: UserDetailTutor,
    username: string
}

export const getUserDetail = async (id: string): Promise<unknown> => {
    return await http.get(`users/${id}`)
}

export const getUserRoles = async (): Promise<unknown> => {
    return await http.get(`users/roles`)
}

export const getUsers = async (filters: any = {}): Promise<unknown> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get('users' + (queryParams ? '?' + queryParams : ''))
}

export const getTutorChildren = async (filters: any = {}): Promise<unknown> => {
    const userService = new UserService()
    const idTutor = userService.getTutor()

    if(!idTutor){
        return createEmptyHttpResponsePromise()
    }

    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get('tutors/' + idTutor + '/children' + (queryParams ? '?' + queryParams : ''))
}
