import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {deleteCenters} from "Api/Mutation/CenterMutation";
import {getCourseLevels} from "Api/Query/CourseQuery";
import {useMutation} from "react-query";
import {deleteCourse} from "Api/Mutation/CourseMutation";
import {Notificator} from "Services/Notificator/Notificator";
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch} from "hooks";

export const AdminCourceList : React.FC = () => {

    const dispatch = useAppDispatch()
    const [refesh, setRefresh] = useState<boolean>(false)
    const headers: string[] = [
        'CURSO', 'POSICIÓN'
    ]

    const deleteMutation = useMutation({
        mutationKey: ['deleteCourse'],
        mutationFn: (id: string) => deleteCourse(id),
        onSuccess: () => {
            Notificator.success('El curso se ha eliminado correctamente.')
            setRefresh(!refesh)
        },
        onError: () => {
            Notificator.success('Se ha producido un error al eliminar el curso.')
        }
    })

    const remove = (id: string) => {
        if(!window.confirm('¿Deseas eliminar este curso?')){
            return
        }

        deleteMutation.mutate(id)
    }

    useEffect(() => {
        dispatch(appLoading({value: deleteMutation.isLoading, text: 'Eliminando'}))
    }, [deleteMutation.isLoading])

    return (
        <RequireAuth>
            <AdminCrudList
                title={"Listado de cursos"}
                headers={headers}
                queryMethod={getCourseLevels}
                queryName={"adminListCourses"}
                deleteMethod={deleteCenters}
                pageSize={999}
                customFilters={<></>}
                refreshSwitch={refesh}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            <Link to={ROUTE_PATHS.ADMIN_COURSES_EDIT.replace(":id", item.id)}>
                                {item.name}
                            </Link>
                        </td>
                        <td>{item.position}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={ROUTE_PATHS.ADMIN_COURSES_EDIT.replace(":id", item.id)}
                            >
                                Modificar
                            </Button>
                            &nbsp;
                            <Button
                                className={"btn-dark btn-sm"}
                                onClick={() => remove(item.id)}
                            >
                                Eliminar
                            </Button>
                        </td>
                    </>
                }
                createButton={
                    <Button
                        className={"purple-btn"}
                        href={ROUTE_PATHS.ADMIN_COURSES_CREATE}
                    >
                        Crear curso
                    </Button>
                }
            />

        </RequireAuth>
    )
}
