import React from "react";
import {CenterServiceResponseItem} from "../../Api/Query/CenterQuery";

interface Props{
    centerService: CenterServiceResponseItem,
    hoverPointer?: boolean
}

export const CenterServiceLabel : React.FC<Props> = (props) => {

    const showPointer = typeof props.hoverPointer === 'undefined' ? false : props.hoverPointer
    const pointerClass = showPointer ? 'pointer' : ''

    return (
        <label
            htmlFor={props.centerService.id}
            className={"d-block mb-2 " + pointerClass}
        >
            <span className={"fw-bold"}>{props.centerService.name} +{props.centerService.price} €</span><br/>
            <small>{props.centerService.center.name}</small>
        </label>
    )

}