import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Notificator} from "Services/Notificator/Notificator";
import CurrencyInput from "react-currency-input-field";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {SimpleTable} from "Components/Table/SimpleTable";
import {Enabled} from "Components/Enabled/Enabled";

interface Props {
    onSave: (formData: { [key: string]: any }, services: any) => void,
    name?: string,
    address?: string,
    services?: any
}

export const AdminCentersForm: React.FC<Props> = (props) => {

    const [formData, setFormData] = useState<{ [key: string]: any }>({
        name: props.name ?? '',
        address: props.address ?? '',
        serviceName: '',
        servicePrice: '',
        serviceBillable: true,
        serviceComment: ''
    })
    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({
        name: false,
        address: false
    })
    const [submited, setSubmited] = useState<boolean>(false)
    const [services, setServices] = useState<any>([])

    const validate = (): boolean => {
        let errors: number = 0
        let currentFormErrors = {}

        if (!formData.name) {
            currentFormErrors = {
                ...currentFormErrors,
                name: true
            }
            errors++
        }

        if (!formData.address) {
            currentFormErrors = {
                ...currentFormErrors,
                address: true
            }
            errors++
        }

        if (errors === 0) {
            return true
        }

        setFormErrors(currentFormErrors)
        return false
    }

    const save = () => {
        if (!submited) {
            setSubmited(true)
        }

        if (!validate()) {
            return
        }

        props.onSave(formData, services)
    }

    const validateService = () => {
        let errors: number = 0
        let currentFormErrors = {}

        if (!formData.serviceName) {
            currentFormErrors = {
                ...currentFormErrors,
                serviceName: true
            }
            errors++
        }

        if (formData.servicePrice !== undefined && formData.servicePrice.toString().length === 0) {
            currentFormErrors = {
                ...currentFormErrors,
                servicePrice: true
            }
            errors++
        }

        if (errors === 0) {
            return true
        }

        setFormErrors(currentFormErrors)
        return false
    }

    const serviceAlreadyExists = (): boolean => {
        if (!services.length) {
            return false
        }

        let found = false
        services.map((service: any) => {
            if (service.name === formData.serviceName) {
                found = true
            }
        })

        if (found) {
            return found
        }

        return false
    }

    const addServiceItem = (
        name: string,
        price: number,
        billable: boolean,
        description: string,
        id: any = null
    ) => {
        const newServices = services
        newServices.push({
            billable: billable,
            name: name,
            description: description,
            price: price,
            id: id
        })
        setServices(newServices)
    }

    const addService = () => {
        if (!validateService()) {
            return false
        }

        if (serviceAlreadyExists()) {
            Notificator.error('Este servicio ya existe.')
            return
        }

        const isBillable = formData.serviceBillable === undefined ? true : formData.serviceBillable
        addServiceItem(
            formData.serviceName,
            formData.servicePrice,
            isBillable,
            formData.serviceComment
        )

        let d = formData
        d = {...d, serviceName: ''}
        d = {...d, serviceComment: ''}
        d = {...d, servicePrice: '0.00'}
        d = {...d, serviceBillable: true}
        setFormData(d)
    }

    const deleteService = (key: number) => {
        if (!window.confirm("¿Deseas eliminar este servicio?")) {
            return
        }

        const newServices = services.filter((serv: any, servKey: number) => servKey !== key)
        setServices(newServices)
    }

    useEffect(() => {
        if (!formData.name && !formData.address) {
            setFormData({
                name: props.name,
                address: props.address
            })
        }
    }, [props.name, props.address]);

    useEffect(() => {
        if (!props.services) {
            return
        }

        const propServices = props.services.items ?? []

        if (!propServices.length) {
            return
        }

        const serv: any = []
        propServices?.map((service: any) => {
            serv.push({
                billable: service.billable,
                name: service.name,
                price: service.price,
                description: service.description,
                id: service.id
            })
        })
        setServices(serv)

    }, [props.services]);

    useEffect(() => {
        if (submited) {
            validate()
        }
    }, [formData]);

    return (
        <>
            <Form className={"row mt-4"}>
                <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId={"queryFilter"}>
                        <Form.Label>
                            Nombre del centro
                            &nbsp;<FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                name: e.target.value
                            })}
                            required
                            className={formErrors.name ? 'is-invalid' : ''}
                            value={formData.name ?? ''}
                        />
                        {formErrors.name && <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId={"queryFilter"}>
                        <Form.Label>
                            Dirección
                            &nbsp;<FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                ...formData,
                                address: e.target.value
                            })}
                            required
                            className={formErrors.address ? 'is-invalid' : ''}
                            value={formData.address ?? ''}
                        />
                        {formErrors.address && <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                    </Form.Group>
                </Col>
            </Form>

            <Form className={"row"} style={{'paddingTop': '50px'}}>
                <h5>Servicios del centro</h5>
                <div>
                    {services.length === 0 && <p>No has añadido ningún servicio</p>}
                    {
                        services.length > 0 &&
                        <SimpleTable
                            bordered={true}
                            border={1}
                            loading={false}
                            headers={
                                <>
                                    <th>Servicio</th>
                                    <th>Descripción</th>
                                    <th>Precio</th>
                                    <th>Facturable</th>
                                    <th></th>
                                </>
                            }
                        >
                            {
                                services.map((service: any, key: number) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                {service.name}
                                            </td>
                                            <td>
                                                {service.description}
                                            </td>
                                            <td>
                                                <MoneyAmount amount={service.price}/>
                                            </td>
                                            <td>
                                                <Enabled enabled={service.billable} />
                                            </td>
                                            <td className={"d-flex justify-content-center align-middle"}>
                                                <Button
                                                    variant={"danger"}
                                                    size={"sm"}
                                                    className={"py-0"}
                                                    onClick={() => deleteService(key)}
                                                >Eliminar</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </SimpleTable>
                    }
                </div>
                <div className={"clearfix"}></div>
                <br/>
                <br/>

                <h5>Añadir nuevo servicio</h5>
                <Row>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Group controlId={"queryFilter"}>
                            <Form.Label>
                                Nombre del servicio
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    serviceName: e.target.value
                                })}
                                required
                                className={formErrors.serviceName ? 'is-invalid' : ''}
                                value={formData.serviceName ?? ''}
                            />
                            {formErrors.name && <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Group controlId={"queryFilter"}>
                            <Form.Label>
                                Precio del servicio
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>

                            <CurrencyInput
                                id="price"
                                name="price"
                                className={"form-control" + (formErrors.servicePrice ? ' is-invalid' : '')}
                                defaultValue={'0.00'}
                                decimalsLimit={2}
                                decimalSeparator={"."}
                                decimalScale={2}
                                onValueChange={(value, name, values: any) =>
                                    setFormData({...formData, servicePrice: values.float ?? 0})
                                }
                            />
                            {formErrors.name && <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={2} md={2} className={"d-flex align-items-end"}>
                        <Form.Group>
                            <Form.Label htmlFor={"billable"}>
                                <input
                                    type="checkbox"
                                    id={"billable"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                        ...formData,
                                        serviceBillable: e.target.checked
                                    })}
                                    checked={formData.serviceBillable !== undefined ? formData.serviceBillable : true}
                                />
                                &nbsp;Facturable
                            </Form.Label>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={2} md={2} className={"d-flex align-items-end"}>
                        <Button
                            className={"purple-btn btn-sm"}
                            onClick={addService}
                        >
                            Añadir servicio
                        </Button>
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col xs={12} sm={8} md={8}>
                        <Form.Group controlId={"queryFilter"}>
                            <Form.Label>
                                Comentario del servicio
                            </Form.Label>
                            <textarea
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFormData({
                                    ...formData,
                                    serviceComment: e.target.value
                                })}
                                required
                                className={(formErrors.serviceComment ? 'is-invalid' : '') + ' form-control'}
                                rows={4}
                                value={formData.serviceComment ?? ''}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Row style={{"paddingTop": "50px"}}>
                <Col xs={12} sm={4}>
                    <Button
                        className={"purple-btn mt-4"}
                        onClick={() => save()}
                    >
                        Guardar
                    </Button>
                </Col>
            </Row>
        </>
    )
}
