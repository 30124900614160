import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormErrorText} from "Components/Form/FormErrorText";
import {DropzoneFile, FileUpload} from "Components/FileUpload/FileUpload";
import {v4 as uuidv4} from 'uuid';
import Toggle from "react-toggle";

export interface Props {
    maxFiles?: number
    maxFileSize?: number
    error?: string
    formLabel: string
    checked?: boolean
    files?: DropzoneFile[]
    hasFiles: boolean
    onChange: (checked: boolean, files: DropzoneFile[]) => void
}

export const GivenDocumentation: React.FC<Props> = (props) => {

    const controlId: string = uuidv4()
    const [checked, setChecked] = useState<boolean>(props.checked ?? true)
    const [files, setFiles] = useState<DropzoneFile[]>(props.files ?? [])

    useEffect(() => {
        props.onChange(checked, files)
    }, [checked, files]);

    return (
        <>
            <Form.Group
                controlId={controlId}
                className={"mb-4"}
            >
                <label
                    htmlFor={controlId}
                    className={"d-block mb-2 fw-bold pointer"}
                >
                    {props.formLabel}
                </label>
                <Toggle
                    id={controlId}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
            </Form.Group>

            {!checked && props.hasFiles &&
                <>
                    {props.hasFiles &&
                        <FileUpload
                            onChange={(files) => setFiles(files)}
                            maxFiles={props.maxFiles ?? 5}
                            maxFileSize={props.maxFileSize ?? (50 * 1024 * 1024)}
                            defaultFiles={files}
                        />
                    }

                    <FormErrorText error={props.error ?? ''}/>
                    <br/>
                </>
            }
        </>
    )

}
