import React from "react";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useIsDesktop, useIsMobile} from "Hooks/Window/ResizeHook";
import {NavbarItem} from "Views/Shared/Navbar/NavbarItem";
import {ToggleMenuMobileIcon} from "Views/Shared/Navbar/ToggleMenuMobileIcon";

export const UserNavbar: React.FC = () => {

    const isDesktop = useIsDesktop()

    return (
        <>
            {
                isDesktop &&
                <>
                    <NavbarItem
                        label={"Inicio"}
                        route={ROUTE_PATHS.DASHBOARD}></NavbarItem>
                    <NavbarItem
                        label={"Hijos/as"}
                        route={ROUTE_PATHS.KIDS_LIST}></NavbarItem>
                    <NavbarItem
                        label={"Actividades"}
                        route={ROUTE_PATHS.ACTIVITIES_LIST}></NavbarItem>
                    <NavbarItem
                        label={"Inscripciones"}
                        route={ROUTE_PATHS.REQUEST_ITEMS_LIST}></NavbarItem>
                    <NavbarItem
                        label={"Mi Perfil"}
                        route={ROUTE_PATHS.TUTOR_DATA_UPDATER}></NavbarItem>
                </>
            }

            <ToggleMenuMobileIcon />

        </>
    )
}
