import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {Button, Col, Form, Row} from "react-bootstrap"
import {DefaultContainer} from "Views/Shared/DefaultContainer"
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk"
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch} from "hooks";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Activity} from "Model/Activity/Activity";
import {ChildSelect} from "Components/Child/ChildSelect";
import {SessionSelect} from "Components/Activities/SessionSelect";
import {SelectOptionType} from "Model/Select/SelectOption";
import {SessionDaySelect} from "Components/Activities/SessionDaySelect";
import {ActivitySession} from "Model/Activity/ActivitySession";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {InitDateCalendar} from "Components/Activities/InitDateCalendar";
import {MultiValue} from "react-select";
import {ActivityService} from "Model/Activity/ActivityService";
import Toggle from 'react-toggle'
import {addCartItem, AddCartItemBody} from "Api/Mutation/CartMutation";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {increaseCartCounter} from "Store/cartSlice";
import {SelectDatesCalendar} from "Components/Activities/SelectDatesCalendar";
import DateObject from "react-date-object";
import DateService from "Services/Date/DateService";
import {IdName} from "Model/Shared/IdName";
import {Observations} from "Components/Activities/Observations";
import {getUserDetail} from "Api/Query/UserQuery";
import {UserService} from "Services/User/UserService";
import {getActivitySessionByChild} from "../../Api/Query/ActivityQuery";

interface Props {
    activity: Activity
}

export const ActivityWeeklyDetail: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [session, setSession] = useState<SelectOptionType | null>(null)
    const [services, setServices] = useState<ActivityService[]>([])

    const [selectedActivitySession, setSelectedActivitySession] = useState<ActivitySession | null>(null)
    const [selectedServices, setSelectedServices] = useState<Array<{ serviceId: string, activityId: string }>>([])
    const [selectedChild, setSelectedChild] = useState<SelectOptionType | null>(null)

    const [activity, setActivity] = useState<Activity>(props.activity)
    const [activityDays, setActivityDays] = useState<MultiValue<SelectOptionType> | null>([])
    const [activityInitDate, setActivityInitDate] = useState<any>(null)
    const [activitySessionCustomDays, setActivitySessionCustomDays] = useState<Date[]>([])
    const [childActivitySessions, setChildActivitySessions] = useState<ActivitySession[]>([])

    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [centerIds, setCenterIds] = useState<string[]>([])
    const [observations, setObservations] = useState<string>('')

    const userService = new UserService()
    const userId = userService.getId()

    // TODO: use react query cache
    const userDetailQuery: any = useQuery(['userDetail', userId], () => getUserDetail(userId))

    const sessionsByChildQuery = useQuery({
        enabled: false,
        queryKey: ['getActivitySessionByChild', activity.id, selectedChild?.value],
        queryFn: () => getActivitySessionByChild(activity.id, selectedChild?.value ?? ''),
        onSuccess: (data: any) => {
            setChildActivitySessions(data.data._result)
        },
        onError: () => {
            setChildActivitySessions([])
        }
    })

    useEffect(() => {
        if (!props.activity) {
            return
        }

        const centers: string[] = []
        props.activity.centers.map((center: IdName) => centers.push(center.id))
        setCenterIds(centers)

        // save all ActivitySessions in one state var
        // setActivitySessions(props.activity.sessions)

    }, [props.activity]);

    const onActivitySessionCustomDaysChange = (selectedDates: DateObject[] | null) => {
        if (selectedDates && !Object.keys(selectedDates).length) {
            setActivitySessionCustomDays([])
            return
        }

        const customDays: Date[] = []
        selectedDates?.map(date => {
            const month = DateService.addLeftZero(date.month.number)
            const day = DateService.addLeftZero(date.day)
            let stringDate = `${date.year}-${month}-${day}`
            const dateObject = new Date(stringDate)
            customDays.push(dateObject)
        })

        setActivitySessionCustomDays(customDays)
    }

    const addToCartMutation = useMutation({
        mutationFn: (body: AddCartItemBody) => addCartItem(body),
        onSuccess: () => {
            dispatch(increaseCartCounter())
            Notificator.success(`“${activity.name}” se ha añadido a tu carrito.`)
            navigate(ROUTE_PATHS.SHOPPING_CART)
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const isFormValid = (): boolean => {
        if (!selectedActivitySession) {
            return false
        }

        if (!activityInitDate && (selectedActivitySession.numberOfDays > 0 && Object.keys(activitySessionCustomDays).length === 0)) {
            return false
        }

        if (!isCustomDaySession && !activityDays) {
            return false
        }

        if (!selectedChild) {
            return false
        }

        return true
    }

    const findSelectedServicesObject = (): ActivityService[] | null => {
        if (!selectedServices || !selectedActivitySession) return null

        const services: ActivityService[] = []
        const servicesHashmap: { [key: string]: ActivityService } = {}
        selectedActivitySession.services.map(service => {
            servicesHashmap[service.id] = service
        })

        for (let serviceId in selectedServices) {
            const checked = selectedServices[serviceId]
            if (!checked) continue
            services.push(servicesHashmap[serviceId])
        }

        return services
    }

    const calculatePrice = () => {
        const services = findSelectedServicesObject()
        const totalSelectedCustomDays = Object.keys(activitySessionCustomDays).length
        const hasSelectedDay = totalSelectedCustomDays > 0 || activityInitDate

        let priceSum = 0
        services?.map(service => {
            priceSum += service.price
        })

        if (selectedActivitySession && hasSelectedDay) {
            priceSum += selectedActivitySession.price
        }

        if (isCustomDaySession && totalSelectedCustomDays > 0) {
            // total price = day * unit price
            priceSum = priceSum * totalSelectedCustomDays
        }

        setTotalPrice(priceSum)
    }

    /**
     * Show the custom day Calendar (user can select one or more days without restrictions) if this ActivitySession properties match with the following rules:
     *
     *  - Number of days => 0
     *  - ActivitySession doesn't have weekly days to select.
     */
    const isCustomDaySession = selectedActivitySession?.numberOfDays === 0 && selectedActivitySession?.days.length === 0

    const areDaysSelected = (): boolean => {
        return !!(activityDays && activityDays.length)
    }

    const findSelectedSessionObject = (): ActivitySession | null => {
        if (!session) return null
        const res = activity.sessions.filter(item => item.id === session.value)
        return res ? res[0] : null
    }

    function createActivityContent() {
        return {__html: activity.description};
    }

    const createRequest = (): void => {
        if (isCustomDaySession && (!selectedActivitySession || Object.keys(activitySessionCustomDays).length === 0)) {
            return
        }

        if (!isCustomDaySession && (!selectedActivitySession || (selectedActivitySession.numberOfDays > 0 && !activityInitDate) || !activityDays)) {
            console.log('return request')
            return
        }

        if (!selectedChild) return

        let formattedInitDate = activityInitDate ? DateService.convertDateToYYYYMMDD(activityInitDate) : ''

        const days: string[] = []

        if (!isCustomDaySession && activityDays) {
            activityDays.map(day => days.push(day.value))
        } else {
            activitySessionCustomDays.map((date: Date) => days.push(DateService.convertDateToYYYYMMDD(date)))
        }

        const services: string[] = []
        const markedServices = findSelectedServicesObject()
        if (markedServices) {
            markedServices.map(service => services.push(service.id))
        }

        // check Min and Max days
        const totalSelectedDays = days.length
        const numberOfDays = selectedActivitySession?.numberOfDays

        if (numberOfDays && totalSelectedDays < numberOfDays) {
            Notificator.error(`Debes seleccionar ${numberOfDays} días para esta modalidad.`, 'Error')
            return
        }

        if (numberOfDays && numberOfDays > 0 && (totalSelectedDays > numberOfDays)) {
            Notificator.error(`Solo se pueden seleccionar ${numberOfDays} días como máximo para esta modalidad.`, 'Error')
            return
        }

        let body: AddCartItemBody = {
            idActivitySession: selectedActivitySession.id,
            idActivitySessionServices: services,
            days: days,
            idChild: selectedChild.value,
            observations: observations
        }

        if (!isCustomDaySession) {
            body = {...body, initDate: formattedInitDate}
        }

        // console.log(body)
        addToCartMutation.mutate(body)
    }

    const onSessionChange = (session: SelectOptionType | null) => {
        setSession(session)
    }

    const onChildChange = (option: SelectOptionType) => {
        setSelectedChild(option)
    }

    useEffect(() => {
        dispatch(
            appLoading({
                value: addToCartMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [addToCartMutation.isLoading])

    useEffect(() => {
        dispatch(
            appLoading({
                value: sessionsByChildQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [sessionsByChildQuery.isLoading])

    useEffect(() => {
        dispatch(
            appLoading({
                value: sessionsByChildQuery.isRefetching,
                text: 'Cargando'
            })
        )
    }, [sessionsByChildQuery.isRefetching])

    useEffect(() => {
        setSelectedActivitySession(findSelectedSessionObject())
        setSelectedServices([])
        setActivityDays(null)
        setActivityInitDate(null)
        setActivitySessionCustomDays([])
    }, [session])

    useEffect(() => {
        let tutorChildren = userDetailQuery?.data?.data._result.tutor.children ?? null
        let selectedChildCenterId = null

        // Get selected children center
        tutorChildren?.map((child: any) => {
            if (child.id === selectedChild?.value) {
                selectedChildCenterId = child.center.id
            }
        })

        // Get services for current child center
        const selectedChildrenCenterServices: any = []
        selectedActivitySession?.services.map((service: any) => {
            if (service.center.id === selectedChildCenterId) {
                selectedChildrenCenterServices.push(service)
            }
        })

        // If activitySession has 5 REQUIRED days, we select them automatically
        if (selectedActivitySession?.numberOfDays === 5) {
            const dayOptions: any[] = []
            selectedActivitySession.days.map((d: any) => {
                dayOptions.push({
                    label: d.name,
                    value: d.id
                })
            })
            setActivityDays(dayOptions)
        }

        setServices(selectedChildrenCenterServices ?? [])
    }, [selectedActivitySession, selectedChild])

    useEffect(() => {
        calculatePrice()
    }, [selectedServices, activitySessionCustomDays, activityInitDate])

    useEffect(() => {
        if(!selectedChild){
            setChildActivitySessions([])
            return
        }

        sessionsByChildQuery.refetch()
    }, [selectedChild]);

    return (
        <div id={"activityDetailUserView"}>
            <Col
                className={"activityBgImage"}
                style={
                    activity.backgroundImage ? {
                        backgroundImage: `url(${activity.backgroundImage})`,
                        backgroundSize: '100% 100%'
                    } : {
                        background: '#bdc3c7'
                    }
                }
            >
                &nbsp;
            </Col>

            <DefaultContainer
                classes={"activityImageContainer px-0"}
            >
                <Row>
                    <Col
                        className={"px-sm-0 d-flex align-items-end  justify-content-center  justify-content-sm-left"}></Col>
                    <Col className={"d-flex justify-content-end"}>
                        <img
                            className={"activityImage"}
                            src={activity.image}
                            alt={activity.name}
                            title={activity.name}
                        />
                    </Col>
                </Row>
                <Row>
                    <h1>{activity.name}</h1>
                </Row>
            </DefaultContainer>

            <DefaultContainer>
                <Col id={"activity_detail"} className={"px-sm-0"}>

                    <div dangerouslySetInnerHTML={createActivityContent()}></div>

                    <Row className={"formContainer"}>
                        <Col xs={12} sm={6}>
                            <Form.Group
                                controlId="child"
                                className="my-3"
                            >
                                <Form.Label>
                                    Nombre del menor <FormLabelRequiredAsterisk/>
                                </Form.Label>

                                <ChildSelect
                                    onChange={onChildChange}
                                    centerIds={centerIds}
                                    value={""}
                                />
                                {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <Form.Group
                                controlId="modality"
                                className="my-3"
                            >
                                <Form.Label>
                                    Modalidad <FormLabelRequiredAsterisk/>
                                </Form.Label>
                                <SessionSelect
                                    onChange={(session) => onSessionChange(session)}
                                    sessions={childActivitySessions}
                                    isDisabled={!selectedChild}
                                />
                                {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                            </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                            {!isCustomDaySession &&
                                <Form.Group
                                    controlId="sessionDay"
                                    className="my-3"
                                >
                                    <Form.Label>
                                        Días <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <SessionDaySelect
                                        onChange={(values) => setActivityDays(values ?? [])}
                                        session={selectedActivitySession}
                                        value={activityDays}
                                        isDisabled={!selectedChild || !selectedActivitySession || !selectedActivitySession.days.length}
                                    />
                                </Form.Group>
                            }
                        </Col>
                    </Row>

                    {
                        session &&
                        areDaysSelected() &&
                        selectedActivitySession &&
                        selectedActivitySession?.numberOfDays > 0 &&
                        <Row>
                            <Col sm={6} xs={12}>
                                <Form.Group
                                    controlId="child"
                                    className="my-3"
                                >
                                    <InitDateCalendar
                                        session={selectedActivitySession}
                                        selectedDays={activityDays}
                                        onChange={(e) => setActivityInitDate(e)}
                                        initDate={activity.initPaymentDate}
                                        endDate={activity.endPaymentDate}
                                        numberOfDays={selectedActivitySession?.numberOfDays}
                                    />
                                    {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                                </Form.Group>
                            </Col>
                        </Row>
                    }

                    {
                        session && isCustomDaySession &&
                        <SelectDatesCalendar
                            session={selectedActivitySession}
                            selectedDays={activityDays}
                            onChange={onActivitySessionCustomDaysChange}
                            initDate={activity.startDate}
                            endDate={activity.endDate}
                            numberOfDays={selectedActivitySession?.numberOfDays}
                        />
                    }

                    {
                        services.length > 0 &&
                        <Row>
                            <Col>
                                <Form.Group
                                    controlId="services"
                                    className="my-3"
                                >
                                    <Form.Label>
                                        Servicios <FormLabelRequiredAsterisk/>
                                    </Form.Label>

                                    <Row>
                                        {
                                            services.map((service: ActivityService, key: number) => {
                                                return <Col md={3} key={key}>
                                                    <label
                                                        htmlFor={service.id}
                                                        className={"d-lock mb-2 fw-bold pointer"}
                                                    >
                                                        <Toggle
                                                            id={service.id}
                                                            checked={typeof selectedServices[service.id] !== 'undefined' ? selectedServices[service.id] : false}
                                                            onChange={(e) => setSelectedServices({
                                                                ...selectedServices,
                                                                [service.id]: e.target.checked
                                                            })}
                                                        />
                                                        <span style={{
                                                            "paddingLeft": "13px",
                                                            "verticalAlign": "top"
                                                        }}>{service.name} +{service.price} €</span>
                                                    </label>
                                                    {service.description &&
                                                        <label
                                                            title={(service.name + ' ' + service.price + '€')}
                                                            htmlFor={service.id}
                                                            className={"d-block pointer"}
                                                            style={{
                                                                "fontWeight": "normal",
                                                                "fontSize": "14px"
                                                            }}>{service.description}</label>
                                                    }
                                                </Col>
                                            })
                                        }
                                    </Row>

                                    {/*{errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}*/}
                                </Form.Group>
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col>
                            <Observations
                                onChange={(value: string) => setObservations(value)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className={"col-12 text-center text-sm-start "}>
                            <Form.Group
                                controlId="child"
                                className="my-3"
                            >
                                <span>Total</span>
                                <span className={"price d-block"}>
                                            <MoneyAmount amount={totalPrice}/>
                                        </span>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>

                    <div className="d-grid d-sm-block gap-2">
                        <Button
                            onClick={createRequest}
                            className={"purple-btn btn-xs-block"}
                            disabled={!isFormValid()}
                        >
                            Solicitar inscripción
                        </Button>
                    </div>

                </Col>
            </DefaultContainer>
        </div>
    )
}
