import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useNavigate, useParams} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation, useQuery} from "react-query";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {AdminCourseForm} from "Views/Course/AdminCourseForm";
import {getCourseLevelDetail} from "Api/Query/CourseQuery";
import {updateCourse} from "Api/Mutation/CourseMutation";

export const AdminCourseEdit : React.FC = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [entity, setEntity] = useState<any>({
        name: "",
        position: null
    })

    const update = useMutation({
        mutationKey: [],
        mutationFn: (body: any) => updateCourse(body.id, body.name, body.position),
        onSuccess: () => {
            Notificator.success('El curso se ha editado correctamente')
            navigate(ROUTE_PATHS.ADMIN_COURSES_LIST)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al editar el curso')
            navigate(ROUTE_PATHS.ADMIN_COURSES_LIST)
        }
    })

    const onSave = (data: any) => {
        update.mutate({
            id: id,
            name: data.name,
            position: data.position ? parseInt(data.position) : null
        })
    }

    const query = useQuery({
        enabled: false,
        queryKey: ['getCourseLevelDetail', id],
        queryFn: () => getCourseLevelDetail(id ?? '-1'),
        onSuccess: (response: any) => {
            setEntity(response.data._result)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al cargar los datos.')
        }
    })

    useEffect(() => {
        query.refetch()
    }, []);

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: update.isLoading, text: 'Cargando'}))
    }, [update.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Detalle del curso</h1>
                        </Col>

                    </Row>

                    <Row>
                        <AdminCourseForm
                            onSave={onSave}
                            data={entity}
                        />
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
