export function trimString(value: string, maxLength: number) : string
{
    const extensionIndex = value.lastIndexOf('.');
    const hasExtension = extensionIndex > 0 && extensionIndex < value.length - 1;
    const extension = hasExtension ? value.slice(extensionIndex) : '';
    const namePart = hasExtension ? value.slice(0, extensionIndex) : value;

    if (namePart.length > maxLength) {
        return namePart.slice(0, maxLength) + '...' + extension;
    }

    return value;
}