import React, {useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import Select from 'react-select'

export const RemoveRequest: React.FC = () => {

    const [activityName, setActivityName] = useState<string>('Los primeros del cole 2022/2023')

    const fakeOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"removeRequest"}>
                    <h1 className={"pb-4"}>Baja de Inscripción</h1>
                    <h3 className={"purple"}>{activityName}</h3>
                    <br/>

                    <Form>
                        <Row className={"my-4"}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Seleccione Niño/a <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Tipo de baja <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Núm. Solicitud
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Grupo
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Fecha Día No Lectivos
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Seleccione Número de Inscripción <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Estado
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Servicio
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Centro
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                                <Form.Group className={"mt-4"}>
                                    <Form.Label>
                                        Junio / Septiembre
                                    </Form.Label>
                                    <Select
                                        options={fakeOptions}
                                        placeholder={""}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                    <Button
                        className={"purple-btn mt-4"}
                    >
                        Cancelar inscripción
                    </Button>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
