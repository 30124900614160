import React from "react";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "../../Config/Router/Routes";

interface Props{
    classes?: string
}

export const PrivacyPolicyLink : React.FC<Props> = (props: Props) => {

    return (
        <Link to={ROUTE_PATHS.PRIVACY_POLICY} target={"_blank"} className={props.classes ?? ''}>Política de Privacidad</Link>
    )

}