import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useQuery} from "react-query";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {getCourseLevels} from "Api/Query/CourseLevelQuery";

interface Props {
    value: any,
    onChange: (newValue: any) => void,
    classes?: string
}

export const CourseLevelSelect: React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(null)
    const [options, setOptions] = useState<any>([])

    const {
        isLoading,
        isError,
        data,
        error,
        refetch
    } = useQuery(['courseLevelList'], () => getCourseLevels())

    useEffect(() => {
        if(!data) return

        // @ts-ignore
        const response : ApiListResponse = data?.data
        const collection = SelectOptionCollection.fromArray(response, 'name', 'id')
        setOptions(collection.toPrimitiveOptions())
    }, [data])

    useEffect(() => {
        if(options.length && props.value === null){
            setValue(props.value)
            return
        }

        if (!props.value || !options.length) {
            return
        }

        const values = options.filter(option => option.value === props.value?.value)
        setValue(values[0] ?? null)
    }, [props.value, options])

    return (
        <Select
            isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
            value={value}
        />
    )

}
