const translationReplacements = {
    "[zipCode]: This value should have exactly 5 characters.": "- El código postal ha de tener 5 caracteres.",
    "This value should have exactly 5 characters": "Ha de tener 5 caracteres",
    "Tutor NIF: NIF not valid": "- El DNI/NIE del tutor no es válido.",
    "Account Holder NIF: NIF not valid": "- El NIF del titular de la cuenta bancaria no es válido.",
    "not valid" : "no es válido"
}

export class ErrorTranslator {

    /**
     * @param text
     */
    static trans = (text: string) : string => {
        for (const [original, translation] of Object.entries(translationReplacements)) {
            text = text.replaceAll(original, translation)
        }

        const fieldNameRegex = /\[.*\]\:\s/ig;
        text = text.replaceAll(fieldNameRegex, "- ")
        text = text.replaceAll("\n", "<br />")

        return text
    }
}
