import React from "react";

interface Props {
    error: string
}

export const FormErrorText: React.FC<Props> = (props) => {
    return (
        <>
            {props.error && <p className={"text-danger pt-2"}>{props.error}</p>}
        </>
    )

}