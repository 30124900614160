import {InvalidEmailException} from "../Exceptions/InvalidEmailException";

export class Email
{
    private readonly regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    private value: string;

    constructor(email: string) {
        this.value = email
        this.validate()
    }

    private validate() : void
    {
        if(!this.value.match(this.regex)){
            throw new InvalidEmailException(this.value)
        }
    }

    getValue(): string {
        return this.value;
    }
}