import React from "react";
import {UserDataUpdater} from "../User/Update/UserDataUpdater";
import {useParams} from "react-router-dom";

/**
 * Tutor data
 * @constructor
 */
export const AdminTutorEdit: React.FC = () => {

    const {id} = useParams()

    return (
        <UserDataUpdater userId={id ?? '-1'} />
    )
}
