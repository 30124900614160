import React from "react";
import {DATA_SECURITY_DOC_URL} from "Config/Router/Routes";

interface Props {
    label?: string
}

export const DataProtectionLink: React.FC<Props> = (props) => {

    return (
        <a href={DATA_SECURITY_DOC_URL} target={"_blank"}>
            {props.label ?? <>Información de protección de datos</>}
        </a>
    )

}