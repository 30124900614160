import React from "react";
import {Navigate} from "react-router-dom";
import {authCheck} from "Services/Security/AuthService";

interface Props {
    children: React.ReactNode
}

export const RequireAuth: any = (props : Props) => {
    return authCheck() ? props.children : <Navigate to="/" replace />;
}
