import React from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useNavigate} from "react-router-dom";

interface Props {
    showAlert: boolean,
    showButton: boolean
}

export const TutorHasNotEnteredPersonalInfo: React.FC<Props> = (props) => {

    const navigate = useNavigate()

    return (
        <>
            {
                props.showAlert &&
                <Row>
                    <Col>
                        <Alert variant={"warning my-4"}>
                            Todavía no has introducido los datos del padre/madre/tutor.
                        </Alert>
                    </Col>
                </Row>
            }
            {
                props.showButton &&
                <Row className={""}>
                    <Col className={"mb-4"}>
                        <Button
                            className={"btn purple-btn"}
                            onClick={() => navigate(ROUTE_PATHS.TUTOR_DATA_UPDATER)}
                        >
                            Añadir datos del Padre/Madre/Tutor
                        </Button>
                    </Col>
                </Row>
            }
        </>
    )

}
