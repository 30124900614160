import React from "react";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Col} from "react-bootstrap";

export const LegalWarning: React.FC = () => {

    return (
        <DefaultContainer>
            <Col id={"signup"} className={"mx-md-auto"}>
                <h1>Aviso legal</h1>
            </Col>
        </DefaultContainer>
    )
}
