import axios from "axios"
import {getAuth, removeAuthToken, setAuthToken} from "Services/Security/AuthService";
import {Notificator} from "Services/Notificator/Notificator";
import {refreshToken} from "Api/Query/SecurityQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import { ImpersonationService } from "Services/User/ImpersonationService";

// check impersonation
const impersonationService = new ImpersonationService()
const impersonatedUser = impersonationService.getImpersonationUser()

// http
let httpHeaders = {
    "Content-type": "application/json"
}

if(impersonatedUser && Object.keys(impersonatedUser).length > 0){
    httpHeaders['act-as'] = impersonatedUser.id
}

export const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: httpHeaders
})

/**
 * Axios interceptors
 * @doc https://codevoweb.com/react-query-context-api-axios-interceptors-jwt-auth/
 */

/**
 * Authorization token interceptor
 */
http.interceptors.request.use(function (config:any) {
    const authToken = getAuth()

    if(authToken){
        config.headers.Authorization = authToken;
    }

    return config;
});

/**
 * Invalid token interceptor
 */
http.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log('error interct', error)
        const originalRequest = error.config
        const response : any = error.response
        const errorMessage = response.data._error.message

        // Remove session for invalid token
        if(errorMessage === 'Invalid Token' || errorMessage === 'Token no valido'){
            removeAuthToken()
            Notificator.warning('Tu sesión ha caducado.')
            window.location.href = ROUTE_PATHS.LOGIN
            return Promise.reject()
        }

        // Refresh expired token
        if(
            (errorMessage === 'Expired Token' || errorMessage === 'Sesion Expirada')
            && response.data._error.status_code === 401
        ){
            return await refreshToken()
                .then(response => {
                    const responseData = response.data
                    const token : string = responseData._result.token ?? ''

                    if(token){
                        setAuthToken(token)

                        // retry original request
                        originalRequest._retry = true;
                        return http(originalRequest);
                    }
                })
        }

        return Promise.reject(error);
    }
);
