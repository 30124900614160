export function getFirstDayForNextWeek() : Date
{
    // Get the current date
    var currentDate = new Date();
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    // Get the current day of the week (0-6)
    var currentDayOfWeek = currentDate.getDay();

    // Calculate the number of remaining days until the end of the week
    var remainingDays = (currentDayOfWeek > 0) ? (6 - currentDayOfWeek) : 0;

    // Calculate the first day of the following week
    var firstDayOfFollowingWeek = new Date(currentDate);
    firstDayOfFollowingWeek.setDate(currentDate.getDate() + remainingDays + 2);

    // Si el primer dia de la semana que viene es LUNES, el usuario se debe inscribir el MARTES,
    // para que les de tiempo a gestionar la inscripción.
    const firstWeekDayNumberOfFollowingWeek = firstDayOfFollowingWeek.getDay()
    if(firstWeekDayNumberOfFollowingWeek === 1){
        firstDayOfFollowingWeek.setDate(firstDayOfFollowingWeek.getDate() + 1);
    }

    // Output the first day of the following week
    // console.log(firstDayOfFollowingWeek);
    // console.log(firstDayOfFollowingWeek.getDay());

    return firstDayOfFollowingWeek
}

export function getNextWeekTuesday(currentDate = new Date()) : Date
{
    // Get the current date
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    // Get the current day of the week (0-6)
    var currentDayOfWeek = currentDate.getDay();

    // Calculate the number of remaining days until the end of the week
    var remainingDays = (currentDayOfWeek > 0) ? (6 - currentDayOfWeek) : 0;

    // Calculate the first day of the following week
    var firstDayOfFollowingWeek = new Date(currentDate);
    firstDayOfFollowingWeek.setDate(currentDate.getDate() + remainingDays + 2);

    // Si el primer dia de la semana que viene es LUNES, el usuario se debe inscribir el MARTES,
    // para que les de tiempo a gestionar la inscripción.
    const firstWeekDayNumberOfFollowingWeek = firstDayOfFollowingWeek.getDay()
    if(firstWeekDayNumberOfFollowingWeek === 1){
        firstDayOfFollowingWeek.setDate(firstDayOfFollowingWeek.getDate() + 1);
    }

    // Output the first day of the following week
    // console.log(firstDayOfFollowingWeek);
    // console.log(firstDayOfFollowingWeek.getDay());

    return firstDayOfFollowingWeek
}
