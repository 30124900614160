import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useQuery} from "react-query";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {SelectOptionType} from "Model/Select/SelectOption";
import {getDiscounts} from "Api/Query/DiscountQuery";

interface Props {
    values: any,
    onChange: (newValues: any) => void,
    classes?: string
}

export const DiscountMultiSelect: React.FC<Props> = (props) => {

    const [values, setValues] = useState<SelectOptionType[]>([])
    const [options, setOptions] = useState<any>([])

    const {isLoading, data} = useQuery(['discountsList'], () => getDiscounts({
        enabled: true
    }))

    useEffect(() => {
        if (!data) return

        // @ts-ignore
        const response: ApiListResponse = data?.data
        const collection = SelectOptionCollection.fromArray(response, 'name', 'id')
        setOptions(collection.toPrimitiveOptions())
    }, [data])

    useEffect(() => {
        if (!props.values || !options.length) {
            return
        }
        setValues(props.values ?? [])
    }, [props.values, options])

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,  // Set your desired z-index value here
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,  // Set the z-index for the portal menu if you use menuPortal
        })
    };

    return (
        <Select
            isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={false}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
            value={values}
            isMulti={true}
            styles={customStyles}
        />
    )

}
