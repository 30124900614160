import React from "react";
import {authCheck, getAuth, removeAuthToken} from "Services/Security/AuthService";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {Notificator} from "Services/Notificator/Notificator";
import jwt_decode from "jwt-decode";
import {UserService} from "Services/User/UserService";
import {ImpersonationService} from "../../../Services/User/ImpersonationService";

export const LoginSquare: React.FC = () => {

    const userService = new UserService()
    const isLogged: boolean = userService.isAuthenticated()

    const navigate = useNavigate()

    const logout = (): void => {
        removeAuthToken()

        const impersonationService = new ImpersonationService()

        if(impersonationService.getImpersonationUser()){
            impersonationService.exitImpersonation()
            window.location.href = ROUTE_PATHS.LOGIN
            return
        }

        Notificator.success('Te has desconectado correctamente.')
        navigate(ROUTE_PATHS.LOGIN)
    }

    const username = () : string => {
        return userService.getUsername()
    }

    return (
        <div className={"ms-auto"}>
            <div className={"login-square"}>
                {!isLogged &&
                    <>
                        <span className={"signin"}>
                            <Link to={ROUTE_PATHS.LOGIN}>
                                Inicia sesión
                            </Link>
                        </span>
                        <span className={"createAccount"}>
                            ¿No tienes cuenta?
                            &nbsp;<Link to={ROUTE_PATHS.SIGNUP}><strong>Regístrate</strong></Link>
                        </span>
                    </>
                }
                {isLogged &&
                    <>
                        {username() && <span>Hola {username()},</span>}
                        {!username() && <span>Bienvenido,</span>}
                        <span
                            className={"logout pointer"}
                            onClick={logout}
                        >
                            Cerrar sesión
                        </span>
                    </>
                }
            </div>
        </div>
    )

}
