import React from "react";
import {useIsDesktop, useIsMobile} from "Hooks/Window/ResizeHook";
import {useAppDispatch, useAppSelector} from "hooks";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {isBurguerMenuOpen} from "Store/burguerMenuSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ToggleMenuMobileIcon: React.FC = () => {

    const isDesktop = useIsDesktop()
    const dispatch = useAppDispatch()

    const isMobileMenuOpen = useAppSelector((state) => state.isBurguerMenuOpen.isOpen)
    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!isMobileMenuOpen)
    }

    const setMobileMenuIsOpen = (isOpen: boolean): void => {
        dispatch(isBurguerMenuOpen({isOpen: isOpen}))
    }

    return (
        <>
            {
                !isDesktop &&
                <div id={"mobileMenuIcon"}>
                    <FontAwesomeIcon
                        icon={faBars}
                        className={"mobileBars"}
                        onClick={toggleMobileMenu}
                    />
                </div>
            }
        </>
    )

}
