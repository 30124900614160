import React, {useState} from "react";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {LoadingSpinner} from "Components/Loading/LoadingSpinner";
import {useMutation} from "react-query";
import {requestPasswordRecovery} from "Api/Mutation/SecurityMutation";
import {Notificator} from "Services/Notificator/Notificator";

export const PasswordRecovery: React.FC = () => {

    const [email, setEmail] = useState<string>('')
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const requestMutation = useMutation({
        mutationKey: ['requestPasswordRecoveryMutation'],
        mutationFn: (email: string) => requestPasswordRecovery(email),
        onSuccess: (response: any) => {
            console.log(response)
            setLoading(false)
            setSuccess(true)
        },
        onError: (error: any) => {
            setLoading(false)

            if (error.response.status === 404) {
                Notificator.error('El email introducido no existe en nuestro sistema.')
            } else {
                Notificator.error('Se ha producido un error al recuperar la contraseña')
            }
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        handleSubmit()
    }

    const validateEmail = (email: string): boolean => {
        // Regular expression to match a valid email address
        const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/;

        // Return true if the email address matches the regular expression, false otherwise
        return regex.test(email);
    }

    const handleSubmit = () => {
        if (!email) {
            Notificator.error('Introduce un email', 'Error')
            return
        }

        if (!validateEmail(email)) {
            Notificator.error('Introduce un email válido', 'Error')
            return
        }

        setSubmitted(true)
        setLoading(true)
        requestMutation.mutate(email)
    }

    return (
        <DefaultContainer>
            <Col id={"signup"} md={6} className={"mx-md-auto"}>
                <h1>Recuperar contraseña</h1>

                <Row>
                    {
                        !success &&
                        <Form.Group
                            controlId="email"
                            className="my-3"
                        >
                            <Form.Label>
                                Dirección de Correo Electrónico <FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onKeyDown={onEnterPressed}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                isInvalid={submitted && !email.length}
                            />
                        </Form.Group>
                    }

                    {
                        success &&
                        <Col>
                            <Alert
                                variant={"success"}
                                className={"mt-4"}
                            >
                                <p>Hemos enviado un correo a <i>{email}</i> con un enlace para modificar tu contraseña.
                                </p>
                                <p>El enlace tiene un periodo de validez de 2 horas.</p>
                            </Alert>
                        </Col>
                    }

                    {
                        !success &&
                        <Row>
                            <Col className={"col-12 d-flex justify-content-end"}>
                                <Button
                                    id={"sendSignupFormBtn"}
                                    variant={""}
                                    className={"purple-btn"}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading && <LoadingSpinner/>}
                                    <span>{loading && " "}Enviar</span>
                                </Button>
                            </Col>
                        </Row>
                    }

                </Row>

            </Col>
        </DefaultContainer>
    )

}
