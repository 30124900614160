import React, {useEffect, useState} from "react"
import {Col} from "react-bootstrap"
import {RequireAuth} from "Components/Security/RequireAuth"
import {DefaultContainer} from "Views/Shared/DefaultContainer"
import {UserService} from "Services/User/UserService";
import {useQuery} from "react-query";
import {getUserDetail, UserDetailResponseResult} from "Api/Query/UserQuery";
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch, useAppSelector} from "hooks";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Notificator} from "Services/Notificator/Notificator";
import {removeAuthToken} from "Services/Security/AuthService";
import {AdminDashboard} from "Views/Dashboard/AdminDashboard";
import {UserDashboard} from "Views/Dashboard/UserDashboard";
import {SocialServicesDashboard} from "Views/Dashboard/SocialServicesDashboard";
import {SpecialNeedsDashboard} from "Views/Dashboard/SpecialNeedsDashboard";

export const Dashboard: React.FC = () => {

    const [username, setUsername] = useState<string>('')
    const userService = new UserService()
    const userId = userService.getId()

    const [totalKids, setTotalKids] = useState<number>(0)
    const [hasTutor, setHasTutor] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const appLoadingState = useAppSelector((state) => state.loading)
    const setAppLoading = (loading: boolean, text: string = ''): void => {
        dispatch(
            appLoading({
                value: loading,
                text: text
            })
        )
    }

    const userDetailQuery = useQuery({
        queryKey: ['userDetail', userId],
        queryFn: () => getUserDetail(userId),
        onSuccess: (data: any) => {
            const response: UserDetailResponseResult = data.data._result

            const hasTutor = typeof response.tutor.children !== 'undefined'
            setHasTutor(hasTutor)

            if (hasTutor) {
                setTotalKids(response.tutor.children.length)
            }
        },
        onError: (error: any) => {
            // user not found
            if (error.response.status === 404) {
                removeAuthToken()
                setAppLoading(false)
            }

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    useEffect(() => {
        // TODO: if "userService.getUsername()" is empty, set from UserDetail request
        setUsername(userService.getUsername())
    }, [])

    useEffect(() => {
        setAppLoading(userDetailQuery.isLoading)
    }, [userDetailQuery.isLoading])

    return (
        <RequireAuth>
            <DefaultContainer>

                {!appLoadingState.value &&
                    <div id={"dashboard"}>
                        <Col>
                            {
                                !userService.isSocialService() &&
                                !userService.isSpecialNeedsManager() &&
                                <h1 className={"pb-4"}>
                                    Hola <i>{username}</i>, bienvenido a Sanse Concilia
                                </h1>
                            }

                            {userService.isRegularUser() &&
                                <h3>
                                    <span className={"purple"}>Niños dados de alta:</span> {totalKids}
                                </h3>
                            }

                        </Col>

                        {userService.isAdmin() && <AdminDashboard/>}

                        {
                            userService.isRegularUser() &&
                            <UserDashboard
                                hasTutor={hasTutor}
                                totalKids={totalKids}
                            />
                        }

                    </div>
                }

                {userService.isSpecialNeedsManager() && <SpecialNeedsDashboard />}
                {userService.isSocialService() && <SocialServicesDashboard/>}

            </DefaultContainer>
        </RequireAuth>
    )
}
