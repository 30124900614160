import React from "react"

import logo from 'Assets/images/logo.png'
import {Col, Row} from "react-bootstrap";
import {LoginSquare} from "./LoginSquare";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useIsDesktop} from "Hooks/Window/ResizeHook";

export const Header: React.FC = () => {

    const isDesktop = useIsDesktop()

    return (
        <>
            <Row className={"pt-4 header"}>
                <Col>
                    <Link to={ROUTE_PATHS.LOGIN}>
                        <img src={logo} title={"Sanse Concilia"} alt={"Logo"}/>
                    </Link>
                </Col>

                {isDesktop &&
                    <Col className={"d-flex align-items-center pr-auto pr-md-0"}>
                        <LoginSquare/>
                    </Col>
                }

            </Row>
        </>
    )
}
