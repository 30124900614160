import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
interface LoadingState {
    value: boolean,
    text?: string
}

// Define the initial state using that type
const initialState: LoadingState = {
    value: false,
    text: 'Cargando'
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        appLoading: (state, action: PayloadAction<LoadingState>) => {
            state.value = action.payload.value
            state.text = action.payload.text
        }
    },
})

export const { appLoading } = loadingSlice.actions

export default loadingSlice.reducer