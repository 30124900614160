import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {DefaultContainer} from "Views/Shared/DefaultContainer";

export const NotFound: React.FC = () => {
    let location = useLocation()

    const navigate = useNavigate()

    return (
        <DefaultContainer>
            <div className={"text-center"}>
                <h3 className={"mb-4"}>Error 404.</h3>
                <h3 className={"mb-4"}>No se ha encontrado la dirección:</h3>
                <h3><code>{location.pathname}</code></h3>
                <br/>
                <br/>
                <Button
                    variant={""}
                    className={"purple-btn"}
                    onClick={() => navigate(ROUTE_PATHS.DASHBOARD)}
                >
                    VOLVER AL INICIO
                </Button>
            </div>
        </DefaultContainer>
    );
}
