import React, {useEffect, useState} from "react";
import Select from "react-select";

export const ACTIVITY_TYPE_WEEKLY_ID = 1;
export const ACTIVITY_TYPE_YEARLY_ID = 2;
export const ACTIVITY_TYPE_LABEL_BY_IDS = {
    [ACTIVITY_TYPE_WEEKLY_ID] : 'Actividad semanal',
    [ACTIVITY_TYPE_YEARLY_ID] : 'Actividad anual'
}

interface Props {
    value: any,
    onChange: (newValue: any) => void,
    classes?: string,
    disabled?: boolean
}

export const ActivityTypeSelect : React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(null)
    const options = [
        {
            label: "Actividad Semanal",
            value: ACTIVITY_TYPE_WEEKLY_ID
        },
        {
            label: "Actividad Anual",
            value: ACTIVITY_TYPE_YEARLY_ID
        }
    ]

    useEffect(() => {
        if(!props.value) {
            return
        }

        const values = options.filter(option => option.value === props.value)
        setValue(values[0] ?? null)
    }, [props.value])

    const onChange = (option: any) => {
        setValue(option)
        props.onChange(option)
    }

    return (
        <Select
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={onChange}
            className={props.classes ?? ''}
            value={value}
            isDisabled={props.disabled ?? false}
        />
    )
}
