import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Notificator} from "Services/Notificator/Notificator";
import {CenterServiceResponseItem} from "../../Api/Query/CenterQuery";
import Toggle from "react-toggle";
import {CenterServiceLabel} from "Components/Center/CenterServiceLabel";

export interface AddYearSubmitDto {
    date: string,
    name: string,
    description: string,
    price: number,
    start: string,
    end: string,
    billable: boolean,
    serviceIds: string[]
    services: CenterServiceResponseItem[]
}

interface Props {
    show: boolean,
    onClose: () => void,
    onSubmit: (data: AddYearSubmitDto) => void,
    services?: CenterServiceResponseItem[],
}

export const AddYearDayModalForm: React.FC<Props> = (props) => {

    const [show, setShow] = useState(false)
    const [date, setDate] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')
    const [billable, setBillable] = useState<boolean>(false)
    const [serviceIds, setServiceIds] = useState<string[]>([])
    const [services, setServices] = useState<CenterServiceResponseItem[]>([])

    const findServiceObjectInArrayList = (serviceId: string) => {
        const services: any = props.services?.filter((item: any) => item.id === serviceId)
        return services[0] ?? null;
    }

    const isServiceChecked = (serviceId: string) => {
        if (!serviceIds) {
            return false
        }

        const exists = serviceIds.filter(id => id === serviceId)

        if (exists.length > 0) {
            return true
        }

        return false
    }

    const addSelectedServices = (serviceId: string): void => {
        setServiceIds([...serviceIds, serviceId])
        setServices([...services, findServiceObjectInArrayList(serviceId)])
    }

    const removeSelectedService = (serviceId: string): void => {
        const services: any = serviceIds.filter((itemId: string) => itemId !== serviceId)
        setServiceIds(services)

        const servicesObjs : any = services.filter((s: CenterServiceResponseItem) => s.id !== serviceId)
        setServices(servicesObjs)
    }

    const handleSubmit = () => {
        if(!date){
            Notificator.error('La fecha es obligatoria', 'Error')
            return
        }

        setShow(false)
        props.onSubmit({
            date: date,
            name: name,
            description: description,
            price: price,
            start: start,
            end: end,
            billable: billable,
            serviceIds: serviceIds,
            services: services
        })
        resetValues()
    }

    const resetValues = () => {
        setDate('')
        setName('')
        setDescription('')
        setPrice(0)
        setStart('')
        setEnd('')
        setBillable(false)
        setServiceIds([])
        setServices([])
    }

    const handleClose = () => {
        setShow(false)
        props.onClose()
        resetValues()
    }

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    return (
        <Modal show={show} onHide={handleClose} size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Añadir día del año</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Fecha&nbsp;<FormLabelRequiredAsterisk />
                            </label>
                            <input
                                type={"date"}
                                className={"form-control"}
                                onChange={(e:any) => setDate(e.target.value)}
                                value={date}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Nombre
                            </label>
                            <input
                                type={"text"}
                                className={"form-control"}
                                onChange={(e:any) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Precio
                            </label>
                            <input
                                type={"number"}
                                className={"form-control"}
                                onChange={(e:any) => setPrice(e.target.value)}
                                value={price}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4 d-flex align-items-end"}>
                        <div className={"form-group"}>
                            <label>
                                <input
                                    type={"checkbox"}
                                    onChange={(e:any) => setBillable(e.target.checked)}
                                    checked={billable}
                                />
                                &nbsp;&nbsp;Facturable
                            </label>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Hora inicio</label>
                            <input
                                type={"time"}
                                className={"form-control"}
                                onChange={(e:any) => setStart(e.target.value)}
                                value={start}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Hora fin</label>
                            <input
                                type={"time"}
                                className={"form-control"}
                                onChange={(e:any) => setEnd(e.target.value)}
                                value={end}
                            />
                        </div>
                    </Col>
                    <Col xs={12} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Descripción</label>
                            <textarea
                                className={"form-control"}
                                rows={4}
                                onChange={(e:any) => setDescription(e.target.value)}
                                value={description}
                            ></textarea>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12}>
                        <Form.Group controlId={"services"}>
                            <h4>Servicios</h4>
                            <p>Selecciona un centro para mostrar los servicios disponibles.</p>

                            <Row>
                            {
                                props.services?.map((service: any, key: number) => {
                                    return <Col key={key} sm={6} md={4} lg={4} xs={12} className={"my-4"}>
                                        <CenterServiceLabel centerService={service} hoverPointer={true} />
                                        <Toggle
                                            id={service.id}
                                            defaultChecked={isServiceChecked(service)}
                                            onChange={(e) => e.target.checked === true
                                                ? addSelectedServices(service.id)
                                                : removeSelectedService(service.id)
                                            }
                                        />
                                    </Col>
                                })
                            }
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    variant={"primary"}
                    onClick={handleSubmit}
                >
                    Añadir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
