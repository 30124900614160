import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ActivitySelect} from "../../Components/Activities/ActivitySelect";

interface Props{
    show: boolean,
    onClose: () => void,
    onSubmit: (data: string) => void
}

export const SelectActivityModal: React.FC<Props> = (props) => {

    const [show, setShow] = useState(false)
    const [activity, setActivity] = useState<any>(null)

    const handleSubmit = () => {
        props.onSubmit( activity)
    }

    const handleClose = () => {
        setShow(false)
        props.onClose()
    }

    useEffect(() => {
        setShow(props.show)
    }, [props.show])


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Selecciona la actividad</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col xs={12} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <ActivitySelect
                                onChange={(option: any) => setActivity(option?.value ?? '')}
                            />
                        </div>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    variant={"primary"}
                    onClick={handleSubmit}
                >
                    Añadir
                </Button>
            </Modal.Footer>
        </Modal>
    )

}