import React from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";

interface Props{
    children?: React.ReactNode,
    route: string,
    label: string
}

export const NavbarDropdownItem : React.FC<Props> = (props) => {

    return (
        <div className={"navbar-item"}>
            <DropdownButton
                title={props.label}
            >
                {props.children}
            </DropdownButton>
        </div>
    )
}
