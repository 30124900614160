import {useQuery} from "react-query";
import Select, {MultiValue} from "react-select";
import React, {useEffect, useState} from "react";
import {getWeekdays} from "Api/Query/CourseQuery";
import {SelectOptionType} from "Model/Select/SelectOption";

interface Props {
    onChange: (newValue: MultiValue<SelectOptionType>|null) => void,
    value?: any,
    classes?: string
}

export const WeekdaysSelect : React.FC<Props> = (props) => {

    const [values, setValues] = useState<any>([])
    const [options, setOptions] = useState<any>([])

    const weekdaysListQuery = useQuery({
        enabled: false,
        queryKey: ['weekdaysListQuery'],
        queryFn: () => getWeekdays(),
        onSuccess: (response: any) => {
            const weekdays : any = []
            response.data._result.items.map(item => {
                weekdays.push({
                    label: item.name,
                    value: item.id
                })
            })
            setOptions(weekdays)
        }
    })

    const onChange = (options: any) => {
        props.onChange(options)
    }

    useEffect(() => {
        weekdaysListQuery.refetch()
    }, [])

    useEffect(() => {
        if (!props.value.length || !options.length) {
            return
        }

        const collection : any[] = []
        props.value.map(item => {
            const label = item.name ?? item.label
            const value = item.id ?? item.value

            collection.push({
                label: label,
                value: value
            })
        })

        setValues(collection)
    }, [props.value, options])

    return (
        <Select
            isLoading={weekdaysListQuery.isLoading}
            isMulti
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={onChange}
            className={props.classes ?? ''}
            value={values}
        />
    )
}
