import {http} from "Services/Http/HttpClient";
import FilterBuilder from "Services/Filters/FilterBuilder";

export const exportAdminInscriptions = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const newFilters = {...filters, csv: "1"}
    return searchAdminInscriptions(newFilters, sort)
}

export const exportValidatedBySsQuery = async(
) : Promise<any[]> => {
    return await http.get('inscription-order-validated-by-social-services')
}

export const exportAdminInscriptionsReport = async(
    filters: any = {}
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    return await http.get('inscriptions/report/admin'+ paramsString)
}

export const exportAdminActivityReport = async(
    filters: any = {}
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    return await http.get('inscriptions/activity-report/admin' + paramsString)
}

export const exportAdminPaymentReport = async(
    filters: any = {}
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    return await http.get('inscriptions/payment-report/admin' + paramsString)
}

export const searchAdminInscriptions = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    if(!paramsString){
        paramsString += '?'
    }else{
        paramsString += '&'
    }

    paramsString += 'sort=' + sort

    return await http.get('inscriptions/admin'+ paramsString)
}

export const getAdminInscription = async(id: string) : Promise<any[]> => {
    return await http.get(`inscriptions/${id}/admin`)
}

export const getInscriptionStatuses = async() : Promise<any[]> => {
    return await http.get(`inscriptions/statuses`)
}

export const getInscriptionObservations = async(id: string) : Promise<any[]> => {
    return await http.get(`inscriptions/${id}/observations`)
}
