export interface SelectOptionType {
    label: string,
    value: string
}

export class SelectOption
{
    private _value: string;

    private _label: string;

    /**
     *
     * @param label
     * @param value
     */
    constructor(label: string, value: string) {
        this._label = label
        this._value = value
    }

    static fromPrimitives = (label : string, value : string) : SelectOption => {
        return new this(label, value)
    }

    toPrimitives = () : SelectOptionType => {
        return {
            label: this.label,
            value: this.value
        }
    }

    get value(): string {
        return this._value;
    }

    get label(): string {
        return this._label;
    }
}