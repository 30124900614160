import React, {useState} from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {LoadingSpinner} from "Components/Loading/LoadingSpinner";
import {Link} from "react-router-dom";
import {Email} from "Model/Shared/ValueObjects/Email";
import {useMutation} from "react-query";
import {createUser, PostUserRequest} from "Api/Mutation/UserMutation"
import {Notificator} from "Services/Notificator/Notificator";
import {DefaultContainer} from "../Shared/DefaultContainer";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {PrivacyPolicyLink} from "../../Components/Link/PrivacyPolicyLink";

export const Signup: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<{ [key: string]: string }>({})
    const [validated, setValidated] = useState<boolean>(false)
    const [registered, setRegistered] = useState<boolean>(false)

    const [password, setPassword] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [username, setUsername] = useState<string>('')

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }
        handleSubmit()
    }

    const validate = (): boolean => {
        const error = 'El campo es obligatorio.'
        let err: { [key: string]: string } = {}

        if (!password) {
            err['password'] = error
        }

        if(password && password.length < 8){
            err['password'] = 'La contraseña debe tener al menos 8 caracteres.'
        }

        if (!username) {
            err['username'] = error
        }
        if (!email) {
            err['email'] = error
        }

        // validate email format
        if (email) {
            try {
                new Email(email)
            } catch (error: any) {
                err['email'] = error.getMessage()
            }
        }

        setErrors(err)
        return !!Object.keys(err).length
    }

    const handleSubmit = () => {
        setLoading(true)

        if (!validated) {
            setValidated(true)
        }

        const hasErrors: boolean = validate()

        if (!hasErrors) {
            mutation.mutate({
                username: username,
                email: email,
                password: password
            })
        } else {
            setLoading(false)
        }
    };

    const mutation = useMutation({
        mutationFn: (body: PostUserRequest) => createUser(body.username, body.email, body.password),
        onSettled: () => setLoading(false),
        onSuccess: () => setRegistered(true),
        onError: (error: any) => {
            const errorMessage = error.response.data._error.message
            let notificationMessage = 'Se ha producido un error inesperado. Inténtelo de nuevo.'

            if(errorMessage === 'El email esta en uso'){
                notificationMessage = 'Ya existe un usuario con este email.'
            }
            if(typeof errorMessage === 'object'){
                notificationMessage = errorMessage.join("\n")
            }

            Notificator.error(notificationMessage, 'Error')
        }
    })

    return (
        <DefaultContainer>
            <Col id={"signup"} md={6} className={"mx-md-auto"}>
            <h1>Registro en Sanse Concilia</h1>

            {registered &&
                <Alert className={"mt-4"} variant={"success"}>¡Te has registrado correctamente!</Alert>
            }
            <small className={"purple"}>Recibirás en tu correo electrónico un enlace en el que deberás entrar para que podamos verificar tu dirección.</small>

            {!registered &&
                <Form className={"mt-4"}>
                    <Form.Group
                        controlId="email"
                        className="my-3"
                    >
                        <Form.Label>
                             Nombre de usuario <FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onKeyUp={() => validated && validate()}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                            isInvalid={!!errors.username}
                            required
                        />
                        {errors.username && <p className={"text-danger pt-2"}>{errors.username}</p>}
                    </Form.Group>
                    <Form.Group
                        controlId="email"
                        className="my-3"
                    >
                        <Form.Label>
                            Dirección de correo electrónico <FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            onKeyUp={() => validated && validate()}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            className={errors.email ? "is-invalid" : ""}
                            required
                        />
                        {errors.email && <p className={"text-danger pt-2"}>{errors.email}</p>}
                    </Form.Group>
                    <Form.Group
                        controlId="email"
                        className="my-3"
                    >
                        <Form.Label>
                            Contraseña <FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <Form.Control
                            onKeyUp={() => validated && validate()}
                            onKeyDown={onEnterPressed}
                            type="password"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            className={errors.password ? "is-invalid" : ""}
                            required
                        />
                        {errors.password && <p className={"text-danger pt-2"}>{errors.password}</p>}
                    </Form.Group>

                    <Row>
                        <Col className={"col-12 d-flex justify-content-end"}>
                            <Button
                                id={"sendSignupFormBtn"}
                                variant={""}
                                className={"purple-btn"}
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading && <LoadingSpinner/>}
                                <span>{loading && " "}Registrarse</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col>
                            <small className={"purple"}>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web, gestionar el acceso a tu cuenta y otros propósitos descritos en nuestra <PrivacyPolicyLink classes={"purple text-underline"} />.
                            </small>
                        </Col>
                    </Row>

                </Form>
            }

        </Col>
        </DefaultContainer>
    )
}
