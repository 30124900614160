import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {useMutation} from "react-query";
import {updateInscriptionActivitySpecialNeed} from "Api/Mutation/SpecialNeedMutation";
import {Notificator} from "Services/Notificator/Notificator";
import {appLoading} from "Store/loadingSlice";

interface Props {
    show: boolean,
    onClose: () => void,
    item: any,
    onUpdated: () => void
}

export const ObservationsModal: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const [observation, setObservation] = useState<string>('')

    const handleClose = () => {
        setShow(false)
        props.onClose()
    }

    const updateMutation = useMutation({
        mutationFn: (body: any) => updateInscriptionActivitySpecialNeed(body.id, body),
        mutationKey: ['updateInscriptionActivitySpecialNeedObservations'],
        onSuccess: () => {
            props.onUpdated()
            Notificator.success('Se ha actualizado correctamente');
        },
        onError: () => {
            Notificator.error('Se ha producido un error al actualizar');
        }
    })

    const save = () => {
        updateMutation.mutate({
            id: props.item.id,
            observations: observation
        })

        handleClose()
    }

    useEffect(() => {
        dispatch(appLoading({value: true, text: 'Cargando'}))
    }, [updateMutation.isLoading]);

    useEffect(() => {
        setObservation(props.item?.observations ?? '')
    }, [props.item?.observations]);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>OBSERVACIONES</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col xs={12} sm={10} md={10} className={"mx-auto py-2 pb-4"}>
                        <p>Añadir/Editar Observación</p>
                        <textarea
                            className="mt-2 form-control"
                            rows={5}
                            value={observation}
                            onChange={(e:any) => setObservation(e.target.value)}
                        >
                        </textarea>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >Cancelar</Button>
                <Button
                    className={"purple-btn"}
                    onClick={save}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
