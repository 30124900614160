import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useAppDispatch} from "hooks";
import {Link, useNavigate} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import {useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {exportAdminInscriptions, exportValidatedBySsQuery, searchAdminInscriptions} from "Api/Query/InscriptionQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {InscriptionStatusesSelect} from "Components/InscriptionOrder/InscriptionStatusSelect";
import {SelectOption} from "Model/Select/SelectOption";
import Pagination from "rc-pagination";
import {InscriptionStatus} from "Services/Inscription/InscriptionStatus";
import {RefreshSpinIcon} from "Components/Icons/RefreshSpinIcon";
import {SortArrows} from "../Shared/Crud/SortArrows";

interface Props {
    showStatusFilter?: boolean,
    isSocialServiceUser?: boolean
}

export const AdminInscriptionList: React.FC<Props> = (props) => {

    const showStatusFilter = props.showStatusFilter ?? true
    const isSocialServiceUser = props.isSocialServiceUser ?? false

    const [selectedInscriptions, setSelectedInscriptions] = useState<{ [key: string]: boolean }>({})
    const [inscriptions, setInscriptions] = useState<any[]>([])

    const [pageSize, setPageSize] = useState<number>(12)
    const [page, setPage] = useState<number>(1)
    const [exporting, setExporting] = useState<boolean>(false)
    const [exportingValidatedBySs, setExportingValidatedBySs] = useState<boolean>(false)

    const defaultSearchFilters = !isSocialServiceUser ? {
        size: pageSize,
        page: page
    } : {
        size: pageSize,
        page: page,
        status: InscriptionStatus.PENDING_SOCIAL_SERVICES_APPROVEMENT
    }

    const [sortField, setSortField] = useState<string>('id')
    const [sortDirection, setSortDirection] = useState<string>('-')

    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)
    const [searchFilterValue, setSearchFilterValue] = useState<string>('')
    const [statusFilterValue, setStatusFilterValue] = useState<SelectOption | null>(null)
    const [initDateFilterValue, setInitDateFilterValue] = useState<string>('')
    const [endDateFilterValue, setEndDateFilterValue] = useState<string>('')

    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [currentPosition, setCurrentPosition] = useState<number>(pageSize)

    const getEditUrl = (item: any) => {
        if (props.isSocialServiceUser) {
            return ROUTE_PATHS.ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL.replace(':id', item.id)

        }

        return ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(':id', item.id)
    }

    const dispatch = useAppDispatch()

    const initCheckboxes = (sessions: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        sessions.map(session => checkboxes[session.id] = false)
        return checkboxes
    }

    const query = useQuery({
        enabled: false,
        queryKey: ['adminSearchInscriptions'],
        queryFn: () => searchAdminInscriptions(searchFilters, (sortDirection + sortField)),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const resp = responseData.items
            setInscriptions(resp)
            setTotalRecords(responseData.total)
            setSelectedInscriptions(initCheckboxes(resp))

            let currentPosition = pageSize * responseData.page
            currentPosition = currentPosition > responseData.total ? responseData.total : currentPosition
            setCurrentPosition(currentPosition)

            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminInscriptions'],
        queryFn: () => exportAdminInscriptions(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'inscripciones.csv'
            downloadCsv(responseData, filename)
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const exportValidatedBySs = useQuery({
        enabled: false,
        queryKey: ['exportValidatedBySs'],
        queryFn: () => exportValidatedBySsQuery(),
        onSuccess: (response: any) => {
            const responseData = response.data
            console.log(responseData)
            const filename = response.headers['content-language'] ?? 'inscripciones-validadas-por-servicios-sociales.csv'
            downloadCsv(responseData, filename)
            setExportingValidatedBySs(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExportingValidatedBySs(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }
        search()
    }

    const search = () => {
        let searchFilters = {}

        if (searchFilterValue) {
            searchFilters = {...searchFilters, q: `*${searchFilterValue}*`}
        }
        if (statusFilterValue) {
            searchFilters = {...searchFilters, status: parseInt(statusFilterValue.value)}
        }
        if (initDateFilterValue) {
            searchFilters = {...searchFilters, startDate: initDateFilterValue}
        }
        if (endDateFilterValue) {
            searchFilters = {...searchFilters, endDate: endDateFilterValue}
        }

        searchFilters = {...searchFilters, size: 12}
        searchFilters = {...searchFilters, page: 1}

        setPage(1)
        setSearchFilters(searchFilters)
        dispatch(appLoading({value: true, text: 'Cargando'}))
    }

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    const excelExportValidatedBySocialServices = () => {
        setExportingValidatedBySs(true)
        exportValidatedBySs.refetch()
    }

    const sortBy = (field: string, direction: string = '') => {
        const newSort = direction + field
        const currentSort = sortDirection + sortField

        if(newSort === currentSort && sortDirection === direction){
            direction = direction === '-' ? '' : '-'
        }

        setSortDirection(direction)
        setSortField(field)
    }

    useEffect(() => {
        query.refetch()
    }, [])

    useEffect(() => {
        query.refetch()
    }, [searchFilters, sortField, sortDirection])

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Listado de solicitudes</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>
                                {/*<Button*/}
                                {/*    variant={"secondary"}*/}
                                {/*    className={"mx-2"}*/}
                                {/*>*/}
                                {/*    Eliminar Seleccionadas*/}
                                {/*</Button>*/}

                                {
                                    isSocialServiceUser &&
                                    <Button
                                        className={"purple-btn mx-2"}
                                        onClick={excelExportValidatedBySocialServices}
                                        disabled={exportingValidatedBySs}
                                    >
                                        {exportingValidatedBySs && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                        <>Inscripciones validadas</>
                                    </Button>
                                }

                                <Button
                                    className={"purple-btn mx-4"}
                                    onClick={excelExport}
                                    disabled={exporting}
                                >
                                    {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                    <>Exportar</>
                                </Button>

                                {/*<Button*/}
                                {/*    className={"purple-btn"}*/}
                                {/*    disabled={true}*/}
                                {/*>*/}
                                {/*    Añadir solicitud*/}
                                {/*</Button>*/}
                            </div>
                        </Col>
                    </Row>

                    <div className={"row my-4"}>
                        {
                            showStatusFilter &&
                            <Col>
                                <Form.Group controlId={"statusFilter"}>
                                    <Form.Label>
                                        Estado de la solicitud
                                    </Form.Label>
                                    <InscriptionStatusesSelect
                                        onChange={(option: any) => setStatusFilterValue(option)}
                                        value={statusFilterValue}
                                    />

                                </Form.Group>
                            </Col>
                        }
                        <Col>
                            <Form.Group controlId={"initDateFilter"}>
                                <Form.Label>
                                    Fecha desde
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitDateFilterValue(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={"endDateFilter"}>
                                <Form.Label>
                                    Fecha hasta
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDateFilterValue(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => setSearchFilterValue(e.target.value)}
                                    onKeyDown={onEnterPressed}
                                />
                            </Form.Group>
                        </Col>
                        <Col className={"d-flex align-items-end"}>
                            <Button
                                className={"purple-btn"}
                                onClick={search}
                            >
                                Buscar
                            </Button>
                        </Col>
                    </div>
                    <br/>

                    <p>{currentPosition} de {totalRecords} solicitudes</p>
                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            <th key={1}>
                                <Link onClick={() => sortBy('id')} to={"#"}>SOLICITUD</Link>
                                <SortArrows field={'id'} sortField={sortField} sortDirection={sortDirection} />
                            </th>
                            <th key={2}>
                                <Link onClick={() => sortBy('createdAt')} to={"#"}>FECHA</Link>
                                <SortArrows field={'createdAt'} sortField={sortField} sortDirection={sortDirection} />
                            </th>
                            <th key={3}>
                                <Link onClick={() => sortBy('status')} to={"#"}>ESTADO</Link>
                                <SortArrows field={'status'} sortField={sortField} sortDirection={sortDirection} />
                            </th>
                            {
                                !isSocialServiceUser &&
                                <th key={4}>
                                    <Link onClick={() => sortBy('totalWithDiscount')} to={"#"}>TOTAL</Link>
                                    <SortArrows field={'totalWithDiscount'} sortField={sortField} sortDirection={sortDirection} />
                                </th>
                            }
                            <th key={5}>ACCIONES</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            inscriptions.length === 0 && <tr>
                                <td colSpan={5}>No hay inscripciones pendientes.</td>
                            </tr>
                        }

                        {inscriptions.map((item, key) => <tr key={key}>
                            <>
                                {/*<td>*/}
                                {/*    <Form.Check type="checkbox"/>*/}
                                {/*</td>*/}
                                <td>
                                    <Link to={getEditUrl(item)}>#{item.id} - {item.name}</Link>
                                </td>
                                <td>
                                    {item.createdAt}
                                </td>
                                <td>
                                    <Badge bg={item.status.color}>{item.status.name.toUpperCase()}</Badge>
                                </td>

                                {
                                    !isSocialServiceUser &&
                                    <td>
                                        {item.finalPrice} €
                                    </td>
                                }
                                <td>
                                    <Link
                                        className={"purple-btn btn-sm"}
                                        to={getEditUrl(item)}
                                    >
                                        Modificar
                                    </Link>
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <Pagination
                                    total={totalRecords}
                                    pageSize={pageSize}
                                    current={page}
                                    onChange={onPaginationPageChange}
                                />
                            }
                        </Col>
                    </Row>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
