import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {UserDataForm} from "Components/User/UserDataForm";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {AuthorizedPerson, AuthorizedPersonForm} from "Components/User/AuthorizedPersonForm";
import {useNavigate} from "react-router-dom";
import {SelectOptionType} from "Model/Select/SelectOption";
import {Notificator} from "Services/Notificator/Notificator";
import {FormErrorText} from "Components/Form/FormErrorText";
import {useMutation, useQuery} from "react-query";
import {TutorRequest, updateTutor} from "Api/Mutation/Tutor";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {NifTypeSelect} from "Components/Nif/NifTypeSelect";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {ErrorConverter} from "Services/Http/ErrorConverter";
import {getUserDetail, UserDetailResponseResult} from "Api/Query/UserQuery";
import {UserService} from "Services/User/UserService";
import {DropzoneFile} from "Components/FileUpload/FileUpload";
import {GivenDocumentation} from "Components/Tutor/GivenDocumentation";
import {uploadTutorFile} from "Api/Mutation/TutorChild";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {createDropzoneFile} from "Services/File/DropzoneFile";
import {refreshTokenAndSave} from "Services/Security/AuthService";
import {PrivacyPolicyLink} from "../../../Components/Link/PrivacyPolicyLink";
import {LegalWarningLink} from "../../../Components/Link/LegalWarningLink";
import {AcceptNormativeLink} from "../../../Components/Link/AcceptNormativeLink";
import {BankLegalCheck} from "../../../Components/Link/BankLegalCheck";
import {GdprCheck} from "../../../Components/Link/GdprCheck";
import {RequestKidsInfoCheck} from "../../../Components/Link/RequestKidsInfoCheck";
import {DataProtectionLink} from "../../../Components/Link/DataProtectionLink";

interface Props{
    userId?: string,
}

/**
 * Tutor data
 * @constructor
 */
export const UserDataUpdater: React.FC<Props> = (props) => {

    const navigate = useNavigate()
    const userService = new UserService()
    const userId = props.userId ?? userService.getId()

    const [tutorId, setTutorId] = useState<string>('')

    const [monoparental, setMonoparental] = useState<boolean>(false)
    const [tutorData1, setTutorData1] = useState<boolean>(false)
    const [tutorData2, setTutorData2] = useState<boolean>(false)

    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [nifType, setNifType] = useState<number>(0)
    const [nif, setNif] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone1, setPhone1] = useState<string>('')
    const [phone2, setPhone2] = useState<string>('')

    const [name2, setName2] = useState<string>('')
    const [surname2, setSurname2] = useState<string>('')
    const [nifType2, setNifType2] = useState<number>(0)
    const [nif2, setNif2] = useState<string>('')
    const [email2, setEmail2] = useState<string>('')
    const [phone21, setPhone21] = useState<string>('')
    const [phone22, setPhone22] = useState<string>('')

    const [residence, setResidence] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [province, setProvince] = useState<string>('')
    const [registeredInSSR, setRegisteredInSSR] = useState<boolean>(false)

    const [bankEntity, setBankEntity] = useState<string>('')
    const [iban, setIban] = useState<string>('')
    const [accountHolderName, setAccountHolderName] = useState<string>('')
    const [accountHolderNif, setAccountHolderNif] = useState<string>('')
    const [accountHolderDocumentType, setAccountHolderDocumentType] = useState<number>(0)

    const [authorizedPersons, setAuthorizedPersons] = useState<AuthorizedPerson[]>([])

    const [acceptedPrivacy, setAcceptedPrivacy] = useState<boolean>(true)
    const [acceptedTerms, setAcceptedTerms] = useState<boolean[]>([false, false, false, false, false, false, false])

    const formTerms = [
        <p><FormLabelRequiredAsterisk/> <AcceptNormativeLink /></p>,
        <p><FormLabelRequiredAsterisk/> Quedo enterado del <LegalWarningLink /> y acepto su normativa junto a la <DataProtectionLink label={"Protección de datos"}/>.</p>,
        <p><FormLabelRequiredAsterisk/> Al enviar, acepto la <PrivacyPolicyLink />.</p>,
        <p><FormLabelRequiredAsterisk/> <GdprCheck /></p>,
        <p><FormLabelRequiredAsterisk/> <RequestKidsInfoCheck /></p>,
        <p><FormLabelRequiredAsterisk/> <BankLegalCheck/></p>
    ]
    const requiredTermKeys = [0, 1, 2, 3, 4, 5]

    const [givenDocumentationFiles, setGivenDocumentationFiles] = useState<DropzoneFile[]>([])

    const [submitted, setSubmitted] = useState<boolean>(false)
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({})

    const checkAcceptedTerm = (key: number) => {
        setAcceptedTerms({
            ...acceptedTerms,
            [key]: !acceptedTerms[key]
        })
    }

    const setUserOneData = (
        name: string,
        surname: string,
        email: string,
        phone1: string,
        phone2: string,
        nifType: SelectOptionType|number,
        nif: string
    ): void => {
        setName(name)
        setSurname(surname)
        setEmail(email)
        setPhone1(phone1)
        setPhone2(phone2)
        setNif(nif)

        if (typeof nifType === 'number') {
            setNifType(nifType)
        }else{
            setNifType(parseInt(nifType.value))
        }
    }

    const setUserTwoData = (
        name: string,
        surname: string,
        email: string,
        phone1: string,
        phone2: string,
        nifType: SelectOptionType|number,
        nif: string
    ): void => {
        setName2(name)
        setSurname2(surname)
        setEmail2(email)
        setPhone21(phone1)
        setPhone22(phone2)
        setNif2(nif)

        if(nifType){
            if (typeof nifType === 'number') {
                setNifType2(nifType)
            }else{
                setNifType2(parseInt(nifType.value))
            }
        }else{
            setNifType2(0)
        }
    }

    const validate = (): boolean => {
        let requiredFields: { [key: string]: string | boolean | number } = {
            "tutorData1": tutorData1,
            "bankEntity": bankEntity,
            "iban": iban,
            "accountHolderName": accountHolderName,
            "accountHolderNif": accountHolderNif,
            "accountHolderDocumentType": accountHolderDocumentType,
            "residence": residence,
            "city": city,
            "postalCode": postalCode,
            "province": province
        }

        if (!monoparental) {
            requiredFields = {
                ...requiredFields,
                tutorData2: tutorData2
            }
        }

        const emptyError = 'El campo es obligatorio.'
        let errors: { [key: string]: string } = {}

        // input fields validation
        for (const [key, value] of Object.entries(requiredFields)) {
            if (!value) {
                errors[key] = emptyError
            }
        }

        // terms validation
        for (let i in requiredTermKeys) {
            const key = requiredTermKeys[i]
            if (!acceptedTerms[key]) {
                errors['terms'] = 'Debes aceptar los condiciones del formulario.'
                break
            }
        }

        setValidationErrors(errors)

        if (Object.keys(errors).length) {
            checkValidationErrorsNotification()
            return false
        }

        return true
    }

    const checkValidationErrorsNotification = (): void => {
        let notificationMessage = 'Hay campos obligatorios sin completar (marcados en rojo).'
        Notificator.error(notificationMessage, 'Error')
    }

    const applyServerValidationErrors = (serverErrors: object): void => {
        if (!Object.keys(serverErrors).length) {
            return
        }

        let errors: { [key: string]: string } = {}

        // input fields validation
        for (const [fieldName, error] of Object.entries(serverErrors)) {
            let key = fieldName

            switch (key) {
                case 'zipCode':
                    key = 'postalCode'
                    break
            }

            errors[key] = error
        }

        setValidationErrors(errors)
    }

    const handleSubmit = (): void => {
        if (!submitted) {
            setSubmitted(true)
        }

        if (!validate()) {
            return
        }

        let tutorPhoneNumbers = [phone1]

        if (phone2) {
            tutorPhoneNumbers = [...tutorPhoneNumbers, phone2]
        }

        let mutationBody: TutorRequest = {
            tutor: {
                name: name,
                surnames: surname,
                email: email,
                nif: nif,
                document: nif,
                documentType: nifType,
                phoneNumbers: tutorPhoneNumbers
            },
            address: residence,
            city: city,
            zipCode: parseInt(postalCode),
            province: province,
            registeredInSSR: registeredInSSR,
            bankEntity: bankEntity,
            iban: iban,
            accountHolder: {
                name: accountHolderName,
                nif: accountHolderNif,
                document: accountHolderNif,
                documentType: accountHolderDocumentType
            }
        }

        if (!monoparental) {
            let extraTutorPhoneNumbers = [phone21]

            if (phone22) {
                extraTutorPhoneNumbers = [...extraTutorPhoneNumbers, phone22]
            }

            mutationBody = {
                ...mutationBody,
                extraTutorInfo: {
                    name: name2,
                    surnames: surname2,
                    email: email2,
                    nif: nif2,
                    phoneNumbers: extraTutorPhoneNumbers,
                    document: nif2,
                    documentType: nifType2,
                }
            }
        }

        // add "authorizedPersons"
        if(authorizedPersons.length){
            mutationBody = {
                ...mutationBody,
                authorizedPersons: authorizedPersons
            }
        }

        tutorMutation.mutate(mutationBody)
        console.log(mutationBody)
    }

    const uploadFiles = async (tutorId: string) => {
        const files = Object.values(givenDocumentationFiles)
        for(let i in files) {
            const file: any = files[i]

            if(typeof file.deleteUrl !== 'undefined'){
                continue
            }

            await uploadMutation.mutateAsync({
                tutorId: tutorId,
                file: file
            })
        }
    }

    const tutorMutation = useMutation({
        mutationFn: (body: TutorRequest) => updateTutor(body, tutorId),
        onMutate: () => {
            setAppLoading(true)
        },
        onSuccess: async () => {
            if (!acceptedPrivacy && Object.keys(givenDocumentationFiles).length) {
                await uploadFiles(tutorId)
            }

            // refresh token
            if(!userService.getTutor()){
                await refreshTokenAndSave()
            }

            setAppLoading(false)
            Notificator.success('El tutor se ha actualizado correctamente.')

            // redirect to dashboard
            if(!props.userId){
                navigate(ROUTE_PATHS.DASHBOARD)
            }else{
                navigate(ROUTE_PATHS.ADMIN_TUTOR_LIST)
            }
        },
        onError: (error: any) => {
            setAppLoading(false)

            const errorMessage = error.response.data._error.message
            let notificationMessage = 'Se ha producido un error inesperado. Inténtelo de nuevo.'

            if (typeof errorMessage === 'object') {
                notificationMessage = errorMessage.join("\n")
                const serverErrors = ErrorConverter.convertToHashmap(errorMessage)
                applyServerValidationErrors(serverErrors)
            }

            if (typeof errorMessage === 'string') {
                notificationMessage = errorMessage
            }
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const dispatch = useAppDispatch()

    const setAppLoading = (loading: boolean, text: string = ''): void => {
        dispatch(
            appLoading({
                value: loading,
                text: text
            })
        )
    }

    const userDetailQuery = useQuery({
        queryKey: ['userDetail', userId],
        queryFn: () => getUserDetail(userId),
        onSuccess: (data: any) => {
            const response: UserDetailResponseResult = data.data._result

            if (!response.tutor || Object.keys(response.tutor).length === 0) {
                return
            }

            const tutor = response.tutor

            if(Object.keys(response.tutor).length){
                setTutorId(response.tutor.id)
            }

            setName(tutor.name)
            setSurname(tutor.surnames)
            setNif(tutor.document)
            setNifType(tutor.documentType)
            setEmail(response.email)

            setResidence(tutor.address)
            setProvince(tutor.province)
            setCity(tutor.city)
            setPostalCode(tutor.zipCode)
            setRegisteredInSSR(tutor.registeredInSSR)

            if (tutor.phoneNumbers && typeof tutor.phoneNumbers[0] !== 'undefined') {
                setPhone1(tutor.phoneNumbers[0])
            }
            if (tutor.phoneNumbers && typeof tutor.phoneNumbers[1] !== 'undefined') {
                setPhone2(tutor.phoneNumbers[1])
            }

            if (tutor.extraTutorInfo && !tutor.extraTutorInfo.name && !tutor.extraTutorInfo.document) {
                setMonoparental(true)
            }

            if(Object.keys(tutor.extraTutorInfo).length){
                setName2(tutor.extraTutorInfo.name)
                setSurname2(tutor.extraTutorInfo.surnames)
                setPhone21(tutor.extraTutorInfo.phoneNumbers[0])

                if(typeof tutor.extraTutorInfo.phoneNumbers[1] !== 'undefined'){
                    setPhone22(tutor.extraTutorInfo.phoneNumbers[1])
                }

                setEmail2(tutor.extraTutorInfo.email)
                setNif2(tutor.extraTutorInfo.document)
                setNifType2(tutor.extraTutorInfo.documentType)
            }

            setBankEntity(tutor.bankEntity)
            setAccountHolderName(tutor.accountHolderName)
            setAccountHolderNif(tutor.accountHolderDocument)
            setAccountHolderDocumentType(tutor.accountHolderDocumentType)
            setIban(tutor.iban)
            setAcceptedTerms([true, true, true, true, true, true])

            if(tutor.authorizedPersons.length){
                setAuthorizedPersons(tutor.authorizedPersons as AuthorizedPerson[])
            }

            // given documentation files
            if(tutor.media.length){
                setAcceptedPrivacy(false)

                const givenDocumentationFiles: any = []
                tutor.media.map((file: any) => givenDocumentationFiles.push(createDropzoneFile(file)))
                setGivenDocumentationFiles(givenDocumentationFiles)
            }

            console.log(tutor)
        }
    })

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadTutorFile(body.tutorId, body.file),
        onMutate: (body: any) => {
            setAppLoading(true, `Subiendo archivo: ${body.file.file.name} ...`)
        },
        onError: (error: any) => {
            setAppLoading(false)

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    useEffect(() => {
        setAppLoading(userDetailQuery.isLoading)
    }, [userDetailQuery.isLoading])

    return (
        <>
            <RequireAuth>
                <DefaultContainer>
                    <Col id={"userDataUpdater"}>
                        <h1 className={"pb-4"}>{props.userId ? 'Detalle de Tutor/a' : 'Detalle de Usuario Padre/Madre/Tutor'}</h1>

                        {
                            userDetailQuery.data &&
                            <>
                                <Form>
                                    <Form.Group className="mb-3" controlId="monoparental">
                                        <Form.Check
                                            type="checkbox"
                                            label="Familia Monoparental"
                                            checked={monoparental}
                                            onChange={() => setMonoparental(!monoparental)}
                                        />
                                    </Form.Group>
                                </Form>

                                <UserDataForm
                                    onChange={(name, surname, email, phone1, phone2, nifType, nif, validationErrors) => {
                                        setUserOneData(name, surname, email, phone1, phone2, nifType, nif)
                                        setTutorData1(!Object.keys(validationErrors).length)
                                    }}
                                    defaultValues={{
                                        name: name,
                                        surname: surname,
                                        document: nif,
                                        nifType: nifType,
                                        phone1: phone1,
                                        phone2: phone2,
                                        email: email
                                    }}
                                    submitted={submitted}
                                />

                                {
                                    !monoparental &&
                                    <UserDataForm
                                        onChange={(name, surname, email, phone1, phone2, nifType, nif, validationErrors) => {
                                            setUserTwoData(name, surname, email, phone1, phone2, nifType, nif)
                                            setTutorData2(Object.keys(validationErrors).length ? false : true)
                                        }}
                                        submitted={submitted}
                                        defaultValues={{
                                            name: name2,
                                            surname: surname2,
                                            document: nif2,
                                            nifType: nifType2,
                                            phone1: phone21,
                                            phone2: phone22,
                                            email: email2
                                        }}
                                    />
                                }

                                <Form>
                                    <Row>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="home">
                                            <Form.Label>
                                                Domicilio
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setResidence(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.residence)}
                                                value={residence}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.residence}/>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="city">
                                            <Form.Label>
                                                Municipio
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.city)}
                                                value={city}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.city}/>}
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="postalCode">
                                            <Form.Label>
                                                Código Postal
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.postalCode)}
                                                value={postalCode}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.postalCode}/>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="province">
                                            <Form.Label>
                                                Provincia
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProvince(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.province)}
                                                value={province}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.province}/>}
                                        </Form.Group>

                                        <Form.Group className="my-4 col-12 col-sm-6" controlId="registered">
                                            <Form.Check
                                                type="checkbox"
                                                label="Empadronado en San Sebastián de los Reyes"
                                                checked={registeredInSSR}
                                                onChange={() => setRegisteredInSSR(!registeredInSSR)}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Form>

                                <br/>
                                <h4 className={"purple my-4"}>Documentación a Aportar</h4>

                                <Form.Group className="mb-3" controlId="acceptedPrivacy">

                                    <GivenDocumentation
                                        formLabel={"Doy mi consentimiento a que sean consultados o recabados mis datos o documentos necesarios para el trámite de la presente solicitud que obren en poder de las Administraciones Públicas. En el caso de indicar NO, debe aportar documentación justificativa"}
                                        hasFiles={true}
                                        checked={acceptedPrivacy}
                                        files={givenDocumentationFiles}
                                        onChange={(checked: boolean, files: DropzoneFile[]) => {
                                            setAcceptedPrivacy(checked)
                                            setGivenDocumentationFiles(files)
                                        }}
                                    />

                                </Form.Group>

                                <br/>
                                <h4 className={"purple my-4"}>Personas Autorizadas</h4>
                                <p>Personas autorizadas a recoger al niño/a y a trasladarlo a Urgencias en caso de
                                    necesidad
                                    (además de los solicitantes, en los casos en los que el alumno no esté autorizado
                                    para
                                    salir
                                    solo del centro tras las actividades).</p>

                                <br/>
                                <AuthorizedPersonForm
                                    onChange={(authorizedPersons: AuthorizedPerson[]) => {
                                        setAuthorizedPersons(authorizedPersons)
                                    }}
                                    defaultValues={authorizedPersons}
                                />

                                <h4 className={"purple my-4"}>Datos Bancarios para la Domiciliación</h4>
                                <p>Incluye los 24 caracteres del número de cuenta completo (IBAN). Escríbelo sin
                                    espacios en
                                    blanco ni guiones de separación.</p>

                                <Form>
                                    <Row className={"mt-4"}>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="bankName">
                                            <Form.Label>
                                                Nombre de la Entidad Bancaria
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBankEntity(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.bankEntity)}
                                                value={bankEntity}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.bankEntity}/>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="bankAccountOwner">
                                            <Form.Label>
                                                Nombre del Titular de la Cuenta
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountHolderName(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.accountHolderName)}
                                                value={accountHolderName}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.accountHolderName}/>}
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3 col-12 col-sm-6"
                                            controlId="accountHolderDocumentType">
                                            <Form.Label>
                                                Seleccione DNI / NIE o Pasaporte
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <NifTypeSelect
                                                defaultValue={accountHolderDocumentType}
                                                onChange={(newValue:any) => setAccountHolderDocumentType(newValue ? newValue.value : 0)}
                                                classes={(submitted && validationErrors.accountHolderDocumentType) ? 'is-invalid' : ''}
                                            />
                                            {submitted &&
                                                <FormErrorText error={validationErrors.accountHolderDocumentType}/>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-sm-6" controlId="bankAccountOwnerNif">
                                            <Form.Label>
                                                NIF / NIE / Pasaporte del Titular de la Cuenta
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountHolderNif(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.accountHolderNif)}
                                                className={"text-uppercase"}
                                                value={accountHolderNif}
                                            />
                                            {submitted && <FormErrorText error={validationErrors.accountHolderNif}/>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12" controlId="iban">
                                            <Form.Label>
                                                Número de Cuenta Bancaria IBAN (24 caracteres)
                                                &nbsp;<FormLabelRequiredAsterisk/>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIban(e.target.value)}
                                                required
                                                isInvalid={!!(submitted && validationErrors.iban)}
                                                value={iban}
                                            />
                                            <p className={"mt-2"}><small>Ejemplo: ES5020957139666954198654</small></p>
                                            {submitted && <FormErrorText error={validationErrors.iban}/>}
                                        </Form.Group>
                                    </Row>
                                </Form>

                                <br/>

                                <Form className={"mt-4"}>
                                    <Row>
                                        <Col>
                                            {
                                                formTerms.map((term, key) => {
                                                    return (
                                                        <Form.Group
                                                            key={key}
                                                            className="mb-3"
                                                            controlId={"term_" + key}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={term}
                                                                checked={acceptedTerms[key]}
                                                                onChange={() => checkAcceptedTerm(key)}
                                                                isInvalid={submitted && requiredTermKeys.indexOf(key) !== -1 && !acceptedTerms[key]}
                                                            />
                                                        </Form.Group>
                                                    )
                                                })
                                            }

                                            <Button
                                                className={"mt-4 purple-btn"}
                                                onClick={handleSubmit}
                                            >
                                                Enviar
                                            </Button>

                                        </Col>
                                    </Row>
                                </Form>

                            </>
                        }

                    </Col>
                </DefaultContainer>
            </RequireAuth>
        </>
    )
}
