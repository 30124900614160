import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useNavigate, useParams} from "react-router-dom";
import {AdminCentersForm} from "Views/Center/AdminCentersForm";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation, useQuery} from "react-query";
import {getCenterDetail, getMutipleCenterServices} from "Api/Query/CenterQuery";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {updateCenter} from "Api/Mutation/CenterMutation";

export const AdminCentersEdit : React.FC = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [entity, setEntity] = useState<any>({
        name: "",
        address:"",
        billable: true
    })
    const [services, setServices] = useState<any>([])

    const update = useMutation({
        mutationKey: [],
        mutationFn: (body: any) => updateCenter(body.id, body.data),
        onSuccess: () => {
            Notificator.success('El centro se ha editado correctamente')
            navigate(ROUTE_PATHS.ADMIN_CENTERS_LIST)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al editar el centro')
            navigate(ROUTE_PATHS.ADMIN_CENTERS_LIST)
        }
    })

    const onSave = (data: any, services : any = []) => {
       data = {...data, services: services}

        update.mutate({
            id: id,
            data: data
        })
    }

    const query = useQuery({
        enabled: false,
        queryKey: ['getCenterDetail', id],
        queryFn: () => getCenterDetail(id ?? '-1'),
        onSuccess: (response: any) => {
            setEntity(response.data._result)
            centerServicesQuery.refetch()
        },
        onError: () => {
            Notificator.error('Se ha producido un error al cargar los datos.')
        }
    })

    const centerId = id ?? -1
    const centerServicesQuery = useQuery({
        enabled: false,
        queryKey: ['getC', id],
        queryFn: () => getMutipleCenterServices([centerId.toString()],50),
        onSuccess: (response: any) => {
            setServices(response.data._result)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al cargar los servicios del centro.')
        }
    })

    useEffect(() => {
        query.refetch()
    }, []);

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: update.isLoading, text: 'Cargando'}))
    }, [update.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Detalle del centro</h1>
                        </Col>

                    </Row>

                    <Row>
                        <AdminCentersForm
                            onSave={onSave}
                            address={entity.address}
                            name={entity.name}
                            services={services}
                        />
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
