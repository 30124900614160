import React from "react";
import {AdminInscriptionDetail} from "Views/Inscription/AdminInscriptionDetail";

export const SocialServiceInscriptionDetail: React.FC = () => {

    return (
        <AdminInscriptionDetail isSocialServiceUserProfile={true} />
    )

}
