import {http} from "Services/Http/HttpClient";
import FilterBuilder from "Services/Filters/FilterBuilder";

export const getTutors = async (filters: any = {}, sort: string = ''): Promise<unknown> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let url = 'tutors' + (queryParams ? '?' + queryParams : '')

    if(sort){
        url += (queryParams ? '&sort=' : '?sort=') + sort
    }

    return await http.get(url)
}
