import React, {useEffect, useState} from "react";
import Select, {MultiValue} from "react-select";
import {SelectOptionType} from "Model/Select/SelectOption";
import {useQuery} from "react-query";
import {listSpecialNeeds} from "Api/Query/SpecialNeedQuery";

interface Props{
    onChange: (newValue: SelectOptionType) => void,
    value?: any,
    classes?: string
}

export const SpecialNeedSelect : React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(props.value ?? null)
    const [options, setOptions] = useState<any>([])

    const query = useQuery({
        enabled: false,
        queryKey: ['specialNeedsQuery'],
        queryFn: () => listSpecialNeeds(),
        onSuccess: (response: any) => {
            const data : any = []
            response.data._result.items.map(item => {
                data.push({
                    label: item.name,
                    value: item.id
                })
            })
            setOptions(data)
        }
    })

    useEffect(() => {
        if (!options.length) {
            return
        }

        setValue(props.value)
    }, [props.value, options])

    const onChange = (option: any) => {
        props.onChange(option)
    }

    useEffect(() => {
        query.refetch()
    }, [])

    return (
        <Select
            isLoading={query.isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={onChange}
            className={props.classes ?? ''}
            value={value}
        />
    )

}
