import React, {useEffect, useState} from "react"
import {RequireAuth} from "Components/Security/RequireAuth"
import {Alert, Button, Col, Row} from "react-bootstrap";
import {ActivityCard} from "Components/Activities/ActivityCard";
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useQuery} from "react-query";
import {getUserDetail, UserDetailResponseResult} from "Api/Query/UserQuery";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Notificator} from "Services/Notificator/Notificator";
import {UserDetailTutor} from "Model/Tutor/Tutor";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {UserService} from "Services/User/UserService";
import {ActivityListResponse, getActivities} from "Api/Query/ActivityQuery";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {TutorHasNotEnteredPersonalInfo} from "Components/Tutor/TutorHasNotEnteredPersonalInfo";

export const ListActivities: React.FC = () => {

    const navigate = useNavigate()
    const userService = new UserService()
    const userId = userService.getId()
    const tutorId = userService.getTutor()
    const pageTitle = 'Actividades disponibles'

    const [tutor, setTutor] = useState<UserDetailTutor | null>(null)
    const [activities, setActivities] = useState<ActivityListResponse[] | null>(null)

    const fakeImage = 'https://picsum.photos/440/320'

    const dispatch = useAppDispatch()
    const setAppLoading = (loading: boolean, text: string = ''): void => {
        dispatch(
            appLoading({
                value: loading,
                text: text
            })
        )
    }

    const userDetailQuery = useQuery({
        queryKey: ['userDetail', userId],
        queryFn: () => getUserDetail(userId),
        onSuccess: (data: any) => {
            const response: UserDetailResponseResult = data.data._result
            const hasTutor = typeof response.tutor.children !== 'undefined'

            if (!hasTutor) {
                return
            }

            setTutor(response.tutor)

            if(response.tutor.children.length){
                // load activities
                activitiesQuery.refetch()
            }
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const activitiesQuery = useQuery({
        queryKey: ['activitiesList'],
        queryFn: () => getActivities(),
        onSuccess: (data: any) => {
            const response: ApiListResponse = data.data
            const activities: ActivityListResponse[] = response._result.items
            setActivities(activities)
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        },
        enabled: false
    })

    useEffect(() => {
        setAppLoading(userDetailQuery.isLoading)
    }, [userDetailQuery.isLoading])

    useEffect(() => {
        if (!userDetailQuery.isLoading) {
            setAppLoading(userDetailQuery.isRefetching)
        }
    }, [userDetailQuery.isRefetching])

    useEffect(() => {
        if (!userDetailQuery.isLoading) {
            setAppLoading(activitiesQuery.isLoading)
        }
    }, [activitiesQuery.isLoading])

    useEffect(() => {
        if (!userDetailQuery.isLoading) {
            setAppLoading(activitiesQuery.isRefetching)
        }
    }, [activitiesQuery.isRefetching])

    useEffect(() => {
        if (!tutor) {
            userDetailQuery.refetch()
        }
    }, [])

    if (userDetailQuery.isLoading) {
        return (
            <RequireAuth>
                <DefaultContainer>
                    <Col id={"activities_list"} className={"px-sm-0"}>
                        <h1 className={"pb-4"}>{pageTitle}</h1>
                    </Col>
                </DefaultContainer>
            </RequireAuth>
        )
    }

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col id={"activities_list"} className={"px-sm-0"}>
                    <h1 className={"pb-4"}>{pageTitle}</h1>

                    {
                        (tutor && tutor.children.length === 0) &&
                        <>
                            <Alert variant={"danger"} style={{'marginBottom': 0}}>
                                Para poder ver las actividades tienes que haber dado de alta algún niño.
                            </Alert>
                            <br/>
                            <Button
                                className={"btn purple-btn"}
                                onClick={() => navigate(ROUTE_PATHS.KIDS_ADD)}
                            >
                                Añadir hijo/a
                            </Button>
                        </>
                    }

                    {!tutorId && <TutorHasNotEnteredPersonalInfo showAlert={true} showButton={true}/>}

                    {
                        activities &&
                        <Row>
                            {activities.length === 0 && <Col>No se han encontrado actividades.</Col>}
                            {activities.map((item: any) =>
                                <Col
                                    md={6}
                                    lg={4}
                                    key={uuidv4()}
                                >
                                    <ActivityCard
                                        onClick={() => navigate(ROUTE_PATHS.ACTIVITY_DETAIL.replace(':id', item.id))}
                                        image={item.image ?? fakeImage}
                                        name={item.name}
                                        description={item.description}
                                        id={item.id}
                                    />
                                </Col>)
                            }
                        </Row>
                    }

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
