import React, { useState, ChangeEvent } from 'react';

interface MonthSelectorProps {
    onChange: (selectedValue: number) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ onChange }) => {
    const months = [
        { value: -1, label: '' },
        { value: 1, label: 'Enero' },
        { value: 2, label: 'Febrero' },
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' }
    ];

    const [selectedMonth, setSelectedMonth] = useState<number>(-1);

    const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue: number = parseInt(event.target.value);
        setSelectedMonth(selectedValue);
        onChange(selectedValue);
    };

    return (
        <div>
            <select
                id="monthSelect"
                value={selectedMonth}
                onChange={handleMonthChange}
                className={"form-control"}
            >
                {months.map((month) => (
                    <option key={month.value} value={month.value}>{month.label}</option>
                ))}
            </select>
        </div>
    );
};

export default MonthSelector;
