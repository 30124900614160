export function createEmptyHttpResponsePromise() {
    return new Promise((resolve:any) => {
        resolve({
            data: {
                _result: {
                    items: []
                }
            }
        })
    })
}
