import React, {useState} from "react";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Link, useNavigate} from "react-router-dom";
import {AdminCentersForm} from "Views/Center/AdminCentersForm";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation} from "react-query";
import {createCenter, CreateCenterBody} from "Api/Mutation/CenterMutation";

export const AdminCentersAdd : React.FC = () => {

    const navigate = useNavigate()

    const createMutation = useMutation({
        mutationKey: ['createCenterMutation'],
        mutationFn: (body : CreateCenterBody) => createCenter(body),
        onSuccess: () => {
            Notificator.success('El centro se ha creado correctamente')
            navigate(ROUTE_PATHS.ADMIN_CENTERS_LIST)
        },
        onError: () => {
            Notificator.error('Se ha producido un error al crear el centro.')
        }
    })

    /**
     * @param formData
     */
    const onSave = (formData: {[key:string]:any}, services: any) => {
        createMutation.mutate({
            name: formData.name,
            address: formData.address,
            services: services
        })
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Crear centro</h1>
                        </Col>

                    </Row>

                    <Row>
                        <AdminCentersForm
                            onSave={onSave}
                        />
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
