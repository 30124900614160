"use strict";
module.exports = {
    name: "spanish_es",
    months: [["Enero", "ene"],
        ["Febrero", "feb"],
        ["Marzo", "mar"],
        ["Abril", "abr"],
        ["Mayo", "may"],
        ["Junio", "jun"],
        ["Julio", "jul"],
        ["Agosto", "ago"],
        ["Septiembre", "sep"],
        ["Octubre", "oct"],
        ["Noviembre", "nov"],
        ["Diciembre", "dic"]],
    weekDays: [
        ["Sábado", "sáb"],
        ["Domingo", "dom"],
        ["Lunes", "lun"],
        ["Martes", "mar"],
        ["Miércoles", "mié"],
        ["Jueves", "jue"],
        ["Viernes", "vie"]
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [["AM", "am"], ["PM", "pm"]]
};
