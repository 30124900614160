import React, { useState } from 'react';

interface YearSelectorProps {
    onChange: (selectedYear: number) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({ onChange }) => {
    const currentYear = new Date().getFullYear();
    const years = [2024]; // TODO: get years from database

    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue: number = parseInt(event.target.value);
        setSelectedYear(selectedValue);
        onChange(selectedValue);
    };

    return (
        <div>
            <select id="yearSelect" value={selectedYear} onChange={handleYearChange} className={"form-control"}>
                {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
        </div>
    );
};

export default YearSelector;
