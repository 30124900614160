import React from "react";
import {Form} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "../Form/FormLabelRequiredAsterisk";

interface Props {
    required?: boolean,
    onChange: (value: string) => void,
    defaultValue?: string
}

export const Observations: React.FC<Props> = (props) => {

    return (
        <Form.Group
            controlId="observations"
            className="my-3"
        >
            <Form.Label>
                Observaciones
                {props.required !== undefined && props.required === true && <FormLabelRequiredAsterisk/>}
            </Form.Label>
            <textarea
                className={"form-control"}
                rows={5}
                defaultValue={props.defaultValue ?? ''}
                onChange={(e:any) => props.onChange(e.target.value ?? '')}
            ></textarea>
        </Form.Group>
    )
}