import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {useMutation} from "react-query";
import {updateInscriptionActivitySpecialNeed} from "Api/Mutation/SpecialNeedMutation";
import {Notificator} from "Services/Notificator/Notificator";
import {appLoading} from "Store/loadingSlice";
import {useDispatch} from "react-redux";

interface Props {
    show: boolean,
    onClose: () => void,
    item: any,
    onUpdated: () => void
}

export const IsValidModal: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const validOptions = [
        {value: true, label: 'Sí'},
        {value: false, label: 'No'}
    ]

    const [show, setShow] = useState(false)
    const [isValid, setIsValid] = useState<any>(validOptions[0])

    const updateMutation = useMutation({
        mutationFn: (body: any) => updateInscriptionActivitySpecialNeed(body.id, body),
        mutationKey: ['updateInscriptionActivitySpecialNeedIsValid'],
        onSuccess: () => {
            props.onUpdated()
            Notificator.success('Se ha actualizado correctamente');
        },
        onError: () => {
            Notificator.error('Se ha producido un error al actualizar');
        }
    })

    const handleClose = () => {
        setShow(false)
        props.onClose()
    }

    const save = () => {

        updateMutation.mutate({
            id: props.item.id,
            validated: isValid.value
        })

        handleClose()
    }

    useEffect(() => {
        dispatch(appLoading({value: true, text: 'Cargando'}))
    }, [updateMutation.isLoading]);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    useEffect(() => {
        const itemIsValidated = props.item?.isValidated ?? false
        const option = validOptions.filter((option:any) => option.value == itemIsValidated)
        setIsValid(option[0])
    }, [props.item])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>VALIDACIÓN</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col xs={12} sm={10} md={10} className={"mx-auto"}>
                        <p>Seleccione Estado</p>
                        <Select
                            options={validOptions}
                            placeholder={""}
                            value={isValid}
                            onChange={(option:any) => setIsValid(option)}
                        />
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    className={"btn"}
                    onClick={handleClose}
                >Cancelar</Button>
                <Button
                    className={"purple-btn"}
                    onClick={save}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
