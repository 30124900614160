import React, {useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useNavigate} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminModalityForm} from "Views/Modalities/AdminModalityForm";
import {useMutation} from "react-query";
import {createActivitySession} from "Api/Mutation/ActivitySession";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";

export const AdminCreateModality : React.FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const createActivitySessionMutation = useMutation({
        mutationFn: (request: any) => createActivitySession(request.body),
        onSuccess: (response: any) => {
            Notificator.success('La modalidad se ha creado correctamente.')
            navigate(ROUTE_PATHS.ADMIN_MODALITIES_LIST)
        },
        onError: (error: any) => {
            Notificator.error('Se ha producido un error al crear la modalidad.')
        },
    })

    useEffect(() => {
        dispatch(appLoading({value: createActivitySessionMutation.isLoading, text: 'Cargando'}))
    }, [createActivitySessionMutation.isLoading])

    const save = (data: any) => {
        const weekDays : string[] = []
        data.weekDays.map(day => weekDays.push(day.id ?? day.value))

        const centerIds :string[] = []
        data.centers?.map(center => {
            centerIds.push(center.value)
        })

        createActivitySessionMutation.mutate({
            body: {
                centerIds: centerIds,
                activityId: data.activity.id,
                name: data.name,
                numberOfDays: data.numberOfDays,
                description: data.description,
                price: data.price,
                start: data.start,
                end: data.end,
                billable: data.billable,
                weekDays: weekDays,
                yearDays: data.yearDays,
                services: data.serviceIds,
                courseLevelIds: data.courseLevelIds
            }
        })
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <h1 className={"pb-4"}>Crear Modalidad</h1>
                    </Row>

                    <AdminModalityForm onSave={save} />

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
