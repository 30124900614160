import {DropzoneFile} from "Components/FileUpload/FileUpload";
import {createCustomSizeBlob} from "Services/File/FileBlob";

export const createDropzoneFile = (file: any): DropzoneFile => {
    const blob = createCustomSizeBlob(file.size, file.mimeType);
    const f = new File([blob], file.name)
    return {
        errors: [],
        file: f,
        id: file.id,
        name: file.name,
        valid: true,
        isImage: file.isImage ?? false,
        url: file.url,
        deleteUrl: file.deleteUrl
    }
}
