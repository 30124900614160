import jwt_decode from "jwt-decode";
import {authCheck, getAuth} from "Services/Security/AuthService";
import {UserRoleIds} from "Model/User/UserRoles";
import {ImpersonationService} from "./ImpersonationService";

export interface User {
    sub: string, // id
    username: string,
    tutor: string
    role: {
        id: string,
        name: string,
        role: number
    }
}

export class UserService {
    private user: User | null

    constructor() {
        this.user = null

        if (this.isAuthenticated()) {
            this.user = jwt_decode(getAuth())
        }
    }

    isAuthenticated(): boolean {
        return authCheck()
    }

    getId(): string {
        const impersonationService = new ImpersonationService()

        if(impersonationService.getImpersonationUser()){
            return impersonationService.getImpersonationUser().id
        }

        return this.user?.sub ?? ''
    }

    getUsername(): string {
        const impersonationService = new ImpersonationService()

        if(impersonationService.getImpersonationUser()){
            return impersonationService.getImpersonationUser().name
        }

        return this.user?.username ?? ''
    }

    getTutor(): string {
        const impersonationService = new ImpersonationService()

        if(impersonationService.getImpersonationUser()){
            return impersonationService.getImpersonationUser().tutor.id
        }

        return this.user?.tutor ?? ''
    }

    getRoleId(): number {

        const impersonationService = new ImpersonationService()

        if(impersonationService.getImpersonationUser()){
            return impersonationService.getImpersonationUser().role.role
        }

        return this.user?.role.role ?? -1
    }

    getRoleName(): string {
        return this.user?.role.name ?? ''
    }

    isRegularUser(): boolean {
        return this.user?.role.role ? (this.getRoleId() === UserRoleIds.REGULAR_USER) : false
    }

    isSpecialNeedsManager(): boolean {
        return this.user?.role.role ? (this.getRoleId() === UserRoleIds.SPECIAL_NEEDS) : false
    }

    isAdmin(): boolean {
        return this.user?.role.role ? (this.getRoleId() === UserRoleIds.ADMIN) : false
    }

    isSocialService(): boolean {
        return this.user?.role.role ? (this.getRoleId() === UserRoleIds.SOCIAL_SERVICES) : false
    }
}
