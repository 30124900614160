import React from "react";

interface Props{
    field:string,
    sortField: string,
    sortDirection: string
}

export const SortArrows: React.FC<Props> = (props) => {

    if(props.field !== props.sortField){
        return (<></>)
    }

    if(props.sortDirection === ''){
        return (<>▲</>)
    }

    if(props.sortDirection === '-'){
        return (<>▼</>)
    }

    return (<></>)
}