import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ActivitySelect} from "Components/Activities/ActivitySelect";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {useQuery} from "react-query";
import {getActivity} from "Api/Query/ActivityQuery";
import {getMutipleCenterServices} from "Api/Query/CenterQuery";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import Toggle from 'react-toggle'
import {WeekdaysSelect} from "Components/Course/WeekdaysSelect";
import {Notificator} from "Services/Notificator/Notificator";
import {ACTIVITY_TYPE_WEEKLY_ID} from "Components/Activities/ActivityTypeSelect";
import CurrencyInput, {CurrencyInputOnChangeValues} from "react-currency-input-field";
import {SimpleHtmlTextEditor} from "Components/TinyMce/SimpleHtmlTextEditor";
import {IdName} from "../../Model/Shared/IdName";
import {ROUTE_PATHS} from "../../Config/Router/Routes";
import {Link} from "react-router-dom";
import {CenterMultiSelect} from "Components/Center/CenterMultiSelect";
import {CourseLevelMultiSelect} from "Components/CourseLevel/CourseLevelMultiSelect";

interface Props {
    name?: string,
    description?: string,
    start?: string,
    end?: string,
    price?: number,
    numberOfDays?: number,
    billable?: boolean,
    activity?: any,
    centers?: IdName[],
    activityType?: number,
    weekDays?: any,
    services?: any,
    courseLevels?: any,
    onSave: (body: object) => void,
    isEdit?: boolean
}

export const AdminModalityForm: React.FC<Props> = (props) => {

    const [name, setName] = useState<string>(props.name ?? '')
    const [description, setDescription] = useState<string>(props.description ?? '')
    const [activity, setActivity] = useState<any>(props.activity ?? null)
    const [activityType, setActivityType] = useState<number>(props.activityType ?? 0)
    const [price, setPrice] = useState<number>(props.price ?? 0)
    const [numberOfDays, setNumberOfDays] = useState<number>(props.numberOfDays ?? 0)
    const [start, setStart] = useState<string>(props.start ?? '00:00')
    const [end, setEnd] = useState<string>(props.end ?? '00:00')
    const [billable, setBillable] = useState<boolean>(props.billable ?? true)

    /** List of service options **/
    const [services, setServices] = useState<{ value: string, label: string }[]>([])

    /** Selected services (by ids) **/
    const [serviceIds, setServiceIds] = useState<string[]>([])

    const [weekDays, setWeekDays] = useState<any[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(props.isEdit ?? false)
    const [activityCenters, setActivityCenters] = useState<string[]>([])
    const [courseLevels, setCourseLevels] = useState<string[]>([])
    const [courseLevelIds, setCourseLevelIds] = useState<string[]>([])
    const [activitySessionCenters, setActivitySessionCenters] = useState<string[]>([])
    const [selectedCenterIds, setSelectedCenterIds] = useState<any[]>([])

    let activityId = ''
    let centerIds: string[] = []
    let activityCenterIds: string[] = []

    const dispatch = useDispatch()

    const validate = (): boolean => {
        const errors: string[] = []
        const isWeeklyActivity = activityType === 1

        if (!activity) {
            errors.push('Actividad')
        }

        if (!name) {
            errors.push('Nombre')
        }

        if (isWeeklyActivity && !start) {
            errors.push('Hora inicio')
        }
        if (isWeeklyActivity && !end) {
            errors.push('Hora fin')
        }

        if (errors.length) {
            let errorMessage = 'Los siguientes campos son obligatorios:'
            errorMessage += '<ul>'
            errors.map(error => {
                errorMessage += `<li>${error}</li>`
            })
            errorMessage += '</ul>'
            Notificator.error(errorMessage)
        }

        if (errors.length === 0) {
            return true
        }

        return false
    }

    const save = () => {
        if (!validate()) {
            return
        }

        props.onSave({
            centers: activitySessionCenters,
            name: name,
            description: description,
            activity: activity,
            price: price,
            numberOfDays: numberOfDays,
            start: start,
            end: end,
            billable: billable,
            weekDays: weekDays,
            serviceIds: serviceIds,
            courseLevelIds: courseLevelIds
        })
    }

    const activityDetailQuery = useQuery({
        enabled: false,
        queryKey: ['adminActivityDetail', activityId],
        queryFn: () => getActivity(activityId),
        onSuccess: (response: any) => {
            setActivity(response.data._result)
            setActivityType(response.data._result.type)
            setWeekDays([])

            // set activity centers
            activityCenterIds = []
            response.data._result.centers.map((center: IdName) => {
                activityCenterIds.push(center.id)
            })
            setActivityCenters(activityCenterIds)
        }
    })

    const centerServicesQuery = useQuery({
        enabled: false,
        queryKey: ['adminCenterServicesQuery'],
        queryFn: () => getMutipleCenterServices(centerIds),
        onSuccess: (response: any) => {
            setServices(response.data._result.items)
        }
    })

    const onActivityChange = (option: any) => {
        // get activity detail
        activityId = option?.value

        if (activityId) {
            activityDetailQuery.refetch()
        }
    }

    const onCourseLevelChange = (options: any) => {
        let optionValues: string[] = []
        options.map(opt => optionValues.push(opt.value))

        setCourseLevelIds(optionValues)
        setCourseLevels(options)
    }

    const onCenterChange = (options: any) => {
        let optionValues: string[] = []
        options.map(opt => optionValues.push(opt.value))

        // set center ids
        centerIds = optionValues

        // get selected service options
        const selectedServiceOptions: any = []
        serviceIds.map((serviceId: string) => {
            services.map((serviceOption: any) => {
                if (serviceOption.id === serviceId) {
                    selectedServiceOptions.push(serviceOption)
                }
            })
        })

        // remove services that are not in the new selected centers
        const newServiceIds: string[] = []
        const newServiceOptions: any[] = []
        selectedServiceOptions.map((serviceOption:any) => {
            const currentCenterId = serviceOption.center.id
            if(centerIds.includes(currentCenterId)){
                newServiceIds.push(serviceOption.id)
                newServiceOptions.push({
                    label: serviceOption.name,
                    value: serviceOption.id,
                })
            }
        })

        // update services
        setServices(newServiceOptions)
        setServiceIds(newServiceIds)

        // update centers
        setActivitySessionCenters(options)
        setSelectedCenterIds(optionValues)

        // refresh center services
        if (centerIds.length) {
            centerServicesQuery.refetch()
        }
    }

    useEffect(() => {
        dispatch(appLoading({value: activityDetailQuery.isLoading, text: 'Cargando'}))
    }, [activityDetailQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: activityDetailQuery.isRefetching, text: 'Cargando'}))
    }, [activityDetailQuery.isRefetching])

    useEffect(() => {
        dispatch(appLoading({value: centerServicesQuery.isLoading, text: 'Cargando'}))
    }, [centerServicesQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: centerServicesQuery.isRefetching, text: 'Cargando'}))
    }, [centerServicesQuery.isRefetching])

    useEffect(() => {
        // Edit form by props
        setName(props.name ?? '')
        setDescription(props.description ?? '')
        setStart(props.start ?? '00:00')
        setEnd(props.end ?? '00:00')
        setPrice(props.price ?? 0)
        setNumberOfDays(props.numberOfDays ?? 0)
        setBillable(props.billable ?? true)
        setActivity(props.activity ?? null)
        setActivityType(props.activityType ?? 0)
        setWeekDays(props.weekDays ?? [])

        if (props.services?.length) {
            const servicesIds: string[] = []
            const serviceOptions: any = []
            props.services.map((serviceItem: { id: string, name: string }) => {
                const option: any = {
                    label: serviceItem.name,
                    value: serviceItem.id
                }
                servicesIds.push(serviceItem.id)
                serviceOptions.push(option)
            })

            setServices(serviceOptions)
            setServiceIds(servicesIds)
        }

        if (props.centers?.length) {
            const centerOptions: any = []
            props.centers.map(center => {
                centerIds.push(center.id)

                const centerOption = ('name' in center) ? {
                    label: center.name,
                    value: center.id
                } : center
                centerOptions.push(centerOption)
            })
            setActivitySessionCenters(centerOptions)
            centerServicesQuery.refetch()
        }

        if (props.courseLevels?.length) {
            const courseLevelsOptions: any = []
            const courseLevelsOptionIds : string[] = []
            props.courseLevels.map(item => {
                courseLevelsOptionIds.push(item.id)

                const courseOption = ('name' in item) ? {
                    label: item.name,
                    value: item.id
                } : item
                courseLevelsOptions.push(courseOption)
            })
            setCourseLevels(courseLevelsOptions)
        }

    }, [
        props.name,
        props.description,
        props.start,
        props.end,
        props.price,
        props.numberOfDays,
        props.billable,
        props.activity,
        props.activityType,
        props.weekDays,
        props.services,
        props.centers,
        props.courseLevels
    ])

    const addSelectedServices = (serviceId: string): void => {
        const newSelectedServices = [...serviceIds, serviceId]
        setServiceIds(newSelectedServices)
    }

    const removeSelectedService = (serviceId: string): void => {
        const newServiceIds: any = serviceIds.filter((id: string) => {
            return serviceId !== id
        })
        setServiceIds(newServiceIds)
    }

    const isServiceChecked = (service: IdName) => {
        const exists = serviceIds.filter((defaultServiceId: string) => defaultServiceId === service.id)

        if (exists.length > 0) {
            return true
        }

        return false
    }

    return (
        <>
            <Form className={"row my-4"}>

                <Row>
                    <h4>Modalidad</h4>
                </Row>

                <Row>
                    <Col xs={12} sm={6} md={6} style={{"paddingBottom": "20px"}}>
                        <Form.Group controlId={"modalityActivity"}>
                            <Form.Label>
                                Actividad
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>

                            {
                                isEdit && activity &&
                                <h5>
                                    <Link to={ROUTE_PATHS.ADMIN_ACTIVITIES_EDIT.replace(':id', activity.id)}>
                                        {activity?.name}
                                    </Link>
                                </h5>
                            }

                            {!isEdit &&
                                <ActivitySelect
                                    onChange={(option: any) => onActivityChange(option)}
                                    value={activity}
                                    filters={{"activityType": ACTIVITY_TYPE_WEEKLY_ID}}
                                />
                            }
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6} md={6} className={"mb-3"}>
                        <Form.Group controlId={"modalityName"}>
                            <Form.Label>
                                Nombre de la modalidad
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                required
                                value={name}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mb-3"}>
                        <Form.Label>
                            Centro/s
                            &nbsp;<FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <CenterMultiSelect
                            onChange={onCenterChange}
                            values={activitySessionCenters}
                            // classes={!!validationErrors.centerIds ? 'is-invalid' : ''}
                        />
                    </Col>

                    {
                        activityType === 1 &&
                        <>
                            <Col xs={12} sm={6} md={6} className={"mb-3"}>
                                <Form.Group controlId={"modalityPrice"}>
                                    <Form.Label>
                                        Precio
                                        &nbsp;<FormLabelRequiredAsterisk/>
                                    </Form.Label>

                                    <CurrencyInput
                                        id="price"
                                        name="price"
                                        className={"form-control"}
                                        defaultValue={price}
                                        decimalsLimit={2}
                                        decimalSeparator={"."}
                                        decimalScale={2}
                                        onValueChange={(value, name, values: any) => setPrice(values?.float ? values.float : 0)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={"mb-3 d-flex align-items-end"}>
                                <Form.Group>
                                    <Form.Label htmlFor={"billable"}>
                                        <input
                                            type="checkbox"
                                            id={"billable"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBillable(e.target.checked)}
                                            checked={billable}
                                        />
                                        &nbsp;Facturable
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={"mb-3"}>
                                <Form.Group controlId={"modalityNumberOfDays"}>
                                    <Form.Label>
                                        Hora inicio
                                        &nbsp;<FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Form.Control
                                        type="time"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStart(e.target.value)}
                                        required
                                        value={start}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={"mb-3"}>
                                <Form.Group controlId={"modalityNumberOfDays"}>
                                    <Form.Label>
                                        Hora fin
                                        &nbsp;<FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Form.Control
                                        type="time"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEnd(e.target.value)}
                                        required
                                        value={end}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={6} className={"mb-3"}>
                                <Form.Group controlId={"modalityNumberOfDays"}>
                                    <Form.Label>
                                        Cursos
                                        &nbsp;<FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <CourseLevelMultiSelect
                                         onChange={onCourseLevelChange}
                                         values={courseLevels}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className={"mb-3"}>
                                <Form.Group controlId={"modalityName"}>
                                    <Form.Label>
                                        Descripción
                                    </Form.Label>

                                    <SimpleHtmlTextEditor
                                        onChange={(htmlContent) => {
                                            setDescription(htmlContent)
                                        }}
                                        defaultContent={description}
                                        height={300}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    }
                </Row>

                <Row className={"mt-2"}>
                    <Col sm={12} md={6}>
                        <h4>Días</h4>
                        <Form.Label className={"d-block"}>
                            {activityType === 0 &&
                                <span>Selecciona una actividad para poder seleccionar el tipo de días</span>}
                            {activityType === 1 && <span>Días de la semana</span>}
                        </Form.Label>

                        {
                            activityType === 1 &&
                            <>
                                <WeekdaysSelect
                                    onChange={(weekDays: any) => setWeekDays(weekDays)}
                                    value={weekDays}
                                />
                                <Form.Group className={"mt-3"}>
                                    <Form.Label className={"d-block"}>
                                        Número de días necesarios&nbsp;<FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumberOfDays(e.target.value ? parseInt(e.target.value) : 1)}
                                        // required
                                        value={numberOfDays}
                                    />
                                </Form.Group>
                            </>
                        }


                    </Col>

                    <Col sm={12} md={6}>
                        <h4>Servicios</h4>
                        {
                            !activity &&
                            <p>Selecciona una actividad para mostrar los servicios disponibles.</p>
                        }
                        {
                            services &&
                            services.map((service: any, key: number) => {
                                return <Col key={key}>
                                    <label
                                        htmlFor={service.id}
                                        className={"d-block mb-2 fw-bold pointer"}
                                    >
                                        {service.name} +{service.price} €
                                    </label>
                                    <Toggle
                                        id={service.id}
                                        checked={isServiceChecked(service)}
                                        onChange={(e) => e.target.checked === true
                                            ? addSelectedServices(service.id)
                                            : removeSelectedService(service.id)
                                        }
                                    />
                                </Col>
                            })
                        }

                        {activity && services.length === 0 && <>No hay servicios para esta modalidad.</>}

                    </Col>
                </Row>

            </Form>
            <br/>

            <Button
                className={"purple-btn btn-sm mt-4"}
                onClick={() => save()}
            >
                Guardar
            </Button>
        </>
    )
}
