import React from "react";
import {Spinner} from "react-bootstrap";

interface Props{
    text?: string
}

export const LoadingOverlay: React.FC<Props> = (props) => {

    return (
        <div className={"loading-overlay-wrapper"}>
            <div className={"text"}>
                <Spinner animation="grow" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span className={"d-block mt-3"}>{props.text && props.text.length ? props.text : 'Cargando'}</span>
            </div>
            <div className={"loading-overlay"}></div>
        </div>
    )
}
