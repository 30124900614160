import {http} from "Services/Http/HttpClient";

export interface CreateCenterBody {
    name: string
    address: string,
    services: any
}

export const createCenter = async(body : CreateCenterBody) : Promise<unknown> => {
    return await http.post('centers', body)
}

export const updateCenter = async(id:string, body : CreateCenterBody) : Promise<unknown> => {
    return await http.patch('centers/' + id, body)
}

export const deleteCenters = async(ids: string[]) : Promise<any> => {
    return http.delete('/centers', {
        data: {
            ids: ids
        }
    })
}
