import React from "react"
import {Col, Row} from "react-bootstrap"
import {Link} from "react-router-dom";
import logoFooter3 from "Assets/images/footer-img-3.png";
import logoSanseWhite from "Assets/images/logo-sanse-white.png";
import lema from "Assets/images/Lema_RRSS_blanco-300x130.png";
import gobEsp from "Assets/images/gobierno-esp.png";
import corresponsales from "Assets/images/corresponsales.png";
import comMadrid from "Assets/images/com_madrid.png";
import comMadridUni from "Assets/images/com_madrid_uni.png";

import gob600 from "Assets/images/gob-600.jpg";
import {PrivacyPolicyLink} from "Components/Link/PrivacyPolicyLink";
import {LegalWarningLink} from "Components/Link/LegalWarningLink";
import {DataProtectionLink} from "../../Components/Link/DataProtectionLink";

export const Footer: React.FC = () => {

    return (
        <Row id={"footer"}>
            <Col className={"col-12 col-md-10 mx-auto"}>
                <Row>
                    <Col className={"col-12 col-sm-6 col-lg-6 mx-auto"}>
                        <span className={"heading"}>Sanse Concilia</span>
                        <span>Concejalía de Educación del Ayuntamiento de San Sebastián de los Reyes</span>
                        <br/>
                        <LegalWarningLink/>&nbsp;·&nbsp;<PrivacyPolicyLink/>&nbsp;·&nbsp;<DataProtectionLink/>
                    </Col>
                    <Col className={"col-12 col-sm-6 col-lg-6 mx-auto"}>
                        <span className={"heading"}>Contacto</span>
                        <span>Si necesitan ponerse en contacto con nosotros para cualquier aclaración o duda se pueden dirigir a nosotros en:</span>
                        <span className={"font-weight-bold"}>Centro Sociocultural “Pablo Iglesias”</span>
                        <span>Avda. Baunatal, 18 , 4ª planta.Tel.: <Link
                            to={"tel:916588999"}>91 658 89 99</Link> - <Link
                            to={"916588993"}>91 658 89 93</Link></span>
                        <span>Email: <a href="mailto:sanseconcilia@ssreyes.org">sanseconcilia@ssreyes.org</a></span>
                    </Col>
                </Row>

                <Row style={{ marginTop: '60px' }}>
                    <Col className="col-12">
                        <Row>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6 pb-4 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <a href={gob600} target="_blank" className="text-center">
                                    <img src={gob600} alt="Logo" style={{ maxWidth: "100%" }} />
                                </a>
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={corresponsales} alt="Corresponsales" style={{ minWidth: "300px" }} />
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={comMadrid} alt="Comunidad de Madrid" style={{ maxWidth: "300px" }} />
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={comMadridUni} alt="Comunidad de Madrid" style={{ maxWidth: "300px" }} />
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={logoSanseWhite} alt="Logo" style={{ minWidth: "225px", maxWidth:'100%' }} />
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={logoFooter3} alt="Logo" />
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-3 col-xl-4 col-xxl-3 d-flex align-items-center justify-content-center justify-content-lg-start pb-4">
                                <img src={lema} alt="Logo" style={{ maxWidth: "150px" }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Col>
        </Row>
    )
}
