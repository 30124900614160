import {LocalStorage} from "Services/LocalStorage/LocalStorage"
import {refreshToken} from "Api/Query/SecurityQuery";

export const AUTH_TOKEN_NAME = 'auth_token'

export const authCheck = () : boolean => {
    const localStorage = new LocalStorage()
    return localStorage.get(AUTH_TOKEN_NAME)
}

export const setAuthToken = (token: string) : void => {
    const localStorage = new LocalStorage()
    localStorage.set(AUTH_TOKEN_NAME, token)
}

export const getAuth = () : string => {
    const localStorage = new LocalStorage()
    const authToken = localStorage.get(AUTH_TOKEN_NAME)
    return authToken ? `Bearer ${authToken}` : ''
}

export const removeAuthToken = () :void => {
    const localStorage = new LocalStorage()
    localStorage.remove(AUTH_TOKEN_NAME)
}

export const refreshTokenAndSave = () : Promise<any> => {
    return new Promise((resolve, reject) => {
        refreshToken()
            .then(response => {
                const responseData = response.data
                const token : string = responseData._result.token ?? ''

                if(token){
                    setAuthToken(token)
                    resolve(token)
                }

                if(!token){
                    reject('Token not found in respose')
                }
            })
            .catch(error => reject(error))
    })

}
