import {http} from "Services/Http/HttpClient";

export interface PostUserRequest {
    username: string,
    email: string,
    password: string
}

/**
 * @param username
 * @param email
 * @param password
 * @param userType
 * @param isActive
 * @param isEmailValidated
 */
export const createUser = async (
    username: string,
    email: string,
    password: string,
    userType: any = null,
    isActive: boolean = false,
    isEmailValidated: boolean = false
) : Promise<any> => {
    let body = {
        username: username,
        email: email,
        password: password,
        isActive: isActive,
        isEmailValidated: isEmailValidated
    }

    if (userType) {
        // @ts-ignore
        body = {...body, userType: userType}
    }

    return await http.post(`/users`, body)
}

export const deleteUsers = async (ids: string[]): Promise<any> => {
    return http.delete('/users', {
        data: {
            ids: ids
        }
    })
}

export interface UserRequest {
    username?: string,
    email?: string,
    password?: string,
    roleId?: string,
    isActive?: boolean,
    isEmailValidated?: boolean,
}

export const updateUser = async (body: UserRequest, id: string): Promise<any> => {
    return http.patch(`users/${id}`, body)
}

export const impersonateUser = async(userId: string) : Promise<any> => {
    return http.post('/user-impersonation', {
        id: userId
    })
}
export const sendUserActivationMail = async(userId: string) : Promise<any> => {
    return http.post('/users/send-validation-link/' + userId, )
}