import React from "react";
import {Col, Row} from "react-bootstrap";
import {DefaultContainer} from "../Shared/DefaultContainer";


export const Help: React.FC = () => {

    return (
        <DefaultContainer>

            <h1 className={"pb-4"}>
                Ayuda
            </h1>

            <Col>
                <video
                    src="https://sans-api.albertolabs.com/uploads/SanseConcilia-Manual-2024.mp4"
                    autoPlay={true}
                    controls
                    width={"100%"}>
                    <p>Su navegador no soporta vídeos HTML5.</p>
                </video>
            </Col>

        </DefaultContainer>
    )
}