import {Base64} from 'js-base64'
import axios from "axios"
import {http as httpClient} from "Services/Http/HttpClient"

export interface LoginBodyRequest {
    username: string,
    password: string
}
export interface LoginBodyResponse {
    data:{
        _result: {
            token: string
        }
    }
}

/**
 * @param username
 * @param password
 */
export const login = async (username: string, password: string) => {
    const http = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json",
            "Authorization": "Basic " + Base64.encode(`${username}:${password}`)
        }
    })

    return await http.post(`/login`)
}

export const requestPasswordRecovery = async(email: string) => {
    return httpClient.post('/password-recovery/request', {
        email: email
    })
}

export const updatePasswordFromRecoveryRequest = async(
    token: string,
    password: string
) => {
    return httpClient.patch('/password-recovery/update/' + token, {
        password: password
    })
}
