import React, {useEffect, useState} from "react";
import {Alert, Col} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {useQuery} from "react-query";
import {checkAccountValidationToken} from "Api/Query/AccountActivationQuery";
import {Notificator} from "Services/Notificator/Notificator";

export const AccountActivation: React.FC = () => {

    const {token} = useParams()
    const dispatch = useAppDispatch()

    const [success, setSuccess] = useState<boolean>(false)
    const [notFound, setNotFound] = useState<boolean>(false)

    const setAppLoading = (loading: boolean, text: string = ''): void => {
        dispatch(
            appLoading({
                value: loading,
                text: text
            })
        )
    }

    useEffect(() => {
        setAppLoading(true, 'Validando')
    }, [])

    useQuery(
        {
            queryKey: ['checkAccountValidationToken', token],
            queryFn: () => checkAccountValidationToken(token ?? ''),
            onSuccess: () => {
                setSuccess(true)
                setAppLoading(false)
                Notificator.success('Su cuenta se ha activado correctamente.')
            },
            onError: (error: any) => {
                const errorMessage = error.response.data._error.message
                let notificationMessage = 'Se ha producido un error inesperado. Inténtelo de nuevo.'

                if (typeof errorMessage === 'object') {
                    notificationMessage = errorMessage.join("\n")
                }

                setAppLoading(false)

                if(error.response.status === 400){
                    setNotFound(true)
                    return
                }

                Notificator.error(notificationMessage, 'Error')
            }
        }
    )

    return (
        <DefaultContainer>
            <Col id={"signup"} md={6} className={"mx-md-auto"}>
                <h1 className={"text-center"}>Activación de Cuenta</h1>
                {
                    success &&
                    <>
                        <br/>
                        <Alert
                            variant={"success"}
                            className={"mt-4"}
                        >
                            Tu cuenta se ha activado satisfactoriamente.
                        </Alert>
                        <p>
                            <Link to={ROUTE_PATHS.LOGIN}>Iniciar Sesión</Link>
                        </p>
                    </>
                }
                {
                    notFound &&
                    <Alert
                        variant={"danger"}
                        className={"mt-4"}
                    >
                        Error, esta validación ha caducado o no existe.
                    </Alert>
                }

            </Col>
        </DefaultContainer>
    )
}