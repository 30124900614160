import FilterBuilder from "Services/Filters/FilterBuilder";
import {http} from "Services/Http/HttpClient";

export const searchAdminInscriptionSpecialNeeds = async(filters: any = {}) : Promise<any[]> => {
    filters = {
        ...filters,
        // sort: 'needValidation',
        needValidation: 1
    }
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get(`inscriptions/special-needs/admin` + (queryParams ? '?' + queryParams : ''))
}

export const exportAdminInscriptionSpecialNeeds = async(
    filters: any = {}
) : Promise<any[]> => {
    const newFilters = {...filters, csv: "1"}
    return searchAdminInscriptionSpecialNeeds(newFilters)
}

export const listSpecialNeeds = async() : Promise<any[]> => {
    return await http.get('/special-needs')
}
