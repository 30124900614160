import React from 'react'
import {AdminInscriptionList} from "Views/Inscription/AdminInscriptionList";

export const SocialServicesDashboard : React.FC = () => {
    return (
        <AdminInscriptionList
            showStatusFilter={false}
            isSocialServiceUser={true}
        />
    )
}
