import React, {useEffect} from 'react'
import {Button, Col, Row} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "hooks";
import {useQuery} from "react-query";
import {getCart} from "Api/Query/CartQuery";
import {markCartAsCheckedOnLogin, setTotalCartCounter} from "Store/cartSlice";
import {useDispatch} from "react-redux";
import {TutorHasNotEnteredPersonalInfo} from "Components/Tutor/TutorHasNotEnteredPersonalInfo";

interface Props{
    hasTutor: boolean,
    totalKids: number
}

export const UserDashboard : React.FC<Props> = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const cartCheckedOnLogin = useAppSelector((state) => state.cart.checkedOnLogin)

    const cartDetailQuery = useQuery({
        enabled: false,
        queryKey: ['cartDetail'],
        queryFn: () => getCart(),
        onSuccess: (data: any) => {
            const response = data.data._result
            dispatch(setTotalCartCounter(response.items?.length ?? 0))
            dispatch(markCartAsCheckedOnLogin(true))
        }
    })

    useEffect(() => {
        if(!cartCheckedOnLogin){
            cartDetailQuery.refetch()
        }
    }, [])

    return (
        <>
            {!props.hasTutor && <TutorHasNotEnteredPersonalInfo showAlert={true} showButton={true} /> }

            {props.hasTutor && props.totalKids > -1 &&
                <Row className={"buttons"}>
                    <Col className={"px-sm-0 mb-4"}>
                        <Button
                            className={"btn button"}
                            onClick={() => navigate(ROUTE_PATHS.KIDS_ADD)}
                        >
                            Añadir Niño/a
                        </Button>
                    </Col>
                    <Col className={"px-sm-0 mb-4"}>
                        <Button
                            className={"btn button"}
                            onClick={() => navigate(ROUTE_PATHS.KIDS_LIST)}
                        >
                            Actualizar Niños/as
                        </Button>
                    </Col>
                    <Col className={"px-sm-0"}>
                        <Button
                            className={"btn button"}
                            onClick={() => navigate(ROUTE_PATHS.ACTIVITIES_LIST)}
                        >
                            Inscribir Niños/as en Actividades
                        </Button>
                    </Col>
                </Row>
            }
        </>
    )
}
