import {CookieService} from "../Http/CookieService";
import {ROUTE_PATHS} from "../../Config/Router/Routes";

export const IMPERSONATION_COOKIE_NAME = 'impersonated_user'

export class ImpersonationService {

    getImpersonationUser = (): any => {
        const cookieService = new CookieService()
        return cookieService.getCookie(IMPERSONATION_COOKIE_NAME) ?
            JSON.parse(cookieService.getCookie(IMPERSONATION_COOKIE_NAME)) :
            null
    }

    exitImpersonation = () : void => {
        const cookieService = new CookieService()
        cookieService.removeCookie(IMPERSONATION_COOKIE_NAME)
        window.location.href = ROUTE_PATHS.DASHBOARD
    }
}