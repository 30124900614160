import {ErrorConverter} from "Services/Http/ErrorConverter";

export class ErrorMessageBuilder
{
    static create = (error : any) : string => {
        const errorMessage = error.response.data._error.message
        let notificationMessage = 'Se ha producido un error inesperado. Inténtelo de nuevo.'

        if (typeof errorMessage === 'object') {
            notificationMessage = errorMessage.join("\n")
        }

        if (typeof errorMessage === 'string') {
            notificationMessage = errorMessage
        }

        return notificationMessage
    }

    static getServerErrors = (error : any) : object => {
        const errorMessage = error.response.data._error.message

        if (typeof errorMessage !== 'object') {
            return {}
        }

        return ErrorConverter.convertToHashmap(errorMessage)
    }
}
