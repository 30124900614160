import {http} from "Services/Http/HttpClient";

export const getWeekdays = async() => {
    return http.get('/weekdays')
}

export const getCourseLevels = async() => {
    return http.get('/course-levels')
}

export const getCourseLevelDetail = async(id : string) => {
    return http.get('/course-levels/' + id)
}
