import React from "react";
import {Button} from "react-bootstrap";

interface Props {
    id: string,
    name: string,
    description: string,
    image: string,
    onClick: () => void
}

export const ActivityCard: React.FC<Props> = (props) => {

    return(
        <div
            onClick={props.onClick}
            className={"activityCard pointer"}>
            <div className={"w-100"}>
                {props.image && <img src={props.image} title={props.name} alt={props.name} />}
            </div>
            <div className={"body"}>
                <h3 className={"name"}>{props.name}</h3>
                <div className={"description"}>{props.description}</div>
                <Button
                    className={"purple-btn"}>
                    Información y Registros
                </Button>
            </div>
        </div>
    )
}
