import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
interface BurguerMenuState {
    isOpen: boolean
}

// Define the initial state using that type
const initialState: BurguerMenuState = {
    isOpen: false
}

export const burguerMenuSlice = createSlice({
    name: 'burguerMenu',
    initialState,
    reducers: {
        isBurguerMenuOpen: (state, action: PayloadAction<BurguerMenuState>) => {
            state.isOpen = action.payload.isOpen
        }
    },
})

export const { isBurguerMenuOpen } = burguerMenuSlice.actions

export default burguerMenuSlice.reducer
