import {http} from "Services/Http/HttpClient";

export interface AddCartItemBody {
    idChild: string
    idActivitySession?: string
    days: string[]
    initDate?: string|null
    idActivitySessionServices?: string[]
    services?: Array<{serviceId: string, activityId: string}>
    observations?: string|null
}

export const addCartItem = async(body : AddCartItemBody) : Promise<unknown> => {
    return await http.patch('cart', body)
}

export const removeCartItem = async(id : string) : Promise<unknown> => {
    return await http.delete('cart/' + id)
}
