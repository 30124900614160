import React from "react";
import {Button} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {getCenters} from "Api/Query/CenterQuery";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {deleteCenters} from "Api/Mutation/CenterMutation";

export const AdminCenterList : React.FC = () => {

    const headers: string[] = [
        'NOMBRE CENTRO',
        'DIRECCIÓN',
    ]

    return (
        <RequireAuth>
            <AdminCrudList
                title={"Listado de centros"}
                headers={headers}
                queryMethod={getCenters}
                queryName={"adminListCenters"}
                deleteMethod={deleteCenters}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            <Link to={ROUTE_PATHS.ADMIN_CENTERS_EDIT.replace(":id", item.id)}>
                                {item.name}
                            </Link>
                        </td>
                        <td>{item.address}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={ROUTE_PATHS.ADMIN_CENTERS_EDIT.replace(":id", item.id)}
                            >
                                Modificar
                            </Button>
                        </td>
                    </>
                }
                createButton={
                    <Button
                        className={"purple-btn"}
                        href={ROUTE_PATHS.ADMIN_CENTERS_ADD}
                    >
                        Crear centro
                    </Button>
                }
            />

        </RequireAuth>
    )
}
