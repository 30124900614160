import {ROUTE_PATHS} from "Config/Router/Routes";

export const ADMIN_MENU = [
    {
        "title": "Inicio",
        "path": ROUTE_PATHS.DASHBOARD,
    },
    {
        "title": "Personas",
        "path" : "#",
        "children" : [
            {
                "title": "Niños/as",
                "path" : ROUTE_PATHS.ADMIN_KIDS_LIST
            },
            {
                "title": "Usuarios",
                "path" : ROUTE_PATHS.ADMIN_USER_LIST
            },
            {
                "title": "Necesidades individuales",
                "path" : ROUTE_PATHS.ADMIN_SPECIAL_NEEDS_LIST
            }
        ]
    },
    {
        "title" : "Solicitudes",
        "path" : ROUTE_PATHS.ADMIN_INSCRIPTION_LIST
    },
    {
        "title" : "Gestión",
        "path" : "#",
        "children" : [
            {
                "title": "Actividades",
                "path" : ROUTE_PATHS.ADMIN_ACTIVITIES_LIST
            },
            {
                "title": "Modalidades",
                "path" : ROUTE_PATHS.ADMIN_MODALITIES_LIST
            },
            {
                "title": "Centros",
                "path" : ROUTE_PATHS.ADMIN_CENTERS_LIST
            }
        ]
    }
]
