import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useMutation, useQuery} from "react-query";
import {searchAdminActivities} from "Api/Query/ActivityQuery";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {Notificator} from "Services/Notificator/Notificator";
import {deleteActivities} from "Api/Mutation/ActivityMutation";
import Pagination from 'rc-pagination';
import { renderHtml } from "Services/View/ViewUtils";

export const AdminActivitiesList: React.FC = () => {

    const [allSelected, setAllChecked] = useState<boolean>(false)
    const [selectedActivities, setSelectedActivities] = useState<{ [key: string]: boolean }>({})
    const [activities, setActivities] = useState<any[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const [pageSize, setPageSize] = useState<number>(12)
    const [page, setPage] = useState<number>(1)

    const [searchFilters, setSearchFilters] = useState<{[key:string]:any}>({
        size: pageSize,
        page: page
    })
    const [searchFilterValue, setSearchFilterValue] = useState<string>('')

    const [totalRecords, setTotalRecords] = useState<number>(0)

    // const [initDateFilter, setInitDateFilter] = useState<string>('')
    // const [endDateFilter, setEndDateFilter] = useState<string>('')

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const checkAll = () => {
        if (allSelected) {
            setSelectedActivities(initCheckboxes(activities))
        } else {
            const checkboxes = selectedActivities
            Object.keys(checkboxes).map(key => checkboxes[key] = true)
            setSelectedActivities(checkboxes)
        }

        setAllChecked(!allSelected)
    }

    const isAnyRowChecked = (): boolean => {
        for (let key in Object.values(selectedActivities)) {
            const isChecked = Object.values(selectedActivities)[key]

            if (isChecked) {
                return true
            }
        }

        return false
    }

    const getCheckedIds = (): string[] => {
        const ids: string[] = []

        const keys = Object.keys(selectedActivities)
        const values = Object.values(selectedActivities)

        for (let i in values) {
            const checked = values[i]
            if (checked) {
                ids.push(keys[i])
            }
        }

        return ids
    }

    const onSelectedCheckboxRowChange = (item: any) => {
        setSelectedActivities({
            ...selectedActivities,
            [item.id]: !selectedActivities[item.id]
        })
    }

    const initCheckboxes = (sessions: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        sessions.map(session => checkboxes[session.id] = false)
        return checkboxes
    }

    const deleteMultiple = () => {
        if (!window.confirm('¿Deseas eliminar los registros seleccionados?')) {
            return
        }

        setIsDeleting(true)
        deleteMutation.mutate(getCheckedIds())
    }

    const activitiesQuery = useQuery({
        enabled: false,
        queryKey: ['adminSearchActivities'],
        queryFn: () => searchAdminActivities(searchFilters),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const activitiesResp = responseData.items
            setActivities(activitiesResp)
            setTotalRecords(responseData.total)
            setSelectedActivities(initCheckboxes(activitiesResp))
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const deleteMutation = useMutation({
        mutationKey: ['deleteActivities'],
        mutationFn: (ids: any) => deleteActivities(ids),
        onSuccess: () => {
            setIsDeleting(false)
            setSelectedActivities({})
            activitiesQuery.refetch()
            Notificator.success('Los registros seleccionados se han eliminado correctamente')
        },
        onError: () => {
            setIsDeleting(false)
            Notificator.error('Ha ocurrido un error al intentar eliminar los registros seleccionados.')
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        if (searchFilterValue) {
            setSearchFilters({'q': `*${searchFilterValue}*`})
        } else {
            setSearchFilters({})
        }

        dispatch(appLoading({value: true, text: 'Cargando'}))
    }

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    useEffect(() => {
        activitiesQuery.refetch()
    }, [])

    useEffect(() => {
        activitiesQuery.refetch()
    }, [searchFilters])

    useEffect(() => {
        dispatch(appLoading({value: activitiesQuery.isLoading, text: 'Cargando'}))
    }, [activitiesQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: activitiesQuery.isRefetching, text: 'Cargando'}))
    }, [activitiesQuery.isRefetching])

    const headers: string[] = [
        'ACTIVIDAD',
        'IMAGEN',
        'DESCRIPCIÓN',
        'INSCRITOS',
        'MODALIDADES',
        'ACCIONES'
    ]

    // @ts-ignore
    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Listado de actividades</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>

                                {
                                    isAnyRowChecked() &&
                                    <>
                                        <Button
                                            variant={"secondary"}
                                            onClick={deleteMultiple}
                                            disabled={isDeleting}
                                            className={"mx-2"}
                                        >
                                            {!isDeleting && <span>Eliminar Seleccionadas</span>}
                                            {isDeleting && <span>Eliminando ...</span>}
                                        </Button>

                                        {/*<Button*/}
                                        {/*    className={"purple-btn mx-4"}*/}
                                        {/*    disabled={true}*/}
                                        {/*>*/}
                                        {/*    Exportar*/}
                                        {/*</Button>*/}
                                    </>
                                }

                                <Button
                                    className={"purple-btn"}
                                    onClick={() => navigate(ROUTE_PATHS.ADMIN_ACTIVITIES_ADD)}
                                >
                                    Añadir actividad
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row my-4"}>

                        {/*<Col>*/}
                        {/*    <Form.Group controlId={"initDateFilter"}>*/}
                        {/*        <Form.Label>*/}
                        {/*            Fecha inicio*/}
                        {/*        </Form.Label>*/}
                        {/*        <Form.Control*/}
                        {/*            type="date"*/}
                        {/*            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => setResidence(e.target.value)}*/}
                        {/*            required*/}
                        {/*        />*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        {/*<Col>*/}
                        {/*    <Form.Group controlId={"endDateFilter"}>*/}
                        {/*        <Form.Label>*/}
                        {/*            Fecha fin*/}
                        {/*        </Form.Label>*/}
                        {/*        <Form.Control*/}
                        {/*            type="date"*/}
                        {/*            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => setResidence(e.target.value)}*/}
                        {/*            required*/}
                        {/*        />*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}

                        <Col xs={12} sm={4}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => setSearchFilterValue(e.target.value)}
                                    onKeyDown={onEnterPressed}
                                />
                            </Form.Group>
                        </Col>
                    </div>
                    <br/>

                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            <th
                                style={{"width": "2%"}}
                            >
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={allSelected}
                                    onChange={checkAll}
                                />
                            </th>
                            {headers.map((header, key) => <th key={key}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {activities.length === 0 &&
                            <td colSpan={7} className={"px-4 py-4"}>No se han encontrado actividades.</td>}

                        {activities.map((item, key) => <tr key={key}>
                            <>
                                <td className={"align-middle"}>
                                    <input
                                        key={key}
                                        type="checkbox"
                                        checked={selectedActivities[item.id]}
                                        onChange={() => onSelectedCheckboxRowChange(item)}
                                    />
                                </td>
                                <td className={"align-middle"} width={"10%"}>
                                    <Link to={ROUTE_PATHS.ADMIN_ACTIVITIES_EDIT.replace(':id', item.id)}>
                                        {item.name}
                                    </Link>
                                </td>
                                <td className={"align-middle text-center"}>
                                    {
                                        item.image &&
                                        <>
                                            <a href={item.image} target={"_blank"}>
                                                <img src={item.image} width={45}/>
                                            </a>
                                        </>
                                    }
                                </td>
                                <td className={"align-middle"}>
                                    <div dangerouslySetInnerHTML={renderHtml(item.description)}></div>
                                </td>
                                <td className={"align-middle text-center"}>
                                    {item.totalInscriptions}
                                </td>
                                <td width={"40%"}>
                                    <ul className={"pb-0 mb-0"}>
                                        {
                                            item.sessions.map((session: any, key: number) => {
                                                return (
                                                    <li key={key}>
                                                        <Link
                                                            to={ROUTE_PATHS.ADMIN_MODALITY_EDIT.replace(':id', session.id)}>
                                                            {session.name}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </td>
                                <td className={"text-center align-middle"}>
                                    <Button
                                        className={"purple-btn btn-sm"}
                                        href={ROUTE_PATHS.ADMIN_ACTIVITIES_EDIT.replace(':id', item.id)}
                                    >
                                        Modificar
                                    </Button>
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <Pagination
                                    total={totalRecords}
                                    pageSize={pageSize}
                                    current={page}
                                    onChange={onPaginationPageChange}
                                />
                            }
                        </Col>
                    </Row>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
