export default class FilterBuilder
{
    static toQueryParams(params: string[]): string {
        return params
            .map((param: string) => `${param}=${encodeURIComponent(param)}`)
            .join("&");
    }

    static hashMapToQueryString(hashMap: any): string {
        const params: any = [];

        const values = Object.values(hashMap)
        const keys = Object.keys(hashMap)

        for(let i in keys){
            params.push(`${keys[i]}=${values[i]}`)
        }

        return params.join("&");
    }
}
