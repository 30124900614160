import React from "react";
import {DATA_SECURITY_DOC_URL} from "Config/Router/Routes";

interface Props {
    label?: string
}

export const AcceptNormativeLink: React.FC<Props> = (props) => {

    return (
        <>Acepto y me comprometo a cumplir de manera rigurosa las directrices, obligaciones y medidas preventivas al alumnado y las familias que se irán marcando y actualizando por las autoridades educativas.</>
    )

}