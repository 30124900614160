import {http} from "Services/Http/HttpClient";

export interface Cart {
    id: string;
    tutor: {
        id: string;
        name: string;
    };
    totalPrice: number;
    status: number;
    items: CartItem[]
}

export interface CartItemService {
    id: string;
    name: string;
    price: number;
}

export interface CartItem {
    id: string,
    child: {
        id: string;
        name: string;
        surnames: string;
    };
    activitySession: {
        id: string;
        name: string;
        image: string;
    };
    price: number;
    services: CartItemService[];
    days: Array<{
        id: string;
        name: string;
    }>;
    description: string;
    start: string;
    end: string;
    billable: boolean;
    numberOfDays: number;
    courseLevel: {
        id: string;
        name: string;
    },
    center: {
        id: string;
        name: string;
    },
    totalPrice: number,
    image: string | null,
    initDate: string,
    name: string,
    activity?: {
        id: string;
        name: string;
    },
    observations: string|null,
    discountAmount: number,
    totalPriceWithDiscount: number,
    discount?: {
        id: string,
        name: string,
        value: number,
    }
    extraDiscount?: {
        id: string,
        name: string,
        value: number,
        amount: number,
    }
}


export const getCart = async (): Promise<Cart> => {
    return await http.get('cart')
}
