import React from "react";
import {Col, Row} from "react-bootstrap";
import {Cart} from "Views/Shared/Navbar/Cart";
import {authCheck} from "Services/Security/AuthService";
import {UserService} from "Services/User/UserService";
import {AdminNavbar} from "Views/Shared/Navbar/AdminNavbar";
import {UserNavbar} from "Views/Shared/Navbar/UserNavbar";

export const Navbar: React.FC = () => {

    const isAuthenticated: boolean = authCheck()
    const userService = new UserService()

    return (
        <Row>
            <Col className={"px-0 template-red-bar"}>
                {isAuthenticated &&
                    <Row className={"col-12"}>
                        <Col xs={10} sm={11} md={11} lg={11}>
                            <Row className={"navbar-item-wrapper"}>
                                {userService.isRegularUser() && <UserNavbar/>}
                                {userService.isAdmin() && <AdminNavbar/>}
                            </Row>
                        </Col>
                        <Col xs={2} sm={1} md={1} lg={1}>
                            {userService.isRegularUser() && <Cart/>}
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    )
}
