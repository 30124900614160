export enum ChildMediaTypes
{
    DISEASE = 1,
    SPECIAL_EDUCATIONAL_NEEDS = 2,
    NEEDS_SPECIAL_ATTENTION_AT_ACTIVITY = 3,
    HAS_ALLERGIES_FOOD_INTOLERANCES_OR_SPECIAL_DIETS = 4,
    CONTROL_SPHINCTERS = 5,
    HAS_DISORDER = 6,

}
