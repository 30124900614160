import axios from "axios";
import {getAuth} from "../../Services/Security/AuthService";

export const uploadInscriptionReportFileForSepaGeneration = async (
    collectionDate: string,
    file: File
) => {
    let data = new FormData()
    data.append('file', file)
    data.append('collectionDate', collectionDate)

    const fhttp = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "multipart/form-data;",
            "Authorization": getAuth()
        }
    })

    return fhttp.post(`inscriptions/sepa-xml/admin`, data)
}