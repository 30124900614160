import React from 'react'
import 'Assets/css/App.css'

import Container from 'react-bootstrap/Container'
import {AppRoutes} from "Config/Router/Routes"
import {ReactQueryDevtools} from 'react-query/devtools'
import {BrowserRouter as Router} from "react-router-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import {Header} from "./Shared/Header/Header";
import {Footer} from "./Shared/Footer";
import {useAppSelector} from "../hooks";
import {LoadingOverlay} from "../Components/Loading/LoadingOverlay";
import ScrollToTop from "Hooks/Window/ScrollToTop";
import {Navbar} from "Views/Shared/Navbar";
import {MobileMenu} from "Views/Shared/Navbar/MobileMenu";
import { ImpersonationAlert } from './Shared/ImpersonationAlert'

export const App: React.FC = () => {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: true,
                refetchOnReconnect: true,
                retry: false,
                staleTime: 5 * 60 * 1000,
            },
        },
    })

    const appLoading = useAppSelector((state) => state.loading)
    const isMobileMenuOpen = useAppSelector((state) => state.isBurguerMenuOpen.isOpen)

    return (
        <Router>
            <ScrollToTop/>
            <QueryClientProvider client={queryClient}>

                {
                    appLoading.value &&
                    <LoadingOverlay text={appLoading.text}/>
                }

                <MobileMenu isOpen={isMobileMenuOpen} />

                <Container id={"main-container"} fluid={true}>
                    <ImpersonationAlert />
                    <Header/>
                    <Navbar/>
                </Container>

                <AppRoutes/>

                <Container fluid={true}>
                    <Footer/>
                </Container>

                {process.env.REACT_APP_ENV === 'dev' &&
                    <ReactQueryDevtools initialIsOpen={false}/>
                }

            </QueryClientProvider>
        </Router>
    );
}
