import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useQuery} from "react-query";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {getInscriptionStatuses} from "Api/Query/InscriptionQuery";
import {SelectOption} from "Model/Select/SelectOption";
import {Form} from "react-bootstrap";
import {INSCRIPTION_REJECTED_STATUS_ID} from "Model/Inscription/Inscription";

interface Props {
    value: SelectOption | any,
    onChange: (newValue: any, rejectedReason: string) => void,
    classes?: string,
    isAdmin?: boolean,
    defaultRejectedReason?: string
}

export const InscriptionStatusesSelect: React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(null)
    const [options, setOptions] = useState<any>([])
    const [rejectedReason, setRejectedReason] = useState<string>(props.defaultRejectedReason ?? '')
    const [isAdministrator, setIsAdministrator] = useState<boolean>(props.isAdmin ?? false)

    const {
        isLoading,
        isError,
        data,
        error,
        refetch
    } = useQuery(['getInscriptionStatuses'], () => getInscriptionStatuses())

    useEffect(() => {
        if (!data) return

        // @ts-ignore
        const response: ApiListResponse = data?.data._result

        const collection = new SelectOptionCollection()
        Object.keys(response).map((id: any) => {
            collection.addPrimitive({
                label: response[id],
                value: id
            })
        })

        setOptions(collection.toPrimitiveOptions())
    }, [data])

    useEffect(() => {
        if (!options.length) {
            return
        }

        const values = options.filter(option => parseInt(option.value) === parseInt(props.value?.value))
        const value = values[0] ?? null
        setValue(value)

        if(value?.value !== INSCRIPTION_REJECTED_STATUS_ID.toString()){
            setRejectedReason('')
            props.onChange(value, '')
        }

    }, [props.value, options])

    useEffect(() => {
        setIsAdministrator(props.isAdmin ?? false)
    }, [props.isAdmin]);

    useEffect(() => {
        if(props.defaultRejectedReason){
            setRejectedReason(props.defaultRejectedReason)
        }
    }, [props.defaultRejectedReason]);

    return (
        <>
            <Select
                isLoading={isLoading}
                options={options}
                placeholder={""}
                noOptionsMessage={() => "No hay resultados"}
                isClearable={true}
                loadingMessage={() => "Cargando ..."}
                onChange={(value: any) => props.onChange(value, rejectedReason)}
                className={props.classes ?? ''}
                value={value}
            />

            {
                isAdministrator &&
                value?.value === INSCRIPTION_REJECTED_STATUS_ID.toString() &&
                <Form.Group controlId="inscriptionStatus" className={"mt-4"}>
                    <Form.Label className={"fw-bold"}>Motivo de rechazo:</Form.Label>
                    <textarea
                        className="mt-2 form-control"
                        rows={5}
                        onChange={(e: any) => {
                            setRejectedReason(e.target.value)
                            props.onChange(value, e.target.value)
                        }}
                        value={rejectedReason}
                    ></textarea>
                </Form.Group>
            }

        </>
    )
}
