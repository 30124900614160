import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {isBurguerMenuOpen} from "Store/burguerMenuSlice";
import {useAppDispatch} from "hooks";
import {UserService} from "Services/User/UserService";
import {ADMIN_MENU} from "Config/Menu/AdminMenu";
import {useNavigate} from "react-router-dom";
import {removeAuthToken} from "Services/Security/AuthService";
import {Notificator} from "Services/Notificator/Notificator";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {USER_MENU} from "Config/Menu/UserMenu";

interface Props {
    isOpen: boolean
}

export const MobileMenu: React.FC<Props> = (props) => {

    const userService = new UserService()
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const close = (): void => {
        dispatch(isBurguerMenuOpen({isOpen: false}))
    }

    const navItems = userService.isAdmin() ? ADMIN_MENU : USER_MENU

    const goToLink = (path: string) => {
        if (path === '#') return

        close()
        navigate(path)
    }

    const logout = () : void => {
        close()
        removeAuthToken()
        Notificator.success('Te has desconectado correctamente.')
        navigate(ROUTE_PATHS.LOGIN)
    }

    return (
        <div
            id={"mobileMenu"}
            className={(props.isOpen ? "show" : "")}
        >
            <FontAwesomeIcon
                onClick={close}
                className={"close"}
                icon={faTimes}
            />
            {
                navItems.map((navItem, key) => {
                    return (
                        <div key={key}>
                            <a
                                className="menu-item"
                                onClick={() => goToLink(navItem.path)}
                            >{navItem.title}</a>

                            {
                                navItem.children?.map((childItem, ckey) => {
                                    return (
                                        <a
                                            key={ckey}
                                            className="menu-item menu-subitem"
                                            onClick={() => goToLink(childItem.path)}
                                        >{childItem.title}</a>
                                    )
                                })
                            }

                        </div>
                    )
                })
            }

            {
                userService.isAuthenticated() &&
                <>
                    <hr className={"logoutHr"} />
                    <a
                        key={999}
                        className="menu-item"
                        onClick={() => logout()}
                    >
                        Desconectarse</a>
                </>

            }

        </div>
    )

}
