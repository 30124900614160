import {useMutation, useQuery} from "react-query";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {LoadingSpinner} from "Components/Loading/LoadingSpinner";
import {checkPasswordRecoveryRequest} from "Api/Query/SecurityQuery";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {updatePasswordFromRecoveryRequest} from "Api/Mutation/SecurityMutation";

export const PasswordRecoveryDetail: React.FC = () => {

    const [found, setFound] = useState<boolean>()
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [confirmedPassword, setConfirmedPassword] = useState<string>('')

    const {token} = useParams()

    useQuery({
        queryKey: ['checkPasswordRecoveryRequest', token],
        queryFn: () => checkPasswordRecoveryRequest(token ?? ''),
        onSuccess: (response: any) => {
            setFound(true)
        },
        onError: (error: any) => {
            setLoading(false)
            setFound(false)
        }
    })

    const updatePasswordMutation = useMutation({
        mutationFn: (body: any) => updatePasswordFromRecoveryRequest(body.token, body.password),
        onSuccess: () => {
            setSuccess(true)
            setSubmitted(false)
            setLoading(false)
        },
        onError: () => {
            setSuccess(false)
            setSubmitted(false)
            setLoading(false)
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        handleSubmit()
    }

    const handleSubmit = () => {
        if (!password || !confirmedPassword) {
            return
        }

        if(isPasswordInvalid() || isConfirmedPasswordInvalid()){
            return false
        }

        setSubmitted(true)
        setLoading(true)

        updatePasswordMutation.mutate({
            token: token,
            password: confirmedPassword
        })
    }

    const isPasswordInvalid = (): boolean => {
        if (submitted && !password.length) {
            return true
        }

        if (confirmedPassword && password !== confirmedPassword) {
            return true
        }

        if (password.length && password.length < 5 || password.length > 20) {
            return true
        }

        return false
    }

    const isConfirmedPasswordInvalid = (): boolean => {
        if (submitted && !confirmedPassword.length) {
            return true
        }

        if (confirmedPassword && password !== confirmedPassword) {
            return true
        }

        return false
    }

    return (
        <DefaultContainer>
            <Col id={"signup"} md={6} className={"mx-md-auto"}>
                <h1>Recuperar contraseña</h1>

                {typeof found === 'undefined' && <>Comprobando ...</>}
                {false === found &&
                    <Alert
                        variant={"danger"}
                        className={"mt-4"}
                    >
                        El enlace de recuperación de contraseña no existe o ha caducado.
                    </Alert>
                }

                {
                    found === true &&
                    <Row>
                        {!success &&
                            <>
                                <Form.Group
                                    controlId="email"
                                    className="my-3"
                                >
                                    <Form.Label>
                                        Nueva contraseña <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        onKeyDown={onEnterPressed}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                        isInvalid={isPasswordInvalid()}
                                    />
                                    {password !== confirmedPassword && confirmedPassword.length > 0 &&
                                        <p className={"text-danger small mt-2"}>Las contraseñas no coinciden</p>}
                                    {password && (password.length < 5 || password.length > 20) &&
                                        <p className={"text-danger small mt-2"}>La contraseña debe tener entre 5 y 20
                                            caracteres</p>}
                                </Form.Group>
                                <Form.Group
                                    controlId="email"
                                    className="my-3"
                                >
                                    <Form.Label>
                                        Repite la contraseña <FormLabelRequiredAsterisk/>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        onKeyDown={onEnterPressed}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmedPassword(e.target.value)}
                                        isInvalid={isConfirmedPasswordInvalid()}
                                    />
                                    {password !== confirmedPassword && confirmedPassword.length > 0 &&
                                        <p className={"text-danger small mt-2"}>Las contraseñas no coinciden</p>}
                                </Form.Group>
                            </>
                        }

                        {
                            success &&
                            <Col>
                                <Alert
                                    variant={"success"}
                                    className={"mt-4"}
                                >
                                    Su contraseña se ha modificado correctamente.
                                </Alert>
                            </Col>
                        }

                        {
                            !success &&
                            <Col className={"col-12 d-flex justify-content-end"}>
                                <Button
                                    id={"sendSignupFormBtn"}
                                    variant={""}
                                    className={"purple-btn"}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading && <LoadingSpinner/>}
                                    <span>{loading && " "}Modificar mi contraseña</span>
                                </Button>
                            </Col>
                        }

                    </Row>
                }

            </Col>
        </DefaultContainer>
    )

}
