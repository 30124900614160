import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {CookieService} from "Services/Http/CookieService";
import {IMPERSONATION_COOKIE_NAME, ImpersonationService} from "../../Services/User/ImpersonationService";
import {useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "../../Config/Router/Routes";


export const ImpersonationAlert: React.FC = () => {

    const [visible, setVisible] = useState<boolean>(false)

    const impersonationService = new ImpersonationService()

    const getImpersonatedUserInfo = () : any => {
        return impersonationService.getImpersonationUser()
    }

    const onExitImpersonation = () : void => {
        impersonationService.exitImpersonation()
    }

    useEffect(() => {
        const exists = getImpersonatedUserInfo() && Object.keys(getImpersonatedUserInfo()).length > 0

        if(exists !== visible){
            setVisible(exists)
        }
    }, [getImpersonatedUserInfo()]);

    if(!visible){
        return <></>
    }

    return (
         visible &&
        <Row>
            <Col
                className={"pt-1"}
                style={{"backgroundColor": "red", "height":"34px", "color":"white"}}
            >
                <p className={"text-center"}>
                    <span className={"d-inline-block"}>Estás impersonado como el usuario <strong>{getImpersonatedUserInfo().name}</strong>. Cuyo tutor es <strong>{getImpersonatedUserInfo().tutor.name ?? 'Ninguno'}</strong>.</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                        onClick={onExitImpersonation}
                        className={"d-inline-block text-uppercase pointer"}>
                        <strong>
                            ➙ Volver a mi usuario.
                        </strong>
                    </span>
                </p>
            </Col>
        </Row>
    )

}