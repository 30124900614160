import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Badge, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useMutation, useQuery} from "react-query";
import {getPreorderInscriptions} from "Api/Query/InscriptionOrderQuery";
import {Notificator} from "Services/Notificator/Notificator";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {CartItemSpecifications} from "Views/Cart/CartItemSpecifications";
import {UserService} from "Services/User/UserService";
import {TutorHasNotEnteredPersonalInfo} from "Components/Tutor/TutorHasNotEnteredPersonalInfo";
import {Link} from "react-router-dom";
import {unsubscribeInscription} from "Api/Mutation/UnsubscribeInscriptionMutation";

export const RequestItemsList: React.FC = () => {

    const dispatch = useDispatch()
    const [currentInscriptions, setCurrentInscriptions] = useState<any>([])
    const [pastInscriptions, setPastInscriptions] = useState<any>([])

    const userService = new UserService()
    const tutorId = userService.getTutor()

    const listPreorderInscriptionsQuery = useQuery({
        enabled: false,
        queryKey: ['listPreorderInscriptions'],
        queryFn: () => getPreorderInscriptions(),
        onSuccess: (response: any) => {
            setCurrentInscriptions(response.data._result?.current ?? [])
            setPastInscriptions(response.data._result?.past ?? [])
        },
        onError: () => {
            setCurrentInscriptions([])
            setPastInscriptions([])
        }
    })

    const unsubscribeMutation = useMutation({
        mutationKey: ['unsubscribeMutation'],
        mutationFn: (inscriptionOrderActivityId: string) => unsubscribeInscription(inscriptionOrderActivityId),
        onSuccess: () => {
            listPreorderInscriptionsQuery.refetch()
            Notificator.success('Has cancelado la inscripción correctamente.')
        },
        onError: (error: any) => {
            if(error.response.status === 403){
                Notificator.error(error.response.data._result.error)
            }else{
                Notificator.error('Se ha producido un error al cancelar la inscripción.')
            }
        }
    })

    const unsubscribe = (inscriptionOrderActivityId: string) => {
        const message = "¿Deseas cancelar la inscripción #" + inscriptionOrderActivityId + "?"
        if (!window.confirm(message)) {
            return
        }

        unsubscribeMutation.mutate(inscriptionOrderActivityId)
    }

    useEffect(() => {
        dispatch(appLoading({value: listPreorderInscriptionsQuery.isLoading, text: 'Cargando'}))
    }, [listPreorderInscriptionsQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: unsubscribeMutation.isLoading, text: 'Cargando'}))
    }, [unsubscribeMutation.isLoading])

    useEffect(() => {
        listPreorderInscriptionsQuery.refetch()
    }, []);

    const currentTable = () => {
        return (
            <>
                <h4 className={"purple mb-4"}>Vigentes</h4>
                <table className={"table table-bordered cartTable"}>
                    <thead key={"head_1"}>
                    <tr>
                        <th key={"h1"}>SERVICIO</th>
                        <th key={"h2"}>PRECIO</th>
                        <th key={"h3"}>PRECIO FINAL</th>
                        <th key={"h4"}>ESTADO</th>
                        <th key={"h5"}></th>
                    </tr>
                    </thead>
                    <tbody key={"body_1"}>

                    {
                        currentInscriptions.length > 0 &&
                        currentInscriptions.map((inscription, zkey) => {
                                return (
                                    <>
                                        {
                                            inscription.inscriptionOrderActivities.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td width={"45%"}>
                                                            <Row className={"d-flex  align-items-center"}>
                                                                <Col sm={2}>
                                                                    <img
                                                                        style={{"maxWidth": "100%"}}
                                                                        src={item.activity.image}
                                                                        title={item.activity.name}
                                                                        alt={item.activity.name}/>
                                                                </Col>
                                                                <Col sm={10}>

                                                                    <h5 style={{
                                                                        "color": "#A80048",
                                                                        "fontWeight": "bold"
                                                                    }}>Solicitud #{inscription.id}</h5>
                                                                    <CartItemSpecifications
                                                                        item={item}
                                                                        inscription={inscription}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        <td className={"align-middle"}>
                                                            {
                                                                item.totalPrice &&
                                                                <MoneyAmount amount={item.totalPrice}/>
                                                            }
                                                        </td>
                                                        <td className={"align-middle"}>
                                                            {
                                                                item.totalPriceWithDiscount &&
                                                                <MoneyAmount amount={item.totalPriceWithDiscount}/>
                                                            }
                                                        </td>
                                                        <td className={"align-middle text-center"}>
                                                            <Badge
                                                                bg={""}
                                                                className={"text-uppercase bg-" + item.status.color}>
                                                                {item.status.name}
                                                            </Badge>
                                                        </td>
                                                        <td className={"text-center align-middle"}>
                                                            {item.status.id > 0 &&
                                                                <Link
                                                                    to={"#"}
                                                                    className={"text-danger"}
                                                                    onClick={() => unsubscribe(item.id)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        style={{"marginRight": "5px"}}
                                                                        icon={faTimes}
                                                                    />
                                                                    <span>Cancelar inscripción</span>
                                                                </Link>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        )
                    }

                    {
                        currentInscriptions && currentInscriptions.length === 0 &&
                        <tr>
                            <td colSpan={5}>No tienes ninguna inscripción vigente.</td>
                        </tr>
                    }

                    </tbody>
                </table>
            </>
        )
    }

    const previousTable = () => {
        return (
            <>
                <h4 className={"purple my-4"}>Pasadas</h4>
                <table className={"table table-bordered cartTable"}>
                    <thead>
                    <tr>
                        <th>SERVICIO</th>
                        <th>PRECIO</th>
                        <th>PRECIO FINAL</th>
                        <th>ESTADO</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        pastInscriptions.length > 0 &&
                        pastInscriptions.map((inscription, zkey) => {
                                return (
                                    <>
                                        {
                                            inscription.inscriptionOrderActivities.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td width={"50%"}>
                                                            <Row className={"d-flex  align-items-center"}>
                                                                <Col sm={2}>
                                                                    <img
                                                                        style={{"maxWidth": "100%"}}
                                                                        src={item.activity.image}
                                                                        title={item.activity.name}
                                                                        alt={item.activity.name}/>
                                                                </Col>
                                                                <Col sm={10}>
                                                                    <h5 style={{
                                                                        "color": "#A80048",
                                                                        "fontWeight": "bold"
                                                                    }}>Solicitud #{inscription.id}</h5>
                                                                    <CartItemSpecifications
                                                                        item={item}
                                                                        inscription={inscription}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        <td className={"align-middle"}>
                                                            {
                                                                item.totalPrice &&
                                                                <MoneyAmount amount={item.totalPrice}/>
                                                            }
                                                        </td>
                                                        <td className={"align-middle"}>
                                                            {
                                                                item.totalPriceWithDiscount &&
                                                                <MoneyAmount amount={item.totalPriceWithDiscount}/>
                                                            }
                                                        </td>
                                                        <td className={"align-middle text-center"}>
                                                            <Badge
                                                                bg={""}
                                                                className={"text-uppercase bg-" + item.status.color}>
                                                                {item.status.name}
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        )
                    }

                    {
                        pastInscriptions && pastInscriptions.length === 0 &&
                        <tr>
                            <td colSpan={4}>No tienes ninguna inscripción pasada.</td>
                        </tr>
                    }

                    </tbody>
                </table>
            </>
        )
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"cart"}>
                    <h1 className={"pb-4"}>Listado de inscripciones</h1>

                    {!tutorId && <TutorHasNotEnteredPersonalInfo showAlert={true} showButton={true}/>}

                    {tutorId && currentTable()}
                    <br/>
                    {tutorId && previousTable()}

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
