import React from "react";
import {useNavigate} from "react-router-dom";
import {faShoppingBag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useAppSelector} from "hooks";

export const Cart : React.FC = () => {

    const navigate = useNavigate()
    const totalCartItems = useAppSelector((state) => state.cart.total)

    return (
        <div
            onClick={() => navigate(ROUTE_PATHS.SHOPPING_CART)}
            className={"shoppingCartIcon pointer"}>
            <FontAwesomeIcon
                icon={faShoppingBag}
                className={"text-white"}
            />
            <div className={"shoppingCartIconCircle"}>{totalCartItems}</div>
        </div>
    )

}
