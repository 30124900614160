import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {ActivitySession} from "Model/Activity/ActivitySession";
import {MultiValue} from "react-select";
import {SelectOptionType} from "Model/Select/SelectOption";
import {TileDisabledFunc, Value} from "react-calendar/dist/cjs/shared/types";
import {getFirstDayForNextWeek} from "Services/ActivitySession/ActivitySessionCalendar";
import { Calendar } from "react-multi-date-picker"
import spanish_es from "Config/Calendar/spanish_es"
import spanish_calendar from "Config/Calendar/spanish_calendar"
import DateObject from "react-date-object";

interface Props {
    session: ActivitySession | null,
    selectedDays: MultiValue<SelectOptionType> | null,
    onChange: (selectedDates: DateObject[] | null) => void,
    initDate: string,
    endDate: string,
    numberOfDays: number
}

export const SelectDatesCalendar: React.FC<Props> = (props) => {

    const [activityInitDate, setActivityInitDate] = useState<Date>(new Date(props.initDate))
    const [activityEndDate, setActivityEndDate] = useState<Date>(new Date(props.endDate))
    const [minDate, setMinDate] = useState<Date | null>(null)

    const calcMinDate = () => {
        const firstDayForNextWeek = getFirstDayForNextWeek()
        const initDate = (activityInitDate < firstDayForNextWeek) ? getFirstDayForNextWeek() : activityInitDate

        setMinDate(initDate)
    }

    useEffect(() => {
        calcMinDate()
    }, [])

    return (
        <>
            <Form.Label>
                Indica los días <FormLabelRequiredAsterisk/>
            </Form.Label>

            {
                minDate &&
                <>
                    <Calendar
                        calendar={spanish_calendar}
                        locale={spanish_es}
                        numberOfMonths={3}
                        disableMonthPicker
                        disableYearPicker
                        minDate={minDate}
                        maxDate={activityEndDate}
                        multiple
                        onChange={props.onChange}
                    />
                </>
            }
        </>
    )
}
