import React, {useEffect, useState} from "react";
import {SelectOption, SelectOptionType} from "Model/Select/SelectOption";
import {DropzoneFile} from "Components/FileUpload/FileUpload";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Link} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import {CenterSelect} from "Components/Center/CenterSelect";
import {FormErrorText} from "Components/Form/FormErrorText";
import {CourseLevelSelect} from "Components/CourseLevel/CourseLevelSelect";
import {NifTypeSelect} from "Components/Nif/NifTypeSelect";
import {ChildFormAdditionalInfoCheck} from "Components/Child/ChildFormAdditionalInfoCheck";
import {Notificator} from "Services/Notificator/Notificator";
import {IdName} from "Model/Shared/IdName";
import {PrivacyPolicyLink} from "../../Components/Link/PrivacyPolicyLink";
import {LegalWarningLink} from "../../Components/Link/LegalWarningLink";
import Calendar from "react-calendar";
import {DataProtectionLink} from "../../Components/Link/DataProtectionLink";
import {AcceptNormativeLink} from "../../Components/Link/AcceptNormativeLink";
import {RequestKidsInfoCheck} from "../../Components/Link/RequestKidsInfoCheck";

export interface ChildFormData {
    center: {
        id: string,
        name: string
    }
    name: string
    surnames: string
    courseLevel: {
        id: string,
        name: string
    },
    birthDate: string,
    canLeaveWithoutTutor: boolean
    hasSpecialNeeds: boolean
    hasSpecialCare: boolean
    hasDisease: boolean
    hasAnyAllergyOrSpecialDiet: boolean
    hasSphincterControl: boolean
    hasAnySyndrome: boolean
    canEatPork: boolean
    hasSocialServices: boolean
    canBeFilmed: boolean
    nif?: string
    documentType?: IdName | null,
    childDiseases?: string
    specialCareDescription?: string
    sphincterControlDescription?: string
    specialNeedsDescription?: string
    allergyOrSpecialDietDescription?: string
    anySyndromeDescription?: string
    diseaseFiles?: DropzoneFile[]
    allergensFiles?: DropzoneFile[]
    disorderFiles?: DropzoneFile[]
    reqSpecialAttentionFiles?: DropzoneFile[]
    specialEducationFiles?: DropzoneFile[]
    sphinctersFiles?: DropzoneFile[]
}

interface Props {
    tutorId: string,
    submitted: boolean
    onSubmit: (formData: ChildFormData) => void
    defaultValues?: ChildFormData
    loading: boolean
}

export const ChildForm: React.FC<Props> = (props) => {

    const MAX_DISEASE_FILES = 5

    const [center, setCenter] = useState<SelectOptionType | null>(null)
    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [nifType, setNifType] = useState<SelectOptionType | null>(null)
    const [nif, setNif] = useState<string>('')
    const [course, setCourse] = useState<SelectOptionType | null>(null)
    const [birthdate, setBirthdate] = useState<string>('')
    const [goAlone, setGoAlone] = useState<boolean>(false)

    const [disease, setDisease] = useState<boolean>(false)
    const [diseaseComments, setDiseaseComments] = useState<string>('')
    const [diseaseFiles, setDiseaseFiles] = useState<DropzoneFile[]>([])

    const [reqSpecialAttention, setReqSpecialAttention] = useState<boolean>(false)
    const [reqSpecialAttentionComments, setReqSpecialAttentionComments] = useState<string>('')
    const [reqSpecialAttentionFiles, setReqSpecialAttentionFiles] = useState<DropzoneFile[]>([])

    const [sphincters, setSphincters] = useState<boolean>(true)
    const [sphinctersComments, setSphinctersComments] = useState<string>('')
    const [sphinctersFiles, setSphinctersFiles] = useState<DropzoneFile[]>([])

    const [pig, setPig] = useState<boolean>(true)

    const [specialEducation, setSpecialEducation] = useState<boolean>(false)
    const [specialEducationComments, setSpecialEducationComments] = useState<string>('')
    const [specialEducationFiles, setSpecialEducationFiles] = useState<DropzoneFile[]>([])

    const [allergens, setAllergens] = useState<boolean>(false)
    const [allergensComments, setAllergensComments] = useState<string>('')
    const [allergensFiles, setAllergensFiles] = useState<DropzoneFile[]>([])

    const [disorder, setDisorder] = useState<boolean>(false)
    const [disorderComments, setDisorderComments] = useState<string>('')
    const [disorderFiles, setDisorderFiles] = useState<DropzoneFile[]>([])

    const [termValues, setTermValues] = useState<boolean[]>([false, false, false, false, false, false])

    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({})

    const [submitted, setSubmitted] = useState<boolean>(false)

    const terms: React.ReactNode[] = [
        <span><FormLabelRequiredAsterisk/> <AcceptNormativeLink /></span>,
        <span><FormLabelRequiredAsterisk/> Quedo enterado del <LegalWarningLink/> y acepto su normativa junto a la <DataProtectionLink label={"Protección de datos"}/>.</span>,
        <span><FormLabelRequiredAsterisk/> Al enviar, acepto la <PrivacyPolicyLink/>.</span>,
        <span><FormLabelRequiredAsterisk/> A los efectos de lo establecido en el Reglamento Europeo 2016/679 de Protección de Datos Personales (RGPD), el Ayuntamiento de San Sebastián de los Reyes garantiza la confidencialidad de los datos personales de que dispone. Le comunicamos que los datos facilitados forman parte de una base de datos gestionada por IPR Prevención para la gestión de los servicios contratados. Los datos serán mantenidos durante la relación contractual y no serán cedidos a otras entidades sin su permiso expreso. Si desea ejercitar sus derechos de acceso, rectificación, cancelación, oposición y portabilidad de los datos de carácter personal, puede hacerlo a través de la dirección info@sanseconcilia.org en los términos establecidos en el RGPD. Puede ampliar información sobre sus derechos en cuanto a la confidencialidad de los datos personales a través de la Agencia Española de Protección de Datos.</span>,
        <span>Autorizo que se pueda fotografiar, filmar y/o grabar a mi hija/o con objetivos pedagógicos y/o fines informativos municipales que, en ningún caso, podrán atentar contra su imagen y dignidad personal.</span>,
        <span><FormLabelRequiredAsterisk/> <RequestKidsInfoCheck /></span>
    ]

    const termValueChanged = (key: number) => {
        setTermValues({
            ...termValues,
            [key]: !termValues[key]
        })
    }

    const requiredTermKeys = [0, 1, 2, 3, 5]

    const validate = (): boolean => {
        const requiredFields = {
            "center": center,
            "name": name,
            "surname": surname,
            "course": course,
            "birthdate": birthdate
        }

        const emptyError = 'El campo es obligatorio.'
        let errors: { [key: string]: string } = {}

        // input fields validation
        for (const [key, value] of Object.entries(requiredFields)) {
            if (!value) {
                errors[key] = emptyError
            }
        }

        // terms validation
        for (let i in requiredTermKeys) {
            const key = requiredTermKeys[i]
            if (!termValues[key]) {
                errors['terms'] = 'Debes aceptar los condiciones del formulario.'
                break
            }
        }

        // files
        const filesToValidate = [
            'diseaseFiles', 'allergensFiles', 'disorderFiles', 'reqSpecialAttentionFiles',
            'specialEducationFiles', 'sphinctersFiles'
        ]

        const checkedMap: any = {
            diseaseFiles: disease,
            allergensFiles: allergens,
            disorderFiles: disorder,
            reqSpecialAttentionFiles: reqSpecialAttention,
            specialEducationFiles: specialEducation,
            sphinctersFiles: sphincters
        }
        const filesMap: any = {
            diseaseFiles: diseaseFiles,
            allergensFiles: allergensFiles,
            disorderFiles: disorderFiles,
            reqSpecialAttentionFiles: reqSpecialAttentionFiles,
            specialEducationFiles: specialEducationFiles,
            sphinctersFiles: sphinctersFiles
        }
        const filesCommentsMap: any = {
            diseaseFiles: diseaseComments,
            allergensFiles: allergensComments,
            disorderFiles: disorderComments,
            reqSpecialAttentionFiles: reqSpecialAttentionComments,
            specialEducationFiles: specialEducationComments,
            sphinctersFiles: sphinctersComments
        }

        filesToValidate.map((fileToValidate: string) => {
            const isChecked: boolean = checkedMap[fileToValidate]
            const expectedCheckValue = fileToValidate !== 'sphinctersFiles'

            if (isChecked === expectedCheckValue) {
                let totalFileKeys = Object.keys(filesMap[fileToValidate]).length

                // check files
                if (totalFileKeys) {
                    if (totalFileKeys > MAX_DISEASE_FILES) {
                        errors[fileToValidate] = `No puedes subir más de ${MAX_DISEASE_FILES} archivos.`
                    }
                } else {
                    errors[fileToValidate] = `Es obligatorio adjuntar al menos un archivo.`
                }

                // check comments
                if(!filesCommentsMap[fileToValidate]){
                    const errorKey = fileToValidate + 'Comments'
                    errors[errorKey] = 'El comentario es obligatorio.'
                }
            }
        })

        setValidationErrors(errors)

        if (Object.keys(errors).length) {
            let notificationMessage = 'Hay campos obligatorios sin completar (marcados en rojo).'
            Notificator.error(notificationMessage, 'Error')
            return false
        }

        return true
    }

    const handleSubmit = () => {
        if (!submitted) {
            setSubmitted(true)
        }

        if (!validate()) {
            return
        }

        let formData: ChildFormData = {
            center: {
                id: center?.value ?? '',
                name: center?.label ?? ''
            },
            name: name,
            surnames: surname,
            courseLevel: {
                id: course?.value ?? '',
                name: course?.label ?? ''
            },
            birthDate: birthdate,
            canLeaveWithoutTutor: goAlone,
            hasSpecialNeeds: specialEducation,
            hasSpecialCare: reqSpecialAttention,
            hasAnyAllergyOrSpecialDiet: allergens,
            hasSphincterControl: sphincters,
            hasAnySyndrome: disorder,
            hasDisease: disease,
            canEatPork: pig,
            hasSocialServices: false,
            canBeFilmed: termValues[4]
        }

        if (nif) {
            formData = {...formData, nif: nif}
            formData = {...formData, documentType: nifType ? {id: nifType.value, name: nifType.label} : null}
        }

        // ¿Padece alguna enfermedad?
        if (diseaseComments) {
            formData = {...formData, childDiseases: diseaseComments}
        }

        // ¿Requiere de alguna atención especial durante las actividades?
        if (reqSpecialAttention) {
            formData = {...formData, specialCareDescription: reqSpecialAttentionComments}
        }

        // ¿Controla esfinteres?
        if (!sphincters) {
            formData = {...formData, sphincterControlDescription: sphinctersComments}
        }

        // ¿Tiene necesidades educativas especiales?
        if (specialEducation) {
            formData = {...formData, specialNeedsDescription: specialEducationComments}
        }

        // ¿Tiene alergias, intolerancias alimentarias o dietas especiales?
        if (allergens) {
            formData = {...formData, allergyOrSpecialDietDescription: allergensComments}
        }

        // ¿Padece algún síndrome o trastorno?
        if (disorder) {
            formData = {...formData, anySyndromeDescription: disorderComments}
        }

        if (diseaseFiles) {
            formData = {...formData, diseaseFiles: diseaseFiles}
        }

        if (allergensFiles) {
            formData = {...formData, allergensFiles: allergensFiles}
        }

        if (disorderFiles) {
            formData = {...formData, disorderFiles: disorderFiles}
        }

        if (reqSpecialAttentionFiles) {
            formData = {...formData, reqSpecialAttentionFiles: reqSpecialAttentionFiles}
        }

        if (specialEducationFiles) {
            formData = {...formData, specialEducationFiles: specialEducationFiles}
        }

        if (sphinctersFiles) {
            formData = {...formData, sphinctersFiles: sphinctersFiles}
        }

        props.onSubmit(formData)
    }

    useEffect(() => {
        if (!props.defaultValues || !props.defaultValues?.name.length) {
            return
        }

        setName(props.defaultValues?.name)
        setSurname(props.defaultValues?.surnames)
        setBirthdate(props.defaultValues?.birthDate)

        const centerOption = new SelectOption(props.defaultValues?.center?.name, props.defaultValues?.center?.id)
        setCenter(centerOption)

        const courseLevel = new SelectOption(props.defaultValues?.courseLevel?.name, props.defaultValues?.courseLevel?.id)
        setCourse(courseLevel)

        if (props.defaultValues?.nif) {
            setNif(props.defaultValues?.nif)
        }

        if (props.defaultValues?.documentType) {
            setNifType(
                new SelectOption(props.defaultValues?.documentType?.name, props.defaultValues?.documentType?.id)
            )
        }

        setGoAlone(props.defaultValues?.canLeaveWithoutTutor)
        setSpecialEducation(props.defaultValues?.hasSpecialNeeds)
        setReqSpecialAttention(props.defaultValues?.hasSpecialCare)
        setAllergens(props.defaultValues?.hasAnyAllergyOrSpecialDiet)
        setSphincters(props.defaultValues?.hasSphincterControl)
        setDisorder(props.defaultValues?.hasAnySyndrome)
        setPig(props.defaultValues?.canEatPork)

        setDisease(props.defaultValues?.hasDisease)
        setDiseaseComments(props.defaultValues?.childDiseases ?? '')
        setSpecialEducationComments(props.defaultValues?.specialNeedsDescription ?? '')
        setReqSpecialAttentionComments(props.defaultValues?.specialCareDescription ?? '')
        setAllergensComments(props.defaultValues?.allergyOrSpecialDietDescription ?? '')
        setSphinctersComments(props.defaultValues?.sphincterControlDescription ?? '')
        setDisorderComments(props.defaultValues?.anySyndromeDescription ?? '')

        setDiseaseFiles(props.defaultValues?.diseaseFiles ?? [])
        setSpecialEducationFiles(props.defaultValues?.specialEducationFiles ?? [])
        setAllergensFiles(props.defaultValues?.allergensFiles ?? [])
        setSpecialEducationFiles(props.defaultValues?.specialEducationFiles ?? [])
        setReqSpecialAttentionFiles(props.defaultValues?.reqSpecialAttentionFiles ?? [])
        setDisorderFiles(props.defaultValues?.disorderFiles ?? [])
        setSphinctersFiles(props.defaultValues?.sphinctersFiles ?? [])

        const canBeFilmed = props.defaultValues?.canBeFilmed ?? false
        setTermValues([true, true, true, true, canBeFilmed, true])

        // hasSocialServices: childResponse.hasSocialServices,

    }, [props.defaultValues]);

    const getMinYearForBirthday = () => {
        const currentDate = new Date();

        // Subtract 14 years from the current date
        const pastDate = new Date();
        pastDate.setFullYear(currentDate.getFullYear() - 14);
        pastDate.setMonth(11)
        pastDate.setDate(31)
        return pastDate
    }

    const getMaxYearForBirthday = () => {
        const currentDate = new Date();

        // Subtract 2 years from the current date
        const pastDate = new Date();
        pastDate.setFullYear(currentDate.getFullYear() - 3);
        pastDate.setMonth(11)
        pastDate.setDate(31)
        return pastDate
    }

    const formatCalendarDate = (date: any): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <>
            <p>
                <small className={"purple fw-bold"}>Los datos marcados con * son obligatorios.</small>
            </p>
            <br/>
            <h3 className={"purple mb-4"}>Datos del hijo/a</h3>

            <Form className={"row"}>
                <Form.Group controlId={"center"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Centro Educativo del Hijo/a
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>

                    <CenterSelect
                        value={center}
                        onChange={(option) => setCenter(option)}
                        classes={validationErrors.center ? "is-invalid" : ""}
                    />
                    <FormErrorText error={validationErrors.center}/>
                </Form.Group>
                <Form.Group controlId={"course"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Curso del Hijo/a
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <CourseLevelSelect
                        value={course}
                        onChange={(option) => setCourse(option)}
                        classes={validationErrors.course ? "is-invalid" : ""}
                    />
                    <FormErrorText error={validationErrors.course}/>
                </Form.Group>
                <Form.Group controlId={"name"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Nombre del Hijo/a
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        required
                        isInvalid={!!validationErrors.name}
                    />
                    <FormErrorText error={validationErrors.name}/>
                </Form.Group>
                <Form.Group controlId={"name"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Apellidos del Hijo/a
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={surname}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSurname(e.target.value)}
                        required
                        isInvalid={!!validationErrors.surname}
                    />
                    <FormErrorText error={validationErrors.surname}/>
                </Form.Group>
                <Form.Group controlId={"nifType"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Seleccione DNI / NIE o Pasaporte (si no lo tuviera, déjelo en blanco)
                    </Form.Label>
                    <NifTypeSelect
                        defaultValue={nifType}
                        onChange={(newValue: any) => {
                            setNifType(newValue)
                        }
                        }
                        classes={props.submitted && validationErrors.nifType ? 'is-invalid' : ''}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.nifType}/>}
                </Form.Group>
                <Form.Group controlId={"nif"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        DNI / NIE del niño/a (solo números y letras)
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={nif}
                        isInvalid={!!validationErrors.nif}
                        className={"text-uppercase"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNif(e.target.value)}
                        required
                    />
                    <FormErrorText error={validationErrors.nif}/>
                </Form.Group>
                <Form.Group controlId={"birthdate"} className={"mb-4 col-sm-6 col-12"}>
                    <Form.Label>
                        Fecha de Nacimiento del Hijo/a
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    
                    {!props.loading && <>
                        <Calendar
                            onChange={(e: any) => setBirthdate(formatCalendarDate(e))}
                            minDate={getMinYearForBirthday()}
                            maxDate={getMaxYearForBirthday()}
                            locale={"es-ES"}
                            // view={birthdate ? "month" : "decade"}
                            defaultView={birthdate ? "month" : "decade"}
                            value={birthdate}
                        />
                        <FormErrorText error={validationErrors.birthdate}/>
                    </>
                    }

                </Form.Group>
                <Form.Group controlId={"goAlone"}
                            className={"pt-2 d-flex align-items-top col-sm-6 col-12"}>
                    <Form.Check
                        type="checkbox"
                        label={"El alumno puede irse solo de las actividades en las que participe."}
                        checked={goAlone}
                        onChange={() => setGoAlone(!goAlone)}
                    />
                </Form.Group>
            </Form>

            <br/>
            <h3 className={"purple my-4"}>Información adicional del hijo/a</h3>
            <p>En caso de responder con alguna necesidad, <span className={"purple"}>deberá adjuntar el informe médico correspondiente</span>.
            </p>

            {!props.loading &&
                <Row>
                    <Col sm={6} xs={12}>
                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Padece alguna enfermedad?"}
                            commentsLabel={"Indique la enfermedad/es"}
                            hasFiles={true}
                            hasComments={true}
                            checked={disease}
                            comments={diseaseComments}
                            files={diseaseFiles}
                            error={validationErrors.diseaseFiles}
                            commentsError={validationErrors.diseaseFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setDisease(checked)
                                setDiseaseComments(comments)
                                setDiseaseFiles(files)
                            }}
                        />

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Requiere de alguna atención especial durante las actividades?"}
                            commentsLabel={"Comentarios"}
                            hasFiles={true}
                            hasComments={true}
                            checked={reqSpecialAttention}
                            comments={reqSpecialAttentionComments}
                            files={reqSpecialAttentionFiles}
                            error={validationErrors.reqSpecialAttentionFiles}
                            commentsError={validationErrors.reqSpecialAttentionFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setReqSpecialAttention(checked)
                                setReqSpecialAttentionComments(comments)
                                setReqSpecialAttentionFiles(files)
                            }}
                        />

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Controla esfínteres?"}
                            commentsLabel={"Comentarios"}
                            hasFiles={true}
                            hasComments={true}
                            toggleValue={false}
                            checked={sphincters}
                            comments={sphinctersComments}
                            files={sphinctersFiles}
                            error={validationErrors.sphinctersFiles}
                            commentsError={validationErrors.sphinctersFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setSphincters(checked)
                                setSphinctersComments(comments)
                                setSphinctersFiles(files)
                            }}
                        />

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Puede comer cerdo?"}
                            hasFiles={false}
                            hasComments={false}
                            checked={pig}
                            onChange={(checked: boolean) => setPig(checked)}
                        />

                    </Col>
                    <Col sm={6} xs={12}>

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Tiene necesidades educativas especiales?"}
                            commentsLabel={"Indique las necesidades educativas especiales"}
                            hasFiles={true}
                            hasComments={true}
                            checked={specialEducation}
                            comments={specialEducationComments}
                            files={specialEducationFiles}
                            error={validationErrors.specialEducationFiles}
                            commentsError={validationErrors.specialEducationFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setSpecialEducation(checked)
                                setSpecialEducationComments(comments)
                                setSpecialEducationFiles(files)
                            }}
                        />

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Tiene alergias, intolerancias alimentarias o dietas especiales?"}
                            commentsLabel={"Indique alergias, intolerancias alimentarias o dietas especiales"}
                            hasFiles={true}
                            hasComments={true}
                            checked={allergens}
                            comments={allergensComments}
                            files={allergensFiles}
                            error={validationErrors.allergensFiles}
                            commentsError={validationErrors.allergensFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setAllergens(checked)
                                setAllergensComments(comments)
                                setAllergensFiles(files)
                            }}
                        />

                        <ChildFormAdditionalInfoCheck
                            formLabel={"¿Padece algún síndrome o trastorno?"}
                            commentsLabel={"Indique el síndrome o trastorno"}
                            hasFiles={true}
                            hasComments={true}
                            checked={disorder}
                            comments={disorderComments}
                            files={disorderFiles}
                            error={validationErrors.disorderFiles}
                            commentsError={validationErrors.disorderFilesComments}
                            onChange={(checked: boolean, comments: string, files: DropzoneFile[]) => {
                                setDisorder(checked)
                                setDisorderComments(comments)
                                setDisorderFiles(files)
                            }}
                        />

                    </Col>
                </Row>
            }

            <Row className={"mt-4"}>
                <Col>
                    {
                        terms.map((term, key) => {
                            return (
                                <Form.Group
                                    key={key}
                                    controlId={"term_" + key}
                                    className={"mb-4"}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={term}
                                        checked={termValues[key]}
                                        onChange={() => termValueChanged(key)}
                                        isInvalid={props.submitted && requiredTermKeys.indexOf(key) !== -1 && !termValues[key]}
                                    />
                                </Form.Group>
                            )
                        })
                    }

                </Col>
            </Row>

            <Button
                className={"mt-4 purple-btn"}
                onClick={handleSubmit}
            >
                Enviar formulario
            </Button>
        </>

    )

}
